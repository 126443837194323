import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './proximityConstraintForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ProximityConstraint } from 'site/app/models/proximityConstraint.model';
import { ProximityConstraintTask } from 'site/app/models/proximityConstraintTask.model';
import { ProximityConstraintAllocationRequestType } from 'site/app/models/proximityConstraintAllocationRequestType.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class ProximityConstraintFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public proximityConstraintId: any;
	public schedulePeriodId: any;

	public proximityConstraint: any;
	public submitted: boolean;	
	public actionTitle: string;
	public proximityConstraintTypes: any;
	public taskVariantGroups: any;
	public taskVariantGroup: any;
	public otherTaskVariantGroup: any;
	public tasks: any;
	public taskTypes: any;
	public allocationRequestTypes: any;
	public schedulePeriodsForTasks: any;
	public schedulePeriodsForOtherTasks: any;
	public schedulePeriodsForAllocationRequestTypes: any;
	public schedulePeriodsForOtherAllocationRequestTypes: any;
	public schedulePeriods: any;
	public isLoading: boolean;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
		private toastr: ToastrService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	deleteProximityConstraintTask(index) {
		this.proximityConstraint.proximity_constraint_tasks.splice(index, 1);
		this.schedulePeriodsForTasks.splice(index, 1);
	}
	
	addProximityConstraintTask() {
		this.proximityConstraint.proximity_constraint_tasks.push(new ProximityConstraintTask());
		this.schedulePeriodsForTasks.push(null);
	}

	deleteOtherProximityConstraintTask(index) {
		this.proximityConstraint.other_proximity_constraint_tasks.splice(index, 1);
		this.schedulePeriodsForOtherTasks.splice(index, 1);
	}
	
	addOtherProximityConstraintTask() {
		this.proximityConstraint.other_proximity_constraint_tasks.push(new ProximityConstraintTask());
		this.schedulePeriodsForOtherTasks.push(null);
	}

	deleteProximityConstraintAllocationRequestType(index) {
		this.proximityConstraint.proximity_constraint_allocation_request_types.splice(index, 1);
		this.schedulePeriodsForAllocationRequestTypes.splice(index, 1);
	}
	
	addProximityConstraintAllocationRequestType() {
		this.proximityConstraint.proximity_constraint_allocation_request_types.push(new ProximityConstraintAllocationRequestType());
		this.schedulePeriodsForAllocationRequestTypes.push(null);
	}

	deleteOtherProximityConstraintAllocationRequestType(index) {
		this.proximityConstraint.other_proximity_constraint_allocation_request_types.splice(index, 1);
		this.schedulePeriodsForOtherAllocationRequestTypes.splice(index, 1);
	}
	
	addOtherProximityConstraintAllocationRequestType() {
		this.proximityConstraint.other_proximity_constraint_allocation_request_types.push(new ProximityConstraintAllocationRequestType());
		this.schedulePeriodsForOtherAllocationRequestTypes.push(null);
	}

  	ok(isValid) {
		this.submitted = true;
		
		if (isValid) {
			if (this.proximityConstraintId === null) {
				this.http.post("proximity_constraints", this.proximityConstraint).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.error.error);
				});
			} else {
				this.http.put("proximity_constraints/" + this.proximityConstraint.id, this.proximityConstraint).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.error.error);
				});
			}
		}
  	}

  	cancel() {
		this.bsModalRef.hide();
  	}
	  
	trackByFunction(index) {
		return index;
	}

    ngOnInit() {
		var self = this;

		this.http.get("task_variant_groups").subscribe(x => {
			this.taskVariantGroups = x;

			if (this.proximityConstraintId === null) {
				this.actionTitle = "toevoegen";
				this.proximityConstraint = new ProximityConstraint();
				this.schedulePeriodsForTasks = [];
				this.schedulePeriodsForOtherTasks = [];
				this.schedulePeriodsForAllocationRequestTypes = [];
				this.schedulePeriodsForOtherAllocationRequestTypes = [];
				this.proximityConstraint.proximity_constraint_tasks = [];
				this.proximityConstraint.other_proximity_constraint_tasks = [];
				this.proximityConstraint.proximity_constraint_allocation_request_types = [];
				this.proximityConstraint.other_proximity_constraint_allocation_request_types = [];
			} else {
				this.actionTitle = "wijzigen";
				this.http.get("proximity_constraints/" + this.proximityConstraintId).subscribe(x => {
					this.proximityConstraint = x;

					this.taskVariantGroup = this.proximityConstraint.task_variant ? 
						this.taskVariantGroups.filter(function(z) { return z.id == self.proximityConstraint.task_variant.task_variant_group_id })[0] : null;

					this.otherTaskVariantGroup = this.proximityConstraint.other_task_variant ? 
						this.taskVariantGroups.filter(function(z) { return z.id == self.proximityConstraint.other_task_variant.task_variant_group_id })[0] : null;

					this.proximityConstraint.proximity_constraint_tasks.forEach(function(y, index) {
						y.index = index;
					});
				});
			}

			this.http.get("schedule_periods/show_all_by_schedule_period",
				{
					params: {
						id: this.schedulePeriodId
					}
				}
			).subscribe(x => {
				this.schedulePeriods = x;
				
				this.schedulePeriodsForTasks = this.proximityConstraint.proximity_constraint_tasks.map(function(y) {
					return self.schedulePeriods.filter(function(z) { return z.id == y.task.schedule_period_id })[0];
				});

				this.schedulePeriodsForOtherTasks = this.proximityConstraint.other_proximity_constraint_tasks.map(function(y) {
					return self.schedulePeriods.filter(function(z) { return z.id == y.task.schedule_period_id })[0];
				});

				this.schedulePeriodsForAllocationRequestTypes = this.proximityConstraint.proximity_constraint_allocation_request_types.map(function(y) {
					return self.schedulePeriods.filter(function(z) { return z.id == y.allocation_request_type.schedule_period_id })[0];
				});

				this.schedulePeriodsForOtherAllocationRequestTypes = this.proximityConstraint.other_proximity_constraint_allocation_request_types.map(function(y) {
					return self.schedulePeriods.filter(function(z) { return z.id == y.allocation_request_type.schedule_period_id })[0];
				});
		
				this.schedulePeriods.forEach(function(schedulePeriod) {
					schedulePeriod.tasks.forEach(function(task) {
						task.nameWithEquivalencePosition = task.name + (task.equivalence_position == null ? "" : " (" + task.equivalence_position + ")");
					});
				});

				this.isLoading = false;
			});
		});

		this.http.get("tasks", { params: { schedule_period_id: this.schedulePeriodId } }).subscribe(x => {
			this.tasks = x;
		});

		this.http.get("task_types").subscribe(x => {
			this.taskTypes = x;
		});

		this.http.get("allocation_request_types", { params: { schedule_period_id: this.schedulePeriodId } }).subscribe(x => {
			this.allocationRequestTypes = x;
		});
	
		this.proximityConstraintTypes = [
			{ id: 1, title: "2 mag niet binnen x dagen na 1 gedaan worden én 1 mag niet binnen x dagen na 2 gedaan worden" }, 
			{ id: 2, title: "2 mag niet binnen x dagen na 1 gedaan worden" },
			{ id: 3, title: "1 mag niet binnen x dagen na 2 gedaan worden" }
		];
    }
}
