import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import templateString from './taskChangeOrder.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EnvironmentService } from 'site/app/environment.service';
import { Task } from 'site/app/models/task.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({ template: templateString })
export class TaskChangeOrderComponent implements OnInit {
    @Output() action = new EventEmitter();

    public tasks;
    public schedulePeriodId;

    public isLoading: boolean;
    public allTasks: any;
    public taskDisplayGroups: any;
    public selection: { taskDisplayGroupId: any; };
    public previousSelection: { taskDisplayGroupId: any; };
    public isChanged: boolean;
    public customer;
    public tasksPerTaskDisplayGroup: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private environmentService: EnvironmentService,
        private renderer: Renderer2,
        private toastr: ToastrService,
    ) { 
        this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
    }

	changeTaskDisplayGroup() {
		if (!this.isChanged || window.confirm('De wijzigingen in de volgorde zijn nog niet opgeslagen. Weet je zeker dat je door wilt gaan?')) {
			this.previousSelection = { taskDisplayGroupId: this.selection.taskDisplayGroupId };
			this.refreshTasks();
		} else {
			this.selection = { taskDisplayGroupId: this.previousSelection.taskDisplayGroupId };
		}
	}
	
	refreshTasks() {
        var self = this;
        this.tasksPerTaskDisplayGroup = this.allTasks.filter(function(x) { return x.task_display_group_id == self.selection.taskDisplayGroupId; });
		this.isChanged = false;
	} 

    ok() {
        var self = this;
        if (this.taskDisplayGroups.filter(function(x) { return x.id == self.selection.taskDisplayGroupId })[0].sort_alphabetically) {
            this.toastr.error("Deze activiteitsgroep wordt automatisch alphabetisch gesorteerd, dus de volgorde kan niet handmatig worden veranderd. Ga indien nodig naar 'Instellingen -> Activiteiten -> Activiteitsgroepen' om dit aan te passen.");
        } else {
            this.isLoading = true;

            this.http.post("tasks/update_order", { task_ids: this.tasksPerTaskDisplayGroup.map(function(x) { return x.id; }) }).subscribe(() => {
                this.isLoading = false;
                this.action.emit(true);
                this.bsModalRef.hide();
            });    
        }
    }

    cancel() {
        this.bsModalRef.hide();
    };

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.tasksPerTaskDisplayGroup, event.previousIndex, event.currentIndex);
        this.isChanged = true;
    }

    ngOnInit() {
        var self = this;

        this.isLoading = true;

        this.customer = this.environmentService.getUser().customer;
        
        forkJoin([
            this.http.get("tasks/index_for_change_order", { params: { schedule_period_id: this.schedulePeriodId } }),
            this.http.get("task_display_groups", { params: { schedule_period_id: this.schedulePeriodId } })
        ]).subscribe(function(data) {
            self.allTasks = (<any>data[0]).map(function(x) { return new Task(x); });

            var currentTaskDisplayGroupName = null;
            self.allTasks.forEach(function(task) {
                task.computedName = task.getComputedName();

                if (task.task_display_group != null && task.task_display_group.name != currentTaskDisplayGroupName) {
                    task.taskDisplayGroupName = task.task_display_group.name;
                    currentTaskDisplayGroupName = task.taskDisplayGroupName;
                }
            });

            self.taskDisplayGroups = data[1];

            self.selection = { taskDisplayGroupId: self.taskDisplayGroups.length > 0 ? self.taskDisplayGroups[0].id : null };
            self.previousSelection = { taskDisplayGroupId: self.selection.taskDisplayGroupId };

            self.refreshTasks();

            self.isLoading = false;       
        });
    }
}
