import { Component, OnInit } from '@angular/core';
import templateString from './resetPassword.component.html';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class ResetPasswordComponent implements OnInit {
    public showError = false;
    public errorMessage = "";
    public user;

    constructor(
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService
	) {	}

    resetPassword() {
		if (this.user.password == null || this.user.password.length < 6) {
			this.errorMessage = "Wachtwoord moet uit tenminste 6 tekens bestaan";
			this.showError = true;
		} else if (this.user.password.indexOf(";") !== -1) {
			this.errorMessage = "Wachtwoord mag niet een punt-komma (';') bevatten. Toegestane speciale tekens zijn: @ % + \ / ' ! # $ ^ ? : . ( ) { } [ ] ~ - _ .";
			this.showError = true;			
		} else if (this.user.password !== this.user.passwordConfirmation) {
			this.errorMessage = "Wachtwoord is niet gelijk aan bevestiging wachtwoord";
			this.showError = true;
		} else {
			this.showError = false;

            this.http.post('other_users/update_password',
                { 
                    id: this.route.snapshot.queryParams["userId"], 
                    encrypted_password: this.route.snapshot.queryParams["encryptedPassword"],
                    password: this.user.password, 
                    password_confirmation: this.user.passwordConfirmation
                }
            ).subscribe(user => {
                this.toastr.success("U kunt nu inloggen", "Wachtwoord succesvol gewijzigd");
            	this.router.navigate(["/login"]);
			});
		}
    }

    ngOnInit() {
        this.http.get('other_users/get_for_registration',
            { 
                params: {
                    id: this.route.snapshot.queryParams["userId"], 
                    encrypted_password: this.route.snapshot.queryParams["encryptedPassword"]    
                }
            }
        ).subscribe(user => {
            this.user = user;
            
            if (this.user.id == null) {
                this.router.navigate(["/login"]);
            }
        });
    }
}
