import { Component, OnInit, EventEmitter, Output, Renderer2 } from '@angular/core';
import templateString from './periodicCandidateClone.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Candidate } from 'site/app/models/candidate.model';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class PeriodicCandidateCloneComponent implements OnInit {
    @Output() action = new EventEmitter();
    
    public currentSchedulePeriodId: any;
    public periodicCandidatesCurrentSchedulePeriod;

    public isLoading: boolean;
    public checkAll: boolean;
    public schedulePeriods: any;
    public selection: any = {};
    public periodicCandidates: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
        private environmentService: EnvironmentService
    ) { 
        this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
    }

    refresh() {
        var self = this;
		this.http.get("periodic_candidates",
            { 
                params: {
                    schedule_period_id: this.selection.schedulePeriodId
                }
            }
        ).subscribe(x => {
			this.periodicCandidates = x;
			this.periodicCandidates.forEach(function(periodicCandidate) {
				
				periodicCandidate.fullName = (new Candidate(periodicCandidate.candidate)).fullName();
				
				if (periodicCandidate.candidate.users.length > 0) {
					periodicCandidate.candidate.user = periodicCandidate.candidate.users[0];
				}
				
				periodicCandidate.alreadyExistsInCurrentSchedule = self.periodicCandidatesCurrentSchedulePeriod.filter(
					function(x) { return x.candidate_id == periodicCandidate.candidate_id; }).length > 0;

				periodicCandidate.checkForClone = periodicCandidate.alreadyExistsInCurrentSchedule;
			});
			
			this.isLoading = false;
		});
	}
	
	changeSchedulePeriod = function() {
		this.isLoading = true;
		
		this.refresh();
	}	

	checkAllForClone() {
        var self = this;
		this.periodicCandidates.filter(function(x) { return !x.alreadyExistsInCurrentSchedule; }).forEach(function(periodicCandidate) {
			periodicCandidate.checkForClone = self.checkAll;
		});
	}

    ok() {
		this.isLoading = true;
		
		this.http.post("periodic_candidates/clone",
            { 
			    current_schedule_period_id: this.currentSchedulePeriodId, 
			    periodic_candidate_ids: this.periodicCandidates
				    .filter(function(x) { return x.checkForClone && !x.alreadyExistsInCurrentSchedule; })
				    .map(function(x) { return x.id; })
            }
        ).subscribe(() => {
            this.isLoading = false;
            this.action.emit(true);
            this.bsModalRef.hide();
		});
    }

    cancel() {
        this.bsModalRef.hide();
    }

    ngOnInit() {
        this.isLoading = true;
        this.checkAll = false;
    
        this.http.get("schedule_periods").subscribe(schedulePeriods => {
            this.schedulePeriods = schedulePeriods;
            
            var currentSchedulePeriodIndex = this.schedulePeriods.map(function(x) { return x.id }).indexOf(this.currentSchedulePeriodId);
            
            // get previous schedule period
            if (currentSchedulePeriodIndex > 0) {
                this.selection = { schedulePeriodId: this.schedulePeriods[currentSchedulePeriodIndex-1].id };
                
                this.refresh();
            } else {
                this.isLoading = false;
            }
        });
    }
}
