import { Component, OnInit } from '@angular/core';
import templateString from './optoutReminders.component.html';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class OptoutRemindersComponent implements OnInit {
    public showError = false;
    public message = "";
    public user;

    constructor(
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService
	) {	}

    ngOnInit() {
        this.http.post('other_users/optout_reminders',
            { 
                id: this.route.snapshot.queryParams["userId"], 
                encrypted_password: this.route.snapshot.queryParams["encryptedPassword"],
            }
        ).subscribe(user => {
            this.message = "Je bent succesvol uitgeschreven voor herinneringsmails. In je easyplanner-omgeving kun je deze uitschrijving ongedaan maken."
        });
    }
}
