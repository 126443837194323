import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import templateString from './taskDisplayGroupChangeOrder.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TaskDisplayGroupChangeOrderComponent implements OnInit {
    @Output() action = new EventEmitter();
    
    public originalTaskDisplayGroups: any;
    
    public taskDisplayGroups: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2
    ) { 
        this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
    }

    ok() {
        this.http.post("task_display_groups/update_order", { task_display_group_ids: this.taskDisplayGroups.map(function(x) { return x.id; }) }).subscribe(() => {
            this.action.emit(true);
            this.bsModalRef.hide();
		});
    }

    cancel() {
        this.bsModalRef.hide();
    };

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.taskDisplayGroups, event.previousIndex, event.currentIndex);
    }

    ngOnInit() {
        this.taskDisplayGroups = JSON.parse(JSON.stringify(this.originalTaskDisplayGroups));
    }
}
