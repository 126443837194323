import { Component, OnInit, Renderer2, Output, EventEmitter, ElementRef } from '@angular/core';
import templateString from './assignTaskModal.component.html'
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EnvironmentService } from './../../environment.service';
import { DataService } from './../../data.service';
import * as moment from 'moment';
import { Candidate } from './../../models/candidate.model';
import { Shift } from './../../models/shift.model';
import { Task } from './../../models/task.model';
import { forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class AssignTaskModalComponent implements OnInit {
	@Output() action = new EventEmitter();
	
	public task: Task;
	public selectedGanttTasksForMultiSelect;
	
	public isLoading;
	public sortType;
	public sortReverse;
	public shift;
	public data = [];
	public shifts = [];
	public assignedPeriodicCandidate;
	public periodicCandidates;
	public assignedTaskVariant;
	public dateSelection;
	public allDatesSelected;
	public allocationRequestParts = [];
	public ganttTasks;
	public selectedSchedulePeriod;
	public allocationRequestStatuses;
	public allocationRequestStatusButtonClasses;
	public isEditComment;
	public fromDate;
	public toDate;
	public taskLengthInDays;
	public datesInSelection;
	public weeksForHeader;
	public modalClosedByAction;
	public isChanged;
	public weekdays;
	public columnWidth;
	public numberOfSelectedItems;
	public timespans;
	public matchedTaskAccumulationPeriods = [];
	public user: any;

	constructor(
		private bsModalRef: BsModalRef,
		private environmentService: EnvironmentService,
		private http: HttpClient,
		private dataService: DataService,
		private renderer: Renderer2,
		private host: ElementRef
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	changeSort(sortType, event) {
		var self = this;
		
		if (sortType == this.sortType) {
			this.sortReverse = !this.sortReverse;
		} else {
			this.sortReverse = false;
		}
		this.sortType = sortType;
	
		this.data = this.data.sort(function(a, b) { 
			switch(self.sortType) {
			  case 'rank':
					return self.sortReverse ? b.rank - a.rank : a.rank - b.rank;
			  case 'name':
					if (a.name < b.name) {
				    return self.sortReverse ? 1 : -1;
				  } else if (a.name > b.name ) {
				    return self.sortReverse ? -1 : 1;
				  }
				  return 0;
				case 'distanceFromPreviousOrToNext':
					return self.sortReverse ? b.distanceFromPreviousOrToNext - a.distanceFromPreviousOrToNext : a.distanceFromPreviousOrToNext - b.distanceFromPreviousOrToNext;
			}
		});
	
		if (event) {
			event.preventDefault();
		}		
	}

	checkIsCancelled() {
		if (this.shift.is_cancelled) {
			this.shift.is_cancelled = false;
		}
	};

	toggleAssignee(periodicCandidateId, performCheckIsCancelled) {
		if (performCheckIsCancelled) {
			this.checkIsCancelled();
		}

		var clickedGanttRow = this.data.filter(function(x) { return x.periodicCandidateId == periodicCandidateId })[0];

		clickedGanttRow.isSelected = !clickedGanttRow.isSelected;

		this.updateAssignedPeriodicCandidate(periodicCandidateId, clickedGanttRow.isSelected, false);
	}

	toggleTaskVariant(taskVariantId) {
		var clickedGanttRow = this.data.filter(function(x) { return x.taskVariantId == taskVariantId })[0];

		clickedGanttRow.isSelected = !clickedGanttRow.isSelected;

		this.updateAssignedTaskVariant(taskVariantId, clickedGanttRow.isSelected);
	}

	updateAssignedPeriodicCandidate(periodicCandidateId, isSelected, performCheckIsCancelled) {
		var self = this;
		if (this.shifts.length == 1 && performCheckIsCancelled) {
			this.checkIsCancelled();
		}

		if (isSelected) {
			if (this.shift.periodic_candidate_id != null) {
				this.data.filter(function(x) { return x.isSelected && x.periodicCandidateId != periodicCandidateId; })[0].isSelected = false;
			}
			this.shift.periodic_candidate_id = periodicCandidateId;
		} else {
			this.shift.periodic_candidate_id = null;
		}

		this.assignedPeriodicCandidate = this.shift.periodic_candidate_id == null ? null : this.periodicCandidates.filter(function(x) { return x.id == self.shift.periodic_candidate_id; })[0];
	}

	updateAssignedTaskVariant(taskVariantId, isSelected) {
		var self = this;
		if (isSelected) {
			if (this.shift.task_variant_id != null) {
				this.data.filter(function(x) { return x.isSelected && x.taskVariantId != taskVariantId; })[0].isSelected = false;
			}
			this.shift.task_variant_id = taskVariantId;
		} else {
			this.shift.task_variant_id = null;
		}

		this.assignedTaskVariant = this.shift.task_variant_id == null ? null : this.task.task_variant_group.task_variants.filter(function(x) { return x.id == self.shift.task_variant_id; })[0];
	}

	toggleDateSelection(date) {
		var self = this;
		this.dateSelection[date] = !this.dateSelection[date]
		this.allDatesSelected = Object.keys(this.dateSelection).map(function(key){return self.dateSelection[key]}).filter(function(x) { return x == false }).length == 0;
	}

	toggleDateSelectionAll() {
		var self = this;
		this.allDatesSelected = !this.allDatesSelected;

		Object.keys(this.dateSelection).forEach(function(date) {
			self.dateSelection[date] = self.allDatesSelected;
		});
	}

	ok() {
		this.isLoading = true;
		this.action.emit([this.shift, this.dateSelection, this.allocationRequestParts]);
		this.bsModalRef.hide();
	}

 	toggleIsCancelled(isCancelled) {
  		this.shift.is_cancelled = isCancelled;

		if (isCancelled && this.shift.periodic_candidate_id != null) {
			this.toggleAssignee(this.shift.periodic_candidate_id, false);
		}
  	};

	setAllocationRequestPartStatus(allocationRequestPart, statusId) {
		allocationRequestPart.status_id = statusId;

		// if is_allocate is true, assign the shift to periodic candidate (or unassign)
		if (allocationRequestPart.is_allocate_preference) {
			if (statusId == 1 && this.shift.periodic_candidate_id == allocationRequestPart.allocation_request.periodic_candidate_id) {
				this.toggleAssignee(allocationRequestPart.allocation_request.periodic_candidate_id, false);
			} else if (statusId == 3 && this.shift.periodic_candidate_id != allocationRequestPart.allocation_request.periodic_candidate_id) {
				this.toggleAssignee(allocationRequestPart.allocation_request.periodic_candidate_id, true);
			}
		// when is_allocate is false, we're dealing with absence. For each accepted allocationRequestPart,
		// have only the dates within these allocationRequestParts selected and get ready to unassign
		} else {
			//TODO: make this work (not relevant now)

			//       // don't do anything when shift is of type 'vakantie' or 'compensatie week' or 'compensatie visite'
			// console.log(this.task);
			// console.log(this.task.task_type);
			// if (this.task.task_type.is_available) {
			// 	var acceptedAllocationRequestParts = this.allocationRequestParts
			// 		.filter(function(x) { return x.status_id == 3; })
			// 		.map(function(x) { return [x.start_date, x.end_date] });
			//
			// 	var individualDaysIsoFormat = [];
			//
			// 	acceptedAllocationRequestParts.forEach(function(x) {
			// 		var currentDate = moment(x[0]);
			//
			// 		while (currentDate.isBefore(moment(x[1]))) {
			// 			individualDaysIsoFormat.push(currentDate.toISOString());
			//
			// 			currentDate = moment(currentDate).add(1, 'day');
			// 		}
			// 	});
			//
			// 	Object.keys(this.dateSelection).forEach(function(dateIso) {
			// 		if (individualDaysIsoFormat.length == 0) {
			// 			this.dateSelection[dateIso] = true;
			// 		} else {
			// 			this.dateSelection[dateIso] = individualDaysIsoFormat.indexOf(dateIso) >= 0;
			// 		}
			// 	});
			//
			// 	if (statusId == 3 && this.shift.periodic_candidate_id == allocationRequestPart.allocation_request.periodic_candidate_id) {
			// 		this.toggleAssignee(allocationRequestPart.allocation_request.periodic_candidate_id, false);
			// 	}
			// }
		}
	}

	setIsOverwritten(shiftDay, isOverwritten) {
		shiftDay.is_overwritten = isOverwritten;

		if (!isOverwritten) {
			shiftDay.start_time = shiftDay.original_start_time;
			shiftDay.end_time = shiftDay.original_end_time;
		}
	}

  	dismiss() {
		this.bsModalRef.hide();
  	}

	cancel() {
		this.bsModalRef.hide();
	}

	getColor(colorId) {
		if (this.user.customer.id == 27) {
			return (this.dataService.colors.concat(["white", "white", "white", "rgba(255, 255, 0, 0.5)"]))[colorId];
		} else {
			return this.dataService.colors[colorId];
		}
    }

	ngOnInit() {
		var self = this;
		this.isLoading = true;

		this.user = this.environmentService.getUser();

	 	this.allDatesSelected = true;
	 	if (this.task == null) {
	 		this.ganttTasks = this.selectedGanttTasksForMultiSelect;
	 	} else {
	 		this.ganttTasks = [this.task];
	 	}

		this.shifts = this.ganttTasks.map(function(x) { return x.shift; });

		this.data = [];
		var ganttRows = [];

		this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();

		this.sortType = 'rank';
		this.sortReverse = false;

		if (this.ganttTasks.length == 1) {
			this.shift = this.shifts[0];
			
			var commentPartsWithoutDate = this.shift.comment_parts.filter(function(x) { return x[0] == null });
			if (commentPartsWithoutDate.length > 0) {
				this.shift.comment = commentPartsWithoutDate[0][1];
			}

			// get shift days
			this.http.get('shift_days',
				{ params:
					{ 
						shift_id: this.shift.id
					}
				}
			).subscribe(x => {
				this.shift.shift_days = x;
			});

			this.allocationRequestStatuses = ["Geweigerd", "Niet verwerkt", "Geaccepteerd"];
			this.allocationRequestStatusButtonClasses = ["btn-danger", "btn-warning", "btn-primary"];
			this.isEditComment = false;
			this.fromDate = moment(this.ganttTasks[0].from);
			this.toDate = moment(this.ganttTasks[0].to).subtract(1, 'day');
			this.taskLengthInDays = moment(this.ganttTasks[0].to).diff(moment(this.ganttTasks[0].from), 'days');
			this.dateSelection = {};

			this.datesInSelection = [];
			this.weeksForHeader = [];
			// needed for when comment is edited, then dismissing the modal should still refresh the schedule
			this.modalClosedByAction = false;
			this.isChanged = false;
			this.weekdays = ["maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag", "zondag"];

			var currentDate = moment(this.fromDate);
			var currentWeek = currentDate.get('week');
			var numberOfDaysInCurrentWeek = 0;
			var index = 0;
			var currentWeekIndex = 0;

			while (currentDate <= moment(this.toDate))
			{
				this.dateSelection[currentDate.toISOString()] = true;
				this.datesInSelection.push(currentDate.format('dd D MMM'));
				currentDate.add(1, 'day');
				index++;

				numberOfDaysInCurrentWeek++;
				if (currentDate.get('week') !== currentWeek || currentDate > moment(this.toDate)) {
					this.weeksForHeader.push([currentWeek, currentWeekIndex, numberOfDaysInCurrentWeek]);
					currentWeek = currentDate.get('week');
					currentWeekIndex = index;
					numberOfDaysInCurrentWeek = 0;
				}
			}

			this.http.get<any>('allocation_request_parts',
				{ params:
					{ 
						shift_id: this.shift.id
					}
				}
			).subscribe(x => {
				this.allocationRequestParts = x;

				for(var i=0; i < this.allocationRequestParts.length; i++) {
					var allocationRequestPart = this.allocationRequestParts[i];

					allocationRequestPart.allocation_request.periodic_candidate.candidateName =
						self.user.customer.use_firstnames ?
							(new Candidate(allocationRequestPart.allocation_request.periodic_candidate.candidate)).firstnameWithInitial() :
							(new Candidate(allocationRequestPart.allocation_request.periodic_candidate.candidate)).infixWithLastname();

					allocationRequestPart.formattedPeriod = moment(allocationRequestPart.start_date).format('D MMM');

					if (!moment(allocationRequestPart.start_date).isSame(moment(allocationRequestPart.end_date).add(-1, 'days'))) {
						allocationRequestPart.formattedPeriod += " t/m " + moment(allocationRequestPart.end_date).add(-1, 'days').format('D MMM');
					}

					// TODO: DRY up (see allocation requests index in 'beheer')
					// allocation_request_type_id is 'compensatieweek'
					// or date_selection_type_id is 'periode' (for both 'afwezig' and 'geen dienst')
					// or (task_type_id != null and is_period_containing_tasks
					if (
						(allocationRequestPart.allocation_request.allocation_request_type.task_id != null && allocationRequestPart.allocation_request.allocation_request_type.is_allocate_preference) ||
						allocationRequestPart.allocation_request.date_selection_type_id == 1 ||
						(allocationRequestPart.allocation_request.allocation_request_type.task_type_id != null &&
							allocationRequestPart.allocation_request.allocation_request_type.is_allocate_preference &&
							!allocationRequestPart.allocation_request.allocation_request_type.is_period_containing_tasks)
					) {
						allocationRequestPart.allocation_request.formattedPeriod = moment(allocationRequestPart.allocation_request.start_date).format('dd D MMM') +
							(moment(allocationRequestPart.allocation_request.end_date).subtract(1, 'day').isSame(allocationRequestPart.allocation_request.start_date) ? "" :
								" t/m " + moment(allocationRequestPart.allocation_request.end_date).add(-1, 'days').format('dd D MMM'));
					} else if (allocationRequestPart.allocation_request.date_selection_type_id == 2) {
						// losse dagen (for both 'afwezig' and 'geen dienst')
						allocationRequestPart.allocation_request.formattedPeriod = allocationRequestPart.allocation_request.allocation_request_parts.map(function(x) {
							return moment(x.start_date).format('dd D MMM') }).join(", ");
					} else if (allocationRequestPart.allocation_request.date_selection_type_id == 3) {
						allocationRequestPart.allocation_request.formattedPeriod = "Elke " + this.weekdays[allocationRequestPart.allocation_request.day_of_week-1] + " van " + moment(allocationRequestPart.allocation_request.start_date).format('dd D MMM') + " t/m " +
							moment(allocationRequestPart.allocation_request.end_date).add(-1, 'days').format('dd D MMM');
					}
				}
			});
		} else {
			this.allocationRequestParts = [];
			this.shift = new Shift();
			this.shift.is_cancelled = false;
			this.numberOfSelectedItems = this.selectedGanttTasksForMultiSelect.length;
		}

		this.http.get('tasks/' + this.ganttTasks[0].task.id).subscribe(fetchedTask => {
			this.task = new Task(fetchedTask);
			this.task.computedName = this.task.getComputedName();

			if (this.task.periodic_candidate_id == null) {

				forkJoin([
					this.http.get('periodic_candidates/for_assign_modal', { params: { 'shift_ids[]': this.shifts.map(function(x) { return x.id; }) } }),
					this.http.get('task_accumulation_periods', { params: { schedule_period_id: this.task.schedule_period_id.toString() } })
				]).subscribe(result => {
					this.periodicCandidates = result[0];
					var taskAccumulationPeriods = <any>result[1];

					this.matchedTaskAccumulationPeriods = taskAccumulationPeriods.filter(function(x) {
						return self.shifts.map(function(y) { return y.taskAccumulationPeriodIds.indexOf(x.id) != -1 }).indexOf(true) != -1 
					});

					if (this.ganttTasks.length == 1) {
						this.columnWidth = (this.matchedTaskAccumulationPeriods.length <= 1 ? 560 : 640 - this.matchedTaskAccumulationPeriods.length * 80) / (this.toDate.diff(this.fromDate, 'days') + 1);
					}

					if (this.shift == null || this.shift.periodic_candidate_id == null) {
						this.assignedPeriodicCandidate = null;
					} else {
						this.assignedPeriodicCandidate = this.periodicCandidates.filter(function(x) { return x.id == self.shift.periodic_candidate_id; })[0];
						this.assignedPeriodicCandidate.candidateName = 
							this.user.customer.use_firstnames ?
								(new Candidate(this.assignedPeriodicCandidate.candidate)).firstnameWithInitial() :
								(new Candidate(this.assignedPeriodicCandidate.candidate)).infixWithLastname();
					}

					this.periodicCandidates.forEach(function(periodicCandidate) {

						periodicCandidate.candidateName = 
							self.user.customer.use_firstnames ?
								(new Candidate(periodicCandidate.candidate)).firstnameWithInitial() :
								(new Candidate(periodicCandidate.candidate)).infixWithLastname();
						
						periodicCandidate.infixWithLastname = (new Candidate(periodicCandidate.candidate)).infixWithLastname();
						periodicCandidate.lastnameWithInfix = (new Candidate(periodicCandidate.candidate)).lastnameWithInfix();

						var ganttTasksAllRows = [];

						for (var i=1; i <= periodicCandidate.assign_modal_row_count; i++) {
							ganttTasksAllRows.push(periodicCandidate.applicable_shifts.filter(function(x) { return x.row_number == i; }).map(
								function(x) { return self.dataService.createAssignScheduleTaskFromShift(x, periodicCandidate, self.ganttTasks[0].from, self.ganttTasks[0].to) }))
						}

						var taskAccumulationCounts = {};

						self.matchedTaskAccumulationPeriods.forEach(function(matchedTaskAccumulationPeriod) {
							if (periodicCandidate.candidate.shift_count[matchedTaskAccumulationPeriod.id] == null) {
								taskAccumulationCounts[matchedTaskAccumulationPeriod.id] = "0";
							} else {
								taskAccumulationCounts[matchedTaskAccumulationPeriod.id] = periodicCandidate.candidate.shift_count[matchedTaskAccumulationPeriod.id].toString();
							}

							if (matchedTaskAccumulationPeriod.has_targets) {
								taskAccumulationCounts[matchedTaskAccumulationPeriod.id] += " / ";

								if (periodicCandidate.candidate.target_count[matchedTaskAccumulationPeriod.id] == null) {
									taskAccumulationCounts[matchedTaskAccumulationPeriod.id] += "0";
								} else {
									taskAccumulationCounts[matchedTaskAccumulationPeriod.id] += periodicCandidate.candidate.target_count[matchedTaskAccumulationPeriod.id].toString();
								}
							}
						});

						var ganttRow = {
							name: (self.user.customer.use_firstnames ? (new Candidate(periodicCandidate.candidate)).firstnameWithInitial() : (new Candidate(periodicCandidate.candidate)).lastnameWithInfix()) + " (" + (self.user.customer.id == 19 ? (periodicCandidate.candidate.phone_number ? periodicCandidate.candidate.phone_number : "-") : periodicCandidate.candidate_type.name) + ")",
							periodicCandidateId: periodicCandidate.id,
							alias: periodicCandidate.alias,
							isSelected: self.shift.periodic_candidate_id == periodicCandidate.id,
							candidateTypeId: periodicCandidate.candidate_type_id,
							ganttTasksAllRows: ganttTasksAllRows,
							taskAccumulationCounts: taskAccumulationCounts,
							distanceFromPreviousOrToNext: periodicCandidate.candidate.distance_from_previous_or_to_next,
							emptyOrTemplateDaysCount: periodicCandidate.empty_or_template_days_count,
							unavailableDaysCount: periodicCandidate.unavailable_days_count,
							assignedDaysCount: periodicCandidate.assigned_days_count,
							rank: periodicCandidate.rank
						};

						taskAccumulationPeriods.forEach(function(taskAccumulationPeriod) {
						 	ganttRow["taskAccumulationPeriod_" + taskAccumulationPeriod.id] = [taskAccumulationPeriod.task_accumulation_type.alias, periodicCandidate.candidate.shift_count[taskAccumulationPeriod.id]];
						});

						ganttRows.push(ganttRow);
					});

					this.data = ganttRows;

					this.timespans = [];

					var currentDate = moment(this.fromDate);
					var end_date = moment(this.ganttTasks[0].to);

					if (currentDate.day() > 0 && currentDate.day() < 6) {
						currentDate = currentDate.day(6);
					}

					while (currentDate < end_date) {
						this.timespans.push({
						  from: currentDate,
						  to: moment(currentDate).add(currentDate.day() === 0 || currentDate.isSame(moment(end_date).subtract(1, 'day')) ? 1 : 2, 'days'),
						});

						if (currentDate.day() === 0) {
						 	currentDate = moment(currentDate).add(6, 'days');
						} else {
						 	currentDate = moment(currentDate).add(1, 'week');
						}
					}

					if (this.user.customer.id == 7) {
						this.changeSort('name', null);
					}

					this.isLoading = false;
				});

			} else {

				if (this.shift == null || this.shift.task_variant_id == null) {
					this.assignedTaskVariant = null;
				} else {
					this.assignedTaskVariant = this.task.task_variant_group.task_variants.filter(function(x) { return x.id == self.shift.task_variant_id; })[0];
				}

				this.task.task_variant_group.task_variants.forEach(function(taskVariant) {
					if (!taskVariant.is_archived || self.shifts.filter(function(x) { return x.task_variant_id == taskVariant.id }).length > 0) {
						var ganttRow = {
							name: taskVariant.name,
							taskVariantId: taskVariant.id,
							colorId: taskVariant.color_id,
							alias: taskVariant.alias,
							isSelected: self.shift.task_variant_id == taskVariant.id,
							rank: taskVariant.position
						};

						ganttRows.push(ganttRow);
					}
				});

				this.data = ganttRows;

				if (this.user.customer.id == 7) {
					this.changeSort('name', null);
				}

				this.isLoading = false;
			}

			// $(function () {
			// 	$('#distance-tooltip').tooltip();
			// 	$('#availability-tooltip').tooltip();
			// });
		}, () => {
			this.bsModalRef.hide();
		});
	}

	ngAfterViewInit() {	
		if (this.user.customer.id == 19) {
			setTimeout(()=>{
				var nameFilter = this.host.nativeElement.querySelector('.nameFilterString');
				nameFilter.focus();
			}, 1000)
		}
	}
}