export class TemplatePeriod {
	public ganttTaskId;
	public task_id;
	public periodic_candidate_id;
	public start_date;
	public end_date;
	public task;

	constructor() {
	}
} 
