import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './optimizationGroupForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { OptimizationGroup } from 'site/app/models/optimizationGroup.model';
import { OptimizationGroupMembership } from 'site/app/models/optimizationGroupMembership.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class OptimizationGroupFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public optimizationGroupId: any;
	public schedulePeriodId: any;

	public optimizationGroup: any;
	public submitted: boolean;	
	public actionTitle: string;
	public taskAccumulationPeriods: any;
	public isLoading: boolean;
	public optimizationGroupMembershipIndex: number;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
        private toastr: ToastrService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	deleteOptimizationGroupMembership(index) {
		var matchIndex = -1;
		this.optimizationGroup.optimization_group_memberships_for_schedule_period.some(function(el, i) {
			if (el.index == index) {
				matchIndex = i;
				return true;
			}
		});
		
		this.optimizationGroup.optimization_group_memberships_for_schedule_period.splice(matchIndex, 1);;
	}
	
	addOptimizationGroupMembership() {
		this.optimizationGroup.optimization_group_memberships_for_schedule_period.push(
			new OptimizationGroupMembership({ index: this.optimizationGroupMembershipIndex++ })
		);
	}

  	ok(isValid) {
		this.submitted = true;
		
		if (isValid) {
			if (this.optimizationGroupId === null) {
				this.http.post("optimization_groups",
					{ 
						optimization_group: this.optimizationGroup,
						schedule_period_id: this.schedulePeriodId
					}
				).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.error.error);
				});
			} else {
				this.http.put("optimization_groups/" + this.optimizationGroup.id,
					{
						optimization_group: this.optimizationGroup, 
						schedule_period_id: this.schedulePeriodId
					}
				).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.error.error);
				});
			}			
		}
  	}

  	cancel() {
		this.bsModalRef.hide();
  	}
	
	trackByFunction(index) {
		return index;
	}

    ngOnInit() {
		if (this.optimizationGroupId === null) {
			this.actionTitle = "toevoegen";
			this.optimizationGroup = new OptimizationGroup();
			this.optimizationGroup.optimization_group_memberships_for_schedule_period = [];
			this.optimizationGroupMembershipIndex = 0;
			
		} else {
			this.actionTitle = "wijzigen";
			this.http.get<any>("optimization_groups/" + this.optimizationGroupId, 
				{ 
					params: {
						schedule_period_id: this.schedulePeriodId 
					}
				}
			).subscribe(x => {
				this.optimizationGroup = x;

				this.optimizationGroup.optimization_group_memberships_for_schedule_period.forEach(function(optimizationGroupMembership, index) {
					optimizationGroupMembership.index = index;
				});
				
				this.optimizationGroupMembershipIndex = x.optimization_group_memberships_for_schedule_period.length;
			});
		}
		
		this.http.get("task_accumulation_periods", { params: { schedule_period_id: this.schedulePeriodId } }).subscribe(taskAccumulationPeriods => {
			this.taskAccumulationPeriods = taskAccumulationPeriods;
	
			this.isLoading = false;
		});
    }
}
