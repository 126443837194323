import { NgModule } from "@angular/core";
import { AllocationRequestsComponent } from "./allocationRequests";
import { BrowserModule } from "@angular/platform-browser";
import { appRoutingModule } from "../app.routing";
import { FormsModule } from "@angular/forms";
import { ModalModule } from "ngx-bootstrap/modal";
import { PipesModule } from "../pipes";
import { DirectivesModule } from "../directives";
import { AllocationRequestFormComponent } from "./allocationRequestForm";
import { DpDatePickerModule } from 'ng2-date-picker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

@NgModule({
  	declarations: [
		AllocationRequestsComponent,
		AllocationRequestFormComponent
	],
  	imports: [
		BrowserModule,
		appRoutingModule,
		FormsModule,
		DirectivesModule,
		ModalModule.forRoot(),
		PipesModule,
		DpDatePickerModule,
		TimepickerModule.forRoot()
	],
  	providers: [	
	],
	entryComponents: [
		AllocationRequestFormComponent
	]
})
export class AllocationRequestsModule {}
