import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { appRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { HomeComponent } from './home';
import { LoggedInLayoutComponent } from './loggedInLayout';
import { AuthenticationModule } from './authentication';
import { DayScheduleModule } from './daySchedule';
import { ProfileModule } from './profile';
import { SettingsModule } from './settings';
import { EnvironmentService } from './environment.service';
import { DataService } from './data.service';
import { AngularTokenModule } from './angular-token';
import { HttpClientModule, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from './directives';
import { PipesModule } from './pipes';
import { MomentModule } from 'ngx-moment';
import * as moment from 'moment';
import * as locales from 'moment/min/locales';
import { enableProdMode } from '@angular/core';
import { GlobalErrorHandler } from './globalErrorHandler';
import { AllocationRequestsModule } from './allocationRequests';
import { MonthScheduleModule } from './monthSchedule';
import { TrainingScheduleModule } from './trainingSchedule';
import { AgendaModule } from './agenda';
import { PopulatedReportsModule } from './populatedReports';
import { TrainingCandidatesModule } from './trainingCandidates';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';

moment.locale('nl');

if (window.location.host !== 'localhost:3000') {
	enableProdMode();
}

var apiBase = "";
switch(process.env.RAILS_ENV) {
	case "development":
		apiBase = "http://localhost:3000";
		break;
	case "production":
		apiBase = 'https://easyplanner.nl';
		break;
	case "backup":
		apiBase = 'https://backup.easyplanner.nl';
		break;
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  	constructor(
		private router: Router,
		private toastr: ToastrService
	) {}

  	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    	// const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    	// if (currentUser && currentUser.token) {
      	// 	request = request.clone({
        // 		setHeaders: {
        //   			'Content-Type': 'application/json',
        //   			Authorization: `JWT ${currentUser.token}`
        // 		}
      	// 	});
    	// }

    	return next.handle(request).pipe( tap(() => {},
      		(err: any) => {
      			if (err instanceof HttpErrorResponse) {
        			if (err.status !== 401) {
         				return;
        			}
					this.toastr.error('Uw sessie is verlopen. Log opnieuw in.');
					this.router.navigate(["/login"]);
				}
    	}));
  	}
}

@NgModule({
  	declarations: [
		AppComponent,
		HomeComponent,
		LoggedInLayoutComponent
  	],
  	imports: [
    	BrowserModule,
		AuthenticationModule,
		appRoutingModule,
    	HttpClientModule,
    	AngularTokenModule.forRoot({
			apiBase: apiBase,
			signInPath: 'users/sign_in.json',
			signOutPath: 'users/sign_out.json',
			signInRedirect: '/login'
    	}),
		DayScheduleModule,
		ProfileModule,
		FormsModule,
		DirectivesModule,
		MomentModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot(),
		SettingsModule,
		AllocationRequestsModule,
		MonthScheduleModule,
		TrainingScheduleModule,
		TrainingCandidatesModule,
		AgendaModule,
		PopulatedReportsModule,
		PipesModule
	],
  	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useFactory: function(router:Router, toastr: ToastrService) {
			  return new AuthInterceptor(router, toastr)
			},
			multi: true,
			deps: [Router, ToastrService]
		},
		EnvironmentService,
		DataService,
		AngularTokenModule,
		ToastrService,
		{
			provide: ErrorHandler, 
			useClass: GlobalErrorHandler
		},
		DatePipe
	],
  	bootstrap: [AppComponent]
})
export class AppModule { }
