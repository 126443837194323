import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './candidateTypeTaskMembershipForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class CandidateTypeTaskMembershipFormComponent implements OnInit {
	@Output() action = new EventEmitter();

	public candidateTypes: any;
	public tasks: any;

	public isLoading: boolean;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
		private environmentService: EnvironmentService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}
	
	ok() {
 		this.isLoading = true;
		
		this.http.post("tasks/update_candidate_type_task_memberships",
			{ tasks: this.tasks }
		).subscribe(() => {
			this.isLoading = false;
			this.action.emit(true);
			this.bsModalRef.hide();
		});
	}

  	cancel() {
		this.bsModalRef.hide();
	}

    ngOnInit() {
	}
}
