import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import templateString from './selectShiftChangesForTemplatePeriods.component.html';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Candidate } from 'site/app/models/candidate.model';
import { EnvironmentService } from 'site/app/environment.service';

@Component({ template: templateString })
export class SelectShiftChangesForTemplatePeriodsComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public shifts: any;

	constructor(
		private renderer: Renderer2,
		private bsModalRef: BsModalRef,
		private environmentService: EnvironmentService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	ok() {
		this.action.emit(this.shifts);
		this.bsModalRef.hide();
	};
	
	cancel() {
		this.bsModalRef.hide();
	};

	ngOnInit() {	
		this.shifts.forEach(function(shift) {
			shift.apply_change = true;
		
			shift.infixWithLastname = shift.periodic_candidate == null || shift.periodic_candidate == undefined ? "" : (new Candidate(shift.periodic_candidate.candidate)).infixWithLastname();
			shift.newInfixWithLastname = shift.new_periodic_candidate == null || shift.new_periodic_candidate == undefined ? "" : (new Candidate(shift.new_periodic_candidate.candidate)).infixWithLastname();
			
			shift.formattedPeriod = moment(shift.start_date).isSame(moment(shift.end_date).add(-1, 'days')) ? 
				moment(shift.start_date).format('dd D MMM') : 
				moment(shift.start_date).format('dd D MMM') + " t/m " + moment(shift.end_date).add(-1, 'days').format('dd D MMM');
				shift.formattedDateForDomId = moment(shift.start_date).format('YYYYMMDD') + "-" + moment(shift.end_date).format('YYYYMMDD');
		});	
	}
}
	