import { Component, OnInit } from '@angular/core';
import templateString from './optimizationGroups.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { EnvironmentService } from 'site/app/environment.service';
import { takeUntil } from 'rxjs/operators';
import { OptimizationGroupFormComponent } from '../optimizationGroupForm';
import { OptimizationGroupChangeOrderComponent } from '../optimizationGroupChangeOrder';
import { OptimizationGroupRunFormComponent } from '../optimizationGroupRunForm';
import { OptimizationGroupRunOptionsComponent } from '../optimizationGroupRunOptions';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class OptimizationGroupsComponent implements OnInit {
    
    public optimizationGroups: any;
    public selectedSchedulePeriod: any;
    public destroyed$: any;
    public isLoading: boolean;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService,
        private environmentService: EnvironmentService,
	) { 
        this.destroyed$ = new Subject<boolean>();
    }

    refresh() {
        this.http.get("optimization_groups", { params: { schedule_period_id: this.selectedSchedulePeriod.id } }).subscribe(x => {
            this.optimizationGroups = x;

            this.optimizationGroups.forEach(function(y) {
                y.optimization_group_runs_for_view.forEach(function(z) {
                     z.formattedStatus = ["", "Error", "Succes"][z.status_id];
                });
            });
        });
    }
    
    changeSchedulePeriod() {
        this.refresh();
    }

    create() {
        var bsModalRef = this.bsModalService.show(OptimizationGroupFormComponent, {
            initialState: {
                optimizationGroupId: null,
                schedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    edit(optimizationGroupId) {
        var bsModalRef = this.bsModalService.show(OptimizationGroupFormComponent, {
            initialState: {
                optimizationGroupId: optimizationGroupId,
                schedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    delete(optimizationGroupId) {
        if (window.confirm('Weet je het zeker?')) {
            this.http.delete("optimization_groups/" + optimizationGroupId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.toastr.error(response.error.error);
			});
        }
    };
    
    changeOrder() {
        var bsModalRef = this.bsModalService.show(OptimizationGroupChangeOrderComponent, {
            initialState: {
                originalOptimizationGroups: this.optimizationGroups
            }
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    editOptimizationGroupRun(optimizationGroupRunId) {
        var bsModalRef = this.bsModalService.show(OptimizationGroupRunFormComponent, {
            initialState: {
                optimizationGroupRunId: optimizationGroupRunId
            },
            class: 'xlModal'
        });

        bsModalRef.content.action.subscribe(result => {	
        });
    }

    undoOptimizationGroupRun(optimizationGroupRunId) {
        if (window.confirm('Weet je het zeker?')) {
            this.isLoading = true;

            this.http.post("optimization_group_runs/undo",
                { id: optimizationGroupRunId }
            ).subscribe(data => {
                this.toastr.success("Toewijzingen zijn ongedaan gemaakt");
                this.isLoading = false;
            });
        }
    }

    autoPlan(optimizationGroup) {
        var bsModalRef = this.bsModalService.show(OptimizationGroupRunOptionsComponent, {
            initialState: {
                optimizationGroup: optimizationGroup,
                schedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'xlModal'
        });

        bsModalRef.content.action.subscribe(result => {
            this.isLoading = true;
            var schedulePeriodIds = result[0];
            var optimizationGroup = result[1];

            this.http.post("shifts/auto_plan",
                { 
                    schedule_period_ids: schedulePeriodIds,
                    optimization_group: optimizationGroup,
                }
            ).subscribe(data => {
                this.isLoading = false;
                this.toastr.success("Run is voltooid");
                this.refresh();
            });
        });
    }

    initialize() {
		this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();

		this.refresh();
	}
	
    ngOnInit() {
        this.environmentService.selectedSchedulePeriodChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.initialize();
            });

        this.initialize();
    }

    ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
    }
}
