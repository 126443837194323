import { Component, OnInit } from '@angular/core';
import templateString from './register.component.html'
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PrivacyStatementComponent } from '../privacyStatement';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class RegisterComponent implements OnInit {
    public showError = false;
    public errorMessage = "";
    public passwordAlreadySet = false;
    public user;
    public privacyStatementAccepted: boolean;

    constructor(
        private toastr: ToastrService,
        private http: HttpClient,
        private router: Router,
        private bsModalService: BsModalService,
        private route: ActivatedRoute
    ) { }

	register() {
		if (this.passwordAlreadySet) {
            this.http.post("other_users/update_password",
                { 
                    password_already_set: 'true', 
                    id: this.route.snapshot.queryParams["userId"], 
                    encrypted_password: this.route.snapshot.queryParams["encryptedPassword"]
                }
            ).subscribe(user => {
                this.toastr.success("U kunt nu inloggen met het eerder ingestelde wachtwoord voor dit emailadres", "Succesvol geregistreerd");
				this.router.navigate(["/login"]);
            });
		} else {
			if (this.user.password == null || this.user.password.length < 6) {
				this.errorMessage = "Wachtwoord moet uit tenminste 6 tekens bestaan";
				this.showError = true;
			} else if (this.user.password.indexOf(";") !== -1) {
				this.errorMessage = "Wachtwoord mag niet een punt-komma (';') bevatten. Toegestane speciale tekens zijn: @ % + \ / ' ! # $ ^ ? : . ( ) { } [ ] ~ - _ .";
				this.showError = true;
			} else if (this.user.password !== this.user.passwordConfirmation) {
				this.errorMessage = "Wachtwoord is niet gelijk aan bevestiging wachtwoord";
				this.showError = true;
			} else {
				this.showError = false;

                var bsModalRef = this.bsModalService.show(PrivacyStatementComponent, {
					initialState: {}
				});
		
				bsModalRef.content.action.subscribe(result => {	
                    this.privacyStatementAccepted = true;
                    
                    this.http.post("other_users/update_password",
                        { 
                            password_already_set: 'false', 
                            id: this.route.snapshot.queryParams["userId"],
                            encrypted_password: this.route.snapshot.queryParams["encryptedPassword"],
                            password: this.user.password, 
                            password_confirmation: this.user.passwordConfirmation
                        }
                    ).subscribe(user => {
                        this.toastr.success("U kunt nu inloggen", "Wachtwoord succesvol gewijzigd");
                        this.router.navigate(["/login"]);
                    });
                });
			}
		}
    }
    
    ngOnInit() {
		this.http.get('other_users/get_for_registration',
			{ 
                params: {
                    id: this.route.snapshot.queryParams["userId"], 
                    encrypted_password: this.route.snapshot.queryParams["encryptedPassword"]    
                }
			}
		).subscribe(user => {
            this.user = user;
   
            if (this.user.id == null) {
               this.toastr.error("Registratielink ongeldig");
               this.router.navigate(["/login"]);
            } else if (this.user.is_registered) {
               // hier kom je ws nooit, encrypted_password is gewijzigd
                this.toastr.error("Registratielink is niet meer geldig, je bent al geregistreerd. Klik op 'Wachtwoord vergeten?' in het loginscherm om je wachtwoord te resetten.");
                this.router.navigate(["/login"]);
            }
        });

        // check other registered user already, then use that password
        this.http.get<any>('other_users/get_other_registered_users_with_same_email_by_id',
			{ params: { id: this.route.snapshot.queryParams["userId"] } }
		).subscribe(response => {
            if (response.number_of_users > 0) {
                this.passwordAlreadySet = true;
            }
        });
    }
}