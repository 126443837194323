import { Component, OnInit } from '@angular/core';
import templateString from './proximityConstraints.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProximityConstraintFormComponent } from '../proximityConstraintForm';
import { Subject } from 'rxjs';
import { EnvironmentService } from 'site/app/environment.service';
import { takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class ProximityConstraintsComponent implements OnInit {
    
    public proximityConstraints: any;
    public selectedSchedulePeriod: any;
    public destroyed$: any;
    public proximityConstraintTypes: any;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService,
        private environmentService: EnvironmentService
    ) {
        this.destroyed$ = new Subject<boolean>();
    }

    refresh() {
        this.http.get("proximity_constraints", { params: { schedule_period_id: this.selectedSchedulePeriod.id } }).subscribe(x => {
            this.proximityConstraints = x;

            this.proximityConstraints.forEach(function(y) {
                if (y.proximity_constraint_type_id == 3) {
                    y.firstTasks = y.other_tasks;
                    y.firstTaskVariant = y.other_task_variant;
                    y.firstTaskType = y.other_task_type;
                    y.firstAllocationRequestTypes = y.other_allocation_request_types;

                    y.secondTasks = y.tasks;
                    y.secondTaskVariant = y.task_variant;
                    y.secondTaskType = y.task_type;
                    y.secondAllocationRequestTypes = y.allocation_request_types;
                } else {
                    y.firstTasks = y.tasks;
                    y.firstTaskVariant = y.task_variant;
                    y.firstTaskType = y.task_type;
                    y.firstAllocationRequestTypes = y.allocation_request_types;

                    y.secondTasks = y.other_tasks;
                    y.secondTaskVariant = y.other_task_variant;
                    y.secondTaskType = y.other_task_type;
                    y.secondAllocationRequestTypes = y.other_allocation_request_types;
                }

                if (y.firstTasks.length > 0) {
                    y.formattedFirstPrefix = "Activiteit" + (y.firstTasks.length > 1 ? "en" : "");
                } else if (y.firstTaskVariant) {
                    y.formattedFirstPrefix = "Activiteitsvariant";
                } else if (y.firstTaskType) {
                    y.formattedFirstPrefix = "Activiteitstype";
                } else if (y.firstAllocationRequestTypes.length > 0) {
                    y.formattedFirstPrefix = "Verzoekstype" + (y.firstAllocationRequestTypes.length > 1 ? "n" : "");
                } else {
                    y.formattedFirstPrefix = "<error>"
                }

                if (y.number_of_days == 0) {
                    y.formattedConstraintDescription1 = "op dezelfde dag";
                    y.formattedConstraintDescription2 = "als";
                } else {
                    y.formattedConstraintDescription1 = "binnen " + y.number_of_days.toString() + " dag" + (y.number_of_days > 1 ? "en" : "");
                    y.formattedConstraintDescription2 = "na";
                }
                
                if (y.secondTasks.length > 0) {
                    y.formattedSecondPrefix = "activiteit" + (y.secondTasks.length > 1 ? "en" : "");
                } else if (y.secondTaskVariant) {
                    y.formattedSecondPrefix = "activiteitsvariant";
                } else if (y.secondTaskType) {
                    y.formattedSecondPrefix = "activiteitstype";
                } else if (y.secondAllocationRequestTypes.length > 0) {
                    y.formattedSecondPrefix = "verzoekstype" + (y.secondAllocationRequestTypes.length > 1 ? "n" : "");
                } else {
                    y.formattedSecondPrefix = "<error>"
                }

                y.suffix = y.proximity_constraint_type_id == 1 ? "en vice versa" : "";
            });
        });
    }

    create() {
        var bsModalRef = this.bsModalService.show(ProximityConstraintFormComponent, {
            initialState: {
                proximityConstraintId: null,
                schedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    edit(proximityConstraintId) {
        var bsModalRef = this.bsModalService.show(ProximityConstraintFormComponent, {
            initialState: {
                proximityConstraintId: proximityConstraintId,
                schedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    delete(proximityConstraintId) {
        if (window.confirm('Weet je het zeker?')) {
            this.http.delete("proximity_constraints/" + proximityConstraintId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.toastr.error(response.error.error);
			});
        }
    };

    initialize() {
		this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();

        this.proximityConstraintTypes = [
			{ id: 1, title: "2 mag niet binnen x dagen na 1 gedaan worden én 1 mag niet binnen x dagen na 2 gedaan worden" }, 
			{ id: 2, title: "2 mag niet binnen x dagen na 1 gedaan worden" },
			{ id: 3, title: "1 mag niet binnen x dagen na 2 gedaan worden" }
		];

		this.refresh();
	}
	
    ngOnInit() {
        this.environmentService.selectedSchedulePeriodChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.initialize();
            });

        this.initialize();
    }

    ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
    }
}
