import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import templateString from './trainingChangeTrainerComment.component.html';
import * as moment from 'moment';
import { EnvironmentService } from 'site/app/environment.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TrainingChangeTrainerCommentComponent implements OnInit {
    @Output() action = new EventEmitter();

	public trainerComment;
	
	constructor(
		private renderer: Renderer2,
		private http: HttpClient,
		private environmentService: EnvironmentService,
		private bsModalRef: BsModalRef,
		private toastr: ToastrService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

  	ok() {
		this.action.emit(this.trainerComment);
		this.bsModalRef.hide();
  	};

  	cancel() {
    	this.bsModalRef.hide();
	};
	  
	ngOnInit() {	
	}
}
