import { Component, OnInit } from '@angular/core';
import templateString from './articles.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ArticleFormComponent } from '../articleForm';
import { DataService } from 'site/app/data.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class ArticlesComponent implements OnInit {
    
    public articles: any;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService,
        private dataService: DataService
    ) { }

    refresh() {
        this.http.get("articles").subscribe(x => {
            this.articles = x;
        });
    }

    create() {
        var bsModalRef = this.bsModalService.show(ArticleFormComponent, {
            initialState: {
                articleId: null
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    edit(articleId) {
        var bsModalRef = this.bsModalService.show(ArticleFormComponent, {
            initialState: {
                articleId: articleId
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    initialize() {
		this.refresh();
	}
	
    ngOnInit() {
        this.initialize();
    }
}
