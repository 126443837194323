import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import templateString from './candidateViolations.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { DataService } from "site/app/data.service";
import { TrainingPeriod } from 'site/app/models/trainingPeriod.model';

@Component({ template: templateString })
export class CandidateViolationsComponent implements OnInit {
    @Output() action = new EventEmitter();

	public candidateId;
	public candidateName;
	public preferredOrderViolations;
	public preferredOrderViolationCount;
	public preferredLocationViolations;
	public preferredLocationViolationCount;
	public changes;
	public changesCount;
	public candidate;
	public formerTrainingPeriods;
	public newTrainingPeriods;
	public numberOfDays;
	public columnWidth;
	public dateHeaderRows;
	public monthColumns;
	public fromDate;
	
	public ganttRows;
	public horizontalScrollReceiver: any;
	
	constructor(
		private renderer: Renderer2,
		private bsModalRef: BsModalRef,
		private dataService: DataService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

  	ok() {
		this.bsModalRef.hide();
  	};

  	cancel() {
    	this.bsModalRef.hide();
	};

	getLength(from, to) {
		return moment(to).diff(moment(from), 'days');
	}

	getOffset(from) {
		return moment(from).diff(this.fromDate, 'days');
	}

	syncScroll(event) {
		this.horizontalScrollReceiver.scrollLeft = event.scrollLeft;
	}

	ngOnInit() {
		var self = this;

		var ganttTasks = [];

		this.formerTrainingPeriods.filter(function(x) { return x; }).forEach(function(x) { 
			if (x.unavailability_periods != undefined && x.unavailability_periods.length > 0) {
				ganttTasks.push(self.dataService.createTrainingPeriodTaskForCandidate(
					new TrainingPeriod({
						candidate_id: x.candidate_id,
						start_date: x.start_date,
						end_date: x.unavailability_periods[0].start_date,
						id: x.id + "-1",
						is_locked: x.locked,
						location: x.location,
						location_id: x.location_id,
						ratio: x.ratio,
						training_period_accumulation_type: x.training_period_accumulation_type,
						training_period_accumulation_type_id: x.training_period_accumulation_type_id,
						is_changed: x.is_changed,
						unavailability_periods: x.unavailability_periods // only for the first trainingPeriod, needed for recalculate_kpis
					}), 
					self.candidate, true));
				ganttTasks.push(this.dataService.createTrainingPeriodTaskForCandidate(
					new TrainingPeriod({
						candidate_id: x.candidate_id,
						start_date: x.unavailability_periods[0].end_date,
						end_date: x.end_date,
						id: x.id + "-2",
						is_locked: x.locked,
						location: x.location,
						location_id: x.location_id,
						ratio: x.ratio,
						training_period_accumulation_type: x.training_period_accumulation_type,
						training_period_accumulation_type_id: x.training_period_accumulation_type_id,
						is_changed: x.is_changed
					}), 
					self.candidate, true));
			} else {
				ganttTasks.push(self.dataService.createTrainingPeriodTaskForCandidate(x, self.candidate, true));
			}
		});
		ganttTasks = ganttTasks.concat(
			self.candidate.candidate_unavailability_periods_for_training_schedule_version.map(function(x) { 
				return self.dataService.createCandidateUnavailabilityPeriod(x) })
		);

		var newGanttTasks = [];

		this.newTrainingPeriods.forEach(function(x) { 
			if (x.unavailability_periods != undefined && x.unavailability_periods.length > 0) {
				newGanttTasks.push(self.dataService.createTrainingPeriodTaskForCandidate(
					new TrainingPeriod({
						candidate_id: x.candidate_id,
						start_date: x.start_date,
						end_date: x.unavailability_periods[0].start_date,
						id: x.id + "-1",
						is_locked: x.locked,
						location: x.location,
						location_id: x.location_id,
						ratio: x.ratio,
						training_period_accumulation_type: x.training_period_accumulation_type,
						training_period_accumulation_type_id: x.training_period_accumulation_type_id,
						is_changed: x.is_changed,
						unavailability_periods: x.unavailability_periods // only for the first trainingPeriod, needed for recalculate_kpis
					}), 
					self.candidate, true));
				newGanttTasks.push(this.dataService.createTrainingPeriodTaskForCandidate(
					new TrainingPeriod({
						candidate_id: x.candidate_id,
						start_date: x.unavailability_periods[0].end_date,
						end_date: x.end_date,
						id: x.id + "-2",
						is_locked: x.locked,
						location: x.location,
						location_id: x.location_id,
						ratio: x.ratio,
						training_period_accumulation_type: x.training_period_accumulation_type,
						training_period_accumulation_type_id: x.training_period_accumulation_type_id,
						is_changed: x.is_changed
					}), 
					self.candidate, true));
			} else {
				newGanttTasks.push(self.dataService.createTrainingPeriodTaskForCandidate(x, self.candidate, true));
			}
		});
		newGanttTasks = newGanttTasks.concat(
			self.candidate.candidate_unavailability_periods_for_training_schedule_version.map(function(x) { 
				return self.dataService.createCandidateUnavailabilityPeriod(x) })
		);

		this.ganttRows = [
			{
				id: "candidate.id",
				name: "candidate.nameField",
				tasks: ganttTasks
			},
			{
				id: "candidate.id",
				name: "candidate.nameField",
				tasks: newGanttTasks
			},
			{
				id: "candidate.id",
				name: "candidate.nameField",
				tasks: []
			}
		];
	}

	ngAfterViewInit() {
		this.horizontalScrollReceiver = document.querySelectorAll('.myHorizontalScrollReceiver2')[0];
	}
}
