import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import templateString from './loggedInLayout.component.html';
import { EnvironmentService } from './../environment.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AngularTokenService } from './../angular-token';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Candidate } from '../models/candidate.model';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app',
	template: templateString
})
export class LoggedInLayoutComponent implements OnInit, OnDestroy {
	public totalOpenAllocationRequestCount;
	public totalOpenAllocationRequestCountAllSchedulePeriods;
	public openAllocationRequestCountPerSchedulePeriod;
	public user;
	public schedulePeriods;
	public schedulePeriodId;
	public hoveredElement;
	public tooltipWidth: number = 0;
	public isRightAligned: boolean;
	public innerWidth: number;
	public currentUrl: string;
	public refreshShiftCountOverview$: any;
	public shiftCountsLoaded: boolean;
	public taskAccumulationPeriods: any;
	public candidatesForShiftCountOverview: any;
	public showShiftCountOverview: boolean = false;
	public destroyed$;
	public isSchedulePeriodSet: boolean = false;
	public isSettingsPage: boolean;
	public menuUncollapsed = false;
	public settingGroups: any;
	public openedSettingGroupTitle: string;
	public totalOpenTrainingChangesCount;

	constructor(
		private router: Router,
		private environmentService: EnvironmentService, 
		private tokenService: AngularTokenService,
		private http: HttpClient,
		private toastr: ToastrService
	) {	
		this.destroyed$ = new Subject<boolean>();
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = window.innerWidth;
	}

	setTooltip(hoveredElement) {
		this.hoveredElement = hoveredElement;
		if (this.hoveredElement) {
			this.isRightAligned = this.hoveredElement.left > 0.8 * this.innerWidth;	
		}
	}

	changeSchedulePeriod() {
		var self = this;
		this.environmentService.setSchedulePeriodSelection(this.schedulePeriods.filter(function(x) { return x.id == self.schedulePeriodId })[0]);
		this.isSchedulePeriodSet = true;

		this.refreshTotalOpenAllocationRequestCount();
	}

	// $scope.$on('changeSchedulePeriodDropdown', function(event, args) {
	// 	$scope.schedulePeriodId = args.schedulePeriodId;
	// 	$scope.changeSchedulePeriod();
	// });

	logout() {
		this.tokenService.signOut().subscribe(res => {
			this.environmentService.deleteUser();
			//$rootScope.$broadcast('refreshUser');

			//this.toastr.success("U bent uitgelogd");
			this.router.navigate(['/login']);
		}, (error) => {
		  console.error(error);
		});
	}

	refreshTotalOpenAllocationRequestCount() {
		this.http.get<any>('allocation_requests/total_open_allocation_request_count', 
			{
				params: {
					schedule_period_id: this.schedulePeriodId
				} 
			}
		).subscribe(x => {
			this.totalOpenAllocationRequestCount = x.count;
			this.totalOpenAllocationRequestCountAllSchedulePeriods = x.total_count;
			this.openAllocationRequestCountPerSchedulePeriod = x.per_schedule_period.map(function(y) { return y.schedule_period_name + ": " + y.count }).join(", ");
		});
	}

	refreshTotalOpenTrainingChangesCount() {
		this.http.get<any>('candidates/total_open_training_changes_count').subscribe(x => {
			this.totalOpenTrainingChangesCount = x.total_count;
		});
	}

	refreshShiftCountOverview(schedulePeriodId, changedShifts) {
		this.shiftCountsLoaded = false;
		forkJoin([
			this.http.get('task_accumulation_periods', { params: { schedule_period_id: schedulePeriodId } }),
			this.http.post('candidates/index_for_shift_count_overview', { schedule_period_id: schedulePeriodId, changed_shifts: changedShifts })
		]).subscribe(data => {
			this.taskAccumulationPeriods = data[0];
			this.candidatesForShiftCountOverview = data[1];
			this.candidatesForShiftCountOverview.forEach(function(candidate) {
				candidate.lastnameWithInfix = (new Candidate(candidate)).lastnameWithInfix();
			});
			this.shiftCountsLoaded = true;
		});
	}

	toggleShiftCountOverview() {
		this.showShiftCountOverview = !this.showShiftCountOverview;
	}
	
	// $scope.refreshTrainingPeriodDifferencesOverview = function() {
	// 	$scope.trainingPeriodDifferencesLoaded = false;
	// 	$scope.hardViolationDescriptions = [];
	// 	TrainingPeriod.getTrainingPeriodDifferences().$promise.then(function(data) {
	// 		data["to_delete"].forEach(function(trainingPeriod) {
	// 			$scope.hardViolationDescriptions.push("Verwijderen: " + (new Candidate(trainingPeriod.candidate)).infixWithLastname() + " - " + 
	// 				trainingPeriod.training_period_accumulation_type.name);
	// 		});
	// 		data["to_add"].forEach(function(trainingPeriod) {
	// 			$scope.hardViolationDescriptions.push("Toevoegen: " + (new Candidate(trainingPeriod.candidate)).infixWithLastname() + " - " + 
	// 				trainingPeriod.training_period_accumulation_type.name + " - " + trainingPeriod.length + " maanden");
	// 		});
	// 		data["to_change"].forEach(function(trainingPeriod) {
	// 			$scope.hardViolationDescriptions.push("Wijzigen: " + (new Candidate(trainingPeriod.candidate)).infixWithLastname() + " - " + 
	// 				trainingPeriod.training_period_accumulation_type.name + " - " + trainingPeriod.length + " maanden " +
	// 				"(was " + trainingPeriod.original_length + ")");
	// 		});
	// 		data["candidate_unavailability_periods"].forEach(function(candidateUnavailabilityPeriod) {
	// 			$scope.hardViolationDescriptions.push("Toevoegen: " + (new Candidate(candidateUnavailabilityPeriod.candidate)).infixWithLastname() + " - " + 
	// 				"Niet beschikbaar - " + moment(candidateUnavailabilityPeriod.start_date).format("DD-MM-YYYY") + " tot " +
	// 				moment(candidateUnavailabilityPeriod.end_date).format("DD-MM-YYYY") + " (" + candidateUnavailabilityPeriod.description + ")");
	// 		});
			
	// 		$scope.trainingPeriodDifferencesLoaded = true;
	// 	});
	// }
	
	
	// $scope.$on('refreshTrainingPeriodDifferencesOverview', function(event, args) {
	// 	$scope.refreshTrainingPeriodDifferencesOverview();
	// });

	// $scope.$on('refreshUser', function(event, args) {
	// 	$scope.user = EnvironmentService.getUser();
	// });

	setIsSettingsPage() {
		this.isSettingsPage = this.currentUrl.startsWith("/app/settings/");
	}

	toggleMenuCollapsed() {
		this.menuUncollapsed = !this.menuUncollapsed;
	}

	collapseMenu() {
		this.menuUncollapsed = false;
	}

	toggleSettingGroup(settingGroupTitle) {
		if (settingGroupTitle == this.openedSettingGroupTitle) {
			this.openedSettingGroupTitle = null;
		} else {
			this.openedSettingGroupTitle = settingGroupTitle;
		}
	}

	openActivitiesSettingGroup() {
		this.openedSettingGroupTitle = "Activiteiten";
	}

	ngOnInit() {
		var self = this;
		this.totalOpenAllocationRequestCount = 0;
		this.totalOpenAllocationRequestCountAllSchedulePeriods = 0;
		this.totalOpenTrainingChangesCount = 0;
		this.openAllocationRequestCountPerSchedulePeriod = [];
		this.user = this.environmentService.getUser();

		if (!this.user) {
			this.toastr.error('Uw sessie is verlopen. Log opnieuw in.');
			this.router.navigate(["/login"]);
		} else {

			this.settingGroups = [
				{ 
					title: 'Activiteiten', 
					iconClass: 'fa-list-ul',
					linkId: 'tasksGrouplink',
					settings: [
						{ title: 'Activiteiten', link: '/app/settings/tasks', linkId: 'tasksLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
						{ title: 'Activiteitsgroepen', link: '/app/settings/taskDisplayGroups', linkId: 'taskDisplayGroupsLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
						{ title: 'Dagrooster tabs', link: '/app/settings/dayScheduleTabs', linkId: 'dayScheduleTabsLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
						{ title: 'Toegestane overlap', link: '/app/settings/taskConflictExceptions', linkId: 'taskConflictExceptionsLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
						{ title: 'Activiteiten per ' + this.environmentService.translateForIndustry('candidate_type'), link: '/app/settings/candidateTypeTaskMemberships', linkId: 'candidateTypeTaskMembershipsLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
						{ title: 'Basisrooster', link: '/app/settings/defaultAssignments', linkId: 'defaultAssignmentsLink', hiddenCondition: false },
						{ title: 'Autoplanner groepen', link: '/app/settings/optimizationGroups', linkId: 'optimizationGroupsLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
						{ title: 'Planregels', link: '/app/settings/proximityConstraints', linkId: 'proximityConstraintsLink', hiddenCondition: this.user.customer.customer_type_id != 4 },
						{ title: 'Zelfroosteren', link: '/app/settings/selfRosteringMemberships', linkId: 'selfRosteringMembershipsLink', hiddenCondition: this.user.lastname == 'Planner RONA' }
					],
					hiddenCondition: this.user.customer.is_only_regional && this.user.customer.has_only_access_to_own_location
				},
				{ 
					title: 'Personen', 
					iconClass: 'fa-users',
					linkId: 'peopleGroupLink',
					settings: [
						{ title: this.environmentService.translateForIndustry('Candidates'), link: '/app/settings/periodicCandidates', linkId: 'periodicCandidatesLink', hiddenCondition: this.user.customer.is_only_regional && this.user.customer.has_only_access_to_own_location },
						{ title: 'Overige gebruikers', link: '/app/settings/otherUsers', linkId: 'otherUsersLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
					],
					hiddenCondition: false
				},
				{ 
					title: 'Bijzondere dagen', 
					iconClass: 'fa-flag-o',
					linkId: 'specialEventsGroupLink',
					settings: [
						{ title: 'Feestdagen', link: '/app/settings/specialEvents', queryParams: { is_public_holiday: true }, linkId: 'specialEventsPublicHolidayLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
						{ title: 'Evenementen', link: '/app/settings/specialEvents', queryParams: { is_public_holiday: false }, linkId: 'specialEventsNonPublicHolidayLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
					],
					hiddenCondition: this.user.customer.is_only_regional && this.user.customer.has_only_access_to_own_location
				},
				{ 
					title: 'Sommaties', 
					iconClass: 'fa-pie-chart',
					linkId: 'accumulationsGroupLink',
					settings: [
						
						{ title: 'Sommaties activiteiten', link: '/app/settings/taskAccumulationPeriods', linkId: 'taskAccumulationPeriodsLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
						{ title: 'Richtwaarden activiteiten', link: '/app/settings/taskAccumulationTargets', linkId: 'taskAccumulationTargetsLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
						{ title: 'Sommaties afwezigheid', link: '/app/settings/allocationRequestAccumulationPeriods', linkId: 'allocationRequestAccumulationPeriodsLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
						{ title: 'Budgetten afwezigheid', link: '/app/settings/candidateAllocationRequestBudgetComponents', linkId: 'candidateAllocationRequestBudgetComponentsLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
						{ title: 'Sommaties dagrooster', link: '/app/settings/shiftAccumulationTypes', linkId: 'shiftAccumulationTypesLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
						{ title: 'Sommaties maandrooster', link: '/app/settings/templatePeriodAccumulationTypes', linkId: 'templatePeriodAccumulationTypesLink', hiddenCondition: this.user.lastname == 'Planner RONA' || this.user.customer.customer_type_id == 4 },
					],
					hiddenCondition: this.user.customer.is_only_regional && this.user.customer.has_only_access_to_own_location
				},
				{ 
					title: 'Rapporten', 
					iconClass: 'fa-bar-chart',
					linkId: 'reportsGroupLink',
					settings: [
						{ title: 'Rapporten', link: '/app/settings/reports', linkId: 'reportsLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
					],
					hiddenCondition: this.user.customer.is_only_regional && this.user.customer.has_only_access_to_own_location
				},
				{ 
					title: 'Perioden', 
					iconClass: 'fa-clock-o',
					linkId: 'periodsGroupLink',
					settings: [
						{ title: 'Roosterperioden', link: '/app/settings/schedulePeriods', linkId: 'schedulePeriodsLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
						{ title: 'Sommatieperioden', link: '/app/settings/accumulationPeriods', linkId: 'accumulationPeriodsLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
					],
					hiddenCondition: this.user.customer.is_only_regional && this.user.customer.has_only_access_to_own_location
				},
				{ 
					title: 'Omgeving', 
					iconClass: 'fa-hospital-o',
					linkId: 'environmentGroupLink',
					settings: [
						{ title: 'Omgeving', link: '/app/settings/environment', linkId: 'environmentLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
					],
					hiddenCondition: this.user.customer.is_only_regional && this.user.customer.has_only_access_to_own_location
				},
				{ 
					title: 'Definities', 
					iconClass: 'fa-book',
					linkId: 'definitionsGroupLink',
					settings: [
						{ title: 'Activiteitstypen', link: '/app/settings/taskTypes', linkId: 'taskTypesLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
						{ title: 'Activiteitsvariant-groepen', link: '/app/settings/taskVariantGroups', linkId: 'taskVariantGroupsLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
						{ title: this.environmentService.translateForIndustry('Candidate_types'), link: '/app/settings/candidateTypes', linkId: 'candidateTypesLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
						{ title: 'Extra agenda\'s', link: '/app/settings/customAgendas', linkId: 'customAgendasLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
						// { title: 'Klinieken', link: '/app/settings/locations', linkId: 'locationsLink', hiddenCondition: this.user.customer.name != 'RONA' },
						{ title: 'Stagetypen', link: '/app/settings/trainingPeriodAccumulationTypes', linkId: 'trainingPeriodAccumulationTypesLink', hiddenCondition: this.user.customer.customer_type_id == 4 },
						{ title: 'Sommatietypen activiteiten', link: '/app/settings/taskAccumulationTypes', linkId: 'taskAccumulationTypesLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
						{ title: 'Sommatietypen afwezigheid', link: '/app/settings/allocationRequestAccumulationTypes', linkId: 'allocationRequestAccumulationTypesLink', hiddenCondition: this.user.lastname == 'Planner RONA' },
						{ title: 'Verzoekstypen', link: '/app/settings/allocationRequestTypes', linkId: 'allocationRequestTypesLink', hiddenCondition: this.user.lastname == 'Planner RONA' },					
					],
					hiddenCondition: this.user.customer.is_only_regional && this.user.customer.has_only_access_to_own_location
				},
				{ 
					title: 'easyplanner', 
					iconClass: 'fa-id-card-o',
					linkId: 'easyplannerLink',
					settings: [
						{ title: 'Klanten', link: '/app/settings/customers', linkId: 'customersLink', hiddenCondition: this.user.customer.id != 17 },
						{ title: 'Blogposts', link: '/app/settings/articles', linkId: 'articlesLink', hiddenCondition: this.user.customer.id != 17 }
					],
					hiddenCondition: this.user.customer.id != 17
				}
			];

			this.settingGroups.forEach(function(x) {
				if (x.settings.filter(function(y) { return y.link == self.router.url; }).length > 0) {
					self.openedSettingGroupTitle = x.title;
				}
			});

			this.http.get('schedule_periods').subscribe( schedulePeriods => {
				this.schedulePeriods = schedulePeriods;

				this.schedulePeriods.forEach(function(schedulePeriod) {
					if (schedulePeriod.is_default) {
						self.schedulePeriodId = schedulePeriod.id;
						
						self.changeSchedulePeriod();
					}
				});
			});

			this.refreshTotalOpenTrainingChangesCount();

			this.router.events
				.pipe(takeUntil(this.destroyed$))
				.subscribe((event) => {
					if (event instanceof NavigationEnd) {
						self.currentUrl = event.urlAfterRedirects;
						self.setIsSettingsPage();
					}
				});

			this.currentUrl = this.router.url;
			this.setIsSettingsPage();

			this.innerWidth = window.innerWidth;

			this.environmentService.hoveredElementChanged$
				.pipe(takeUntil(this.destroyed$))
				.subscribe(hoveredElement => this.setTooltip(hoveredElement));

			this.environmentService.refreshShiftCountOverview$
				.pipe(takeUntil(this.destroyed$))	
				.subscribe(data => {
					this.refreshShiftCountOverview(data.schedulePeriodId, data.changedShifts);
				});

			this.environmentService.refreshTotalOpenAllocationRequestCount$
				.pipe(takeUntil(this.destroyed$))	
				.subscribe(data => {
					this.refreshTotalOpenAllocationRequestCount();
				});

			this.environmentService.refreshTotalOpenTrainingChangesCount$
				.pipe(takeUntil(this.destroyed$))	
				.subscribe(data => {
					this.refreshTotalOpenTrainingChangesCount();
				});
			
			this.environmentService.selectedSchedulePeriodChangedExternally$
				.pipe(takeUntil(this.destroyed$))
				.subscribe(schedulePeriod => {
					self.schedulePeriodId = schedulePeriod.id;
					this.changeSchedulePeriod();
				});

			window.addEventListener('storage', (event) => {
				if (event.storageArea == localStorage) {
					let token = localStorage.getItem('user');
					if(token == undefined) { 
						// Perform logout
						//Navigate to login/home
						this.router.navigate(['/login']);
					} else if (this.router.url == '/login') {
						this.redirect(this.user);
					}
				}
			});
		}
	}

	// todo: remove here, dry up
	redirect(user) {
		if (user.customer.customer_type_id == 2) {
			if (user.isAdmin) {
				this.router.navigate(['/app/trainingSchedule']);
			} else {
				this.router.navigate(['/app/trainingCandidates']);
			}
		} else if (user.customer.is_only_regional || user.has_only_access_to_training_period_accumulation_type_id) {
			if (user.isAdmin || user.isTrainingAdmin) {
				this.router.navigate(['/app/trainingSchedule']);
			} else {
				this.router.navigate(['/app/trainingCandidates']);
			}
		} else if (user.isAdmin || user.isSuperAdmin || (user.candidateId == null && !user.isDayScheduleNotAccessible)) {
			this.router.navigate(['/app/daySchedule']);
		} else {
			this.router.navigate(['/app/agenda']);
		}
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}