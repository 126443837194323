import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import templateString from './trainingScheduleVersionForm.component.html';
import * as moment from 'moment';
import { EnvironmentService } from 'site/app/environment.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TrainingScheduleVersion } from 'site/app/models/trainingScheduleVersion.model';
import { Candidate } from 'site/app/models/candidate.model';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TrainingScheduleVersionFormComponent implements OnInit {
    @Output() action = new EventEmitter();

	public referenceTrainingScheduleVersion;
	public isDuplicate;
	
	public trainingScheduleVersion;
	public user: any;
	public actionTitle: string;
	public isLoading = false;
	public submitted: boolean;
	public trainingChanges: any;
	public optimizationStrategies: any;
	public selectedTab: any;
	public trainingChangePartTypes: any;
	public numberOfSelectedTrainingChanges: any;
	public subTypes: { id: number; name: string; }[];
	public checkAllTrainingChanges: any = false;
	public checkMandatoryTrainingChanges: any = false;

	constructor(
		private renderer: Renderer2,
		private http: HttpClient,
		private environmentService: EnvironmentService,
		private bsModalRef: BsModalRef,
		private toastr: ToastrService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

  	ok(isValid) {
		this.submitted = true;

		if (isValid) {
			this.isLoading = true;

			if (this.isDuplicate) {
				this.http.post("training_schedule_versions", this.trainingScheduleVersion).subscribe(() => {
					this.isLoading = false;
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.isLoading = false;
					this.toastr.error(response.error.error);
				});
			} else {
				this.http.put("training_schedule_versions/" + this.trainingScheduleVersion.id, this.trainingScheduleVersion).subscribe((result) => {
					this.isLoading = false;
					this.action.emit();
					this.bsModalRef.hide();
				}, (response) => {
					this.isLoading = false;
					this.toastr.error(response.error.error);
				});
			}
		}
  	};

  	cancel() {
    	this.bsModalRef.hide();
	};
	  
	trackByFunction(index) {
		return index;
	}

	changeTab(tabNumber) {
	    this.selectedTab = tabNumber;
	}

	refreshNumberOfSelectedTrainingChanges() {
		var self = this;

		var numberOfSelectedTrainingChanges = 0;

		this.trainingChanges.forEach(function(y) { 
			if (self.trainingScheduleVersion.training_change_ids[y.id]) {
				numberOfSelectedTrainingChanges += 1;
			}
		});
		this.numberOfSelectedTrainingChanges = numberOfSelectedTrainingChanges;
	}

	checkUncheckAllTrainingChanges() {
        var self = this;
		this.trainingChanges.forEach(function(trainingChange) {
			self.trainingScheduleVersion.training_change_ids[trainingChange.id] = self.checkAllTrainingChanges;
		});

		this.refreshNumberOfSelectedTrainingChanges();
	}
	
	checkUncheckMandatoryTrainingChanges() {
        var self = this;
		this.trainingChanges.forEach(function(trainingChange) {
			if (trainingChange.is_mandatory) {
				self.trainingScheduleVersion.training_change_ids[trainingChange.id] = self.checkMandatoryTrainingChanges;
			}
		});

		this.refreshNumberOfSelectedTrainingChanges();
	}

	ngOnInit() {	
		var self = this;
		
		this.user = this.environmentService.getUser();

		this.selectedTab = 1;

		this.trainingChangePartTypes = [
			"Duur stagetype opleidingsplan", 
			"Startbalans stagetype opleidingsplan", 
			"Duur stage", 
			"Nieuwe stage", 
			"Verwijderde stage", 
			"Nieuwe afwezigheid", 
			"Verwijderde afwezigheid", 
			"Volgordevoorkeur", 
			"Locatievoorkeur"
		];

		this.subTypes = [
			{ id: 1, name: "1. Acute neurologie" },
			{ id: 2, name: "2. Cerebrovasculaire aandoeningen" },
			{ id: 3, name: "3a. Intensive care & Medium care" },
			{ id: 4, name: "3b. Intensive care volwassenen" },
			{ id: 5, name: "4a. Bewegingsstoornissen AMC" },
			{ id: 6, name: "4b. Bewegingsstoornissen VUmc" },
			{ id: 7, name: "5. Neuropsychiatrie" },
			{ id: 8, name: "6. SOLK en somatisch-psychiatrische comorbiditeit" },
			{ id: 9, name: "7. Cognitieve neurologie" },
			{ id: 10, name: "8. Neurodegeneratieve ziekten: bewegingsstoornissen en cognitieve neurologie" },
			{ id: 11, name: "9. Multiple sclerose perifeer in combinatie met algemene polikliniek" },
			{ id: 12, name: "10. Multipele sclerose academisch" },
			{ id: 13, name: "11. Neuro-immunologische en neurologische infectieziekten" },
			{ id: 14, name: "12. Neuro-oncologie" },
			{ id: 15, name: "13. Neuromusculaire ziekten" },
			{ id: 16, name: "14. Klinische epileptologie" },
			{ id: 17, name: "15. Epilepsiechirurgie" },
			{ id: 18, name: "16. Ouderen, cognitieve stoornissen, bewegingsstoornissen en vallen" },
			{ id: 19, name: "17. Polikliniek algemene neurologie" },
			{ id: 20, name: "18. Rug- en radiculaire pijn" },
			{ id: 21, name: "19. Slaapgeneeskunde" },
			{ id: 22, name: "20. KNF perifeer" },
			{ id: 23, name: "21. KNF academisch" },
			{ id: 24, name: "22. KNF epilepsie en het EEG" },
			{ id: 25, name: "23. Leiderschap en management in combinatie met geïndividualiseerde polikliniek stage" },
			{ id: 26, name: "24. Leiderschap en opleiding in combinatie met geïndividualiseerde polikliniek stage" },
			{ id: 27, name: "25. Leiderschap en innovatie in combinatie met geïndividualiseerde polikliniek stage" },
			{ id: 28, name: "Onderwijs" },
			{ id: 29, name: "Overig" },
			{ id: 30, name: "Kinderneurologie specieel" },
		];

		this.optimizationStrategies = [
			{ id: 1, title: "Aantal wijzigingen" }, 
			{ id: 2, title: "Capaciteit" },
			{ id: 3, title: "Voorkeuren" },
			{ id: 4, title: "Opdelingen" }
		];

		this.http.get('training_changes', { params: { approved: "true" } }).subscribe(x => {
			this.trainingChanges = x;
			
			for(var i=0; i < this.trainingChanges.length; i++) {
				var trainingChange = this.trainingChanges[i];

				trainingChange.candidate.lastnameWithInfix = (new Candidate(trainingChange.candidate)).lastnameWithInfix();

				trainingChange.training_change_parts.forEach(function(w) {
					w.is_sub_type = w.sub_type_id != null,
					w.subTypeName = w.sub_type_id ? self.subTypes.filter(function(z) { return z.id == w.sub_type_id; })[0].name : null;	
					w.computedEndDate = w.end_date ? moment(w.end_date).subtract(1, 'day') : null;
				});
			}

			if (this.referenceTrainingScheduleVersion.id == 0) {
				this.actionTitle = "toevoegen";
				this.trainingScheduleVersion = new TrainingScheduleVersion();
				this.trainingScheduleVersion.weight_violations = 10;
				this.trainingScheduleVersion.weight_target_violations = 0.1;
				this.trainingScheduleVersion.weight_changes = 10;
				this.trainingScheduleVersion.weight_order_preferences = 0.1;
				this.trainingScheduleVersion.weight_location_preferences = 0.1;

				this.trainingScheduleVersion.training_change_ids = {};

				this.trainingChanges.forEach(function(y) { 
					self.trainingScheduleVersion.training_change_ids[y.id] = false;
				});

				this.refreshNumberOfSelectedTrainingChanges();
			} else {
				this.http.get("training_schedule_versions/" + this.referenceTrainingScheduleVersion.id).subscribe(x => {
					this.trainingScheduleVersion = x;

					this.trainingScheduleVersion.training_change_ids = {};
					this.referenceTrainingScheduleVersion.training_change_ids = {};

					this.trainingChanges.forEach(function(y) { 
						var isIncluded = self.trainingScheduleVersion.training_changes.map(function(z) { return z.id; }).indexOf(y.id) >= 0;
						self.trainingScheduleVersion.training_change_ids[y.id] = isIncluded;
						self.referenceTrainingScheduleVersion.training_change_ids[y.id] = isIncluded;
					});

					if (this.isDuplicate) {
						this.actionTitle = "toevoegen";
					} else {
						this.actionTitle = "wijzigen";
					}

					this.refreshNumberOfSelectedTrainingChanges();
				});
			}
		});
	}
}
