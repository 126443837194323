import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './environmentForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class EnvironmentFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public originalCustomer: any;

	public customer: any;
	public submitted: boolean;
	public startDate: any;
	public datePickerConfig: any;
	public user: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
        private toastr: ToastrService,
		private environmentService: EnvironmentService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

  	ok(isValid) {
		this.submitted = true;
		
		if (isValid) {
			var startDate = moment(this.startDate);
			startDate.locale('en');
			this.customer.start_date = startDate.format("ddd D MMM YYYY");
				
			this.http.put("customers/" + this.customer.id, this.customer).subscribe(() => {
				this.action.emit(true);
				this.bsModalRef.hide();
			});
		}
	}

  	cancel() {
		this.bsModalRef.hide();
	}

    ngOnInit() {
		this.customer = JSON.parse(JSON.stringify(this.originalCustomer));
		this.user = this.environmentService.getUser();

		this.startDate = moment(this.customer.start_date);

		this.datePickerConfig = {
			firstDayOfWeek: "mo"
		};
	}
}
