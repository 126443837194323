import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './accumulationPeriodForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AccumulationPeriod } from 'site/app/models/accumulationPeriod.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class AccumulationPeriodFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public accumulationPeriodId: any;

	public accumulationPeriod: any;
	public submitted: boolean;
	public actionTitle: string;
	public schedulePeriods: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
        private toastr: ToastrService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	deleteSchedulePeriodId(index) {
		this.accumulationPeriod.schedule_period_ids.splice(index, 1);;
	}
	
	addSchedulePeriodId() {
		this.accumulationPeriod.schedule_period_ids.push(null);
	}
	
	ok(isValid) {
		this.submitted = true;
		
		if (isValid) {
			if (this.accumulationPeriodId === null) {
				this.http.post("accumulation_periods", this.accumulationPeriod).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.error.error);
				});
			} else {
				this.http.put("accumulation_periods/" + this.accumulationPeriod.id, this.accumulationPeriod).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.error.error);
				});
			}
		}
  	}

  	cancel() {
		this.bsModalRef.hide();
  	}

	trackByFunction(index) {
		return index;
	}

    ngOnInit() {
		if (this.accumulationPeriodId === null) {
			this.actionTitle = "toevoegen";
			
			this.accumulationPeriod = new AccumulationPeriod();
			this.accumulationPeriod.schedule_period_ids = [];
		} else {
			this.actionTitle = "wijzigen";
			
			this.http.get("accumulation_periods/" + this.accumulationPeriodId).subscribe(x => {
				this.accumulationPeriod = x;
				this.accumulationPeriod.schedule_period_ids = this.accumulationPeriod.schedule_periods.map(function(y) { return y.id; });
			});
		}
	
		this.http.get("schedule_periods").subscribe(x => {
			this.schedulePeriods = x;
		});
    }
}
