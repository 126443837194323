import { Component, OnInit } from '@angular/core';
import templateString from './taskAccumulationTargets.component.html';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TaskAccumulationTargetFormComponent } from '../taskAccumulationTargetForm';
import { EnvironmentService } from 'site/app/environment.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Candidate } from 'site/app/models/candidate.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TaskAccumulationTargetsComponent implements OnInit {
    public destroyed$: any;
    public isLoading: boolean;
    public accumulationPeriod: any;
    public selectedSchedulePeriod: any;
    public taskAccumulationPeriods: any;
    public candidatesForShiftCountOverview: any;
    public isSuperAdmin: any;
    public customer: any;

    constructor(
        private http: HttpClient,
        private bsModalService: BsModalService,
        private environmentService: EnvironmentService
	) { 
        this.destroyed$ = new Subject<boolean>();
    }

	refresh() {
        this.isLoading = true;

        this.http.get("accumulation_periods/get_by_schedule_period_id", { params: { schedule_period_id: this.selectedSchedulePeriod.id } }).subscribe(x => {
            this.accumulationPeriod = x;
        });

        this.http.get("task_accumulation_periods/index_for_shift_count_overview", 
            { params: { schedule_period_id: this.selectedSchedulePeriod.id } }
        ).subscribe(x => {
			this.taskAccumulationPeriods = x;
			
			if (this.taskAccumulationPeriods.length > 0) {
                this.http.post("candidates/index_for_shift_count_overview",
                    { 
                        schedule_period_id: this.selectedSchedulePeriod.id, 
                        filter_by_location: true,
                        filter_by_has_targets: true
                    }
                ).subscribe(candidates => {
					this.candidatesForShiftCountOverview = candidates;

                    this.candidatesForShiftCountOverview.forEach(function(candidate) {
						candidate.lastnameWithInfix = (new Candidate(candidate)).lastnameWithInfix();
					});
					
					this.isLoading = false;
				});
			} else {
				this.candidatesForShiftCountOverview = [];
				this.isLoading = false;
			}
		});
    }

    edit() {
        var bsModalRef = this.bsModalService.show(TaskAccumulationTargetFormComponent, {
            initialState: {
                schedulePeriodId: this.selectedSchedulePeriod.id,
            },
            class: 'xlModal'
        });

        bsModalRef.content.action.subscribe(result => {
			this.refresh();
        });
    }

	initialize() {
        this.isLoading = true;
		
 		this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();
		
        this.isSuperAdmin = this.environmentService.getUser().isSuperAdmin;
 		this.customer = this.environmentService.getUser().customer;
		
 		this.refresh();
 	}
		
    ngOnInit() {
        this.environmentService.selectedSchedulePeriodChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.initialize();
            });

        this.initialize();
    }

    ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
    }
}
