import { Component, OnInit } from '@angular/core';
import templateString from './schedulePeriods.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { SchedulePeriodFormComponent } from '../schedulePeriodForm';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class SchedulePeriodsComponent implements OnInit {
    public destroyed$: any;
    public schedulePeriods: any;
    public user: any;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService,
        private environmentService: EnvironmentService
	) { }

    refresh() {
        this.http.get("schedule_periods/all").subscribe(x => {
			this.schedulePeriods = x;
			
			for(var i=0; i < this.schedulePeriods.length; i++) {
				var schedulePeriod = this.schedulePeriods[i];

				schedulePeriod.formattedPeriod = moment(schedulePeriod.start_date).format('dd D MMM YYYY') + " t/m " + 
					moment(schedulePeriod.end_date).add(-1, 'days').format('dd D MMM YYYY');
				
                schedulePeriod.isPublishedPartly = schedulePeriod.task_display_groups.filter(function(x) { return x.is_published; }).length > 0 || 
                    schedulePeriod.candidate_type_schedule_periods.filter(function(x) { return x.is_published; }).length > 0;
                
                schedulePeriod.task_display_groups.forEach(taskDisplayGroup => {
                    taskDisplayGroup.nameWithFormattedPublished = taskDisplayGroup.name + " (";

                    if (taskDisplayGroup.published_start_date == null && taskDisplayGroup.published_end_date == null) {
                        taskDisplayGroup.nameWithFormattedPublished += "gehele periode";
                    } else {
                        if (taskDisplayGroup.published_start_date != null) {
                            taskDisplayGroup.nameWithFormattedPublished += "vanaf " + moment(taskDisplayGroup.published_start_date).format("DD-MM-YYYY");
                        }
                        if (taskDisplayGroup.published_start_date != null && taskDisplayGroup.published_end_date != null) {
                            taskDisplayGroup.nameWithFormattedPublished += " ";
                        }
                        if (taskDisplayGroup.published_end_date != null) {
                            taskDisplayGroup.nameWithFormattedPublished += "t/m " + moment(taskDisplayGroup.published_end_date).subtract(1, 'day').format("DD-MM-YYYY");
                        }
                    }

                    taskDisplayGroup.nameWithFormattedPublished += ")";
                });

                schedulePeriod.candidate_type_schedule_periods.forEach(candidateTypeSchedulePeriod => {
                    candidateTypeSchedulePeriod.nameWithFormattedPublished = candidateTypeSchedulePeriod.candidate_type.name + " (";

                    if (candidateTypeSchedulePeriod.published_start_date == null && candidateTypeSchedulePeriod.published_end_date == null) {
                        candidateTypeSchedulePeriod.nameWithFormattedPublished += "gehele periode";
                    } else {
                        if (candidateTypeSchedulePeriod.published_start_date != null) {
                            candidateTypeSchedulePeriod.nameWithFormattedPublished += "vanaf " + moment(candidateTypeSchedulePeriod.published_start_date).format("DD-MM-YYYY");
                        }
                        if (candidateTypeSchedulePeriod.published_start_date != null && candidateTypeSchedulePeriod.published_end_date != null) {
                            candidateTypeSchedulePeriod.nameWithFormattedPublished += " ";
                        }
                        if (candidateTypeSchedulePeriod.published_end_date != null) {
                            candidateTypeSchedulePeriod.nameWithFormattedPublished += "t/m " + moment(candidateTypeSchedulePeriod.published_end_date).subtract(1, 'day').format("DD-MM-YYYY");
                        }
                    }

                    candidateTypeSchedulePeriod.nameWithFormattedPublished += ")";
                });
			}
		});
	}

    create() {
        var bsModalRef = this.bsModalService.show(SchedulePeriodFormComponent, {
            initialState: {
                schedulePeriodId: null
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    edit(schedulePeriodId) {
        var bsModalRef = this.bsModalService.show(SchedulePeriodFormComponent, {
            initialState: {
                schedulePeriodId: schedulePeriodId
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

	delete(schedulePeriodId) {
		if (window.confirm('Weet je het zeker?')) {
            this.http.delete("schedule_periods/" + schedulePeriodId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.toastr.error(response.error.error);
			});
		}
	};

    updateShiftsCache(schedulePeriodId) {
        this.http.post("schedule_periods/update_shifts_cache",
            { id: schedulePeriodId }
        ).subscribe((result) => {
            this.toastr.success("Cache update initiated");
        });
    }

    ngOnInit() {
        this.user = this.environmentService.getUser();

        this.refresh();
    }
}
