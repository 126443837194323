import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import templateString from './applyDefaultAssignmentDetails.component.html';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Candidate } from 'site/app/models/candidate.model';
import { EnvironmentService } from 'site/app/environment.service';
import { Task } from 'site/app/models/task.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class ApplyDefaultAssignmentDetailsComponent implements OnInit {
	@Output() action = new EventEmitter();

	public selectedSchedulePeriod;
	public selectedTaskDisplayGroupId;

	public activeDateStart;
	public activeDateEnd;
	public formattedStartDate;
	public formattedEndDate;
	public displayDateStart;
	public displayDateEnd;
	public datePickerConfig;
	public applyToPartialPeriod;
	public minDate: any;
	public maxDate: any;
	public startDate: any;
	public endDate: any;
	public submitted: any;
	public isLoading: any;
	public shifts: any;
	public showShiftSelection: any;
	public checkAllShifts: any = true;
	public numberOfSelectedShifts;
	public overwriteAssignments: any;

	constructor(
		private http: HttpClient,
		private renderer: Renderer2,
		private bsModalRef: BsModalRef,
		private toastr: ToastrService,
		private environmentService: EnvironmentService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	initializePeriod() {
		this.activeDateStart = moment(this.startDate);
		this.activeDateEnd = moment(this.endDate).subtract(1, 'day');
		
		this.displayDateStart = moment(this.activeDateStart);
		this.displayDateEnd = moment(this.activeDateEnd);

		this.changeStartDate();
		this.changeEndDate();
	}

	changeStartDate() {
		this.formattedStartDate = moment(this.activeDateStart).format("DD-MM-YYYY");
	 
		if (this.activeDateStart > this.activeDateEnd) {
			this.displayDateEnd = moment(this.activeDateStart);

		 	// this is a hack because changing displayDate and ngModel date at the same time will only change one of them...
		 	setTimeout(() => {
			 	this.activeDateEnd = moment(this.activeDateStart);
				this.changeEndDate();
			}, 1000);			   
		}
	}

	changeEndDate() {
		this.formattedEndDate = moment(this.activeDateEnd).format("DD-MM-YYYY");
	}

	ok(isValid) {
		this.submitted = true;

		if (isValid && (!this.applyToPartialPeriod || moment(this.activeDateStart) <= moment(this.activeDateEnd))) {
			var startDate = moment(this.activeDateStart);
			var endDate = moment(this.activeDateEnd).add(1, 'day');
			startDate.locale('en');
			endDate.locale('en');
			this.startDate = startDate.format("ddd D MMM YYYY");
			this.endDate = endDate.format("ddd D MMM YYYY");

			this.isLoading = true;

			if (!this.showShiftSelection) {

				this.http.get("default_assignments/get_affected_shifts",
					{
						params: {
							schedule_period_id: this.selectedSchedulePeriod.id,
							task_display_group_id: this.selectedTaskDisplayGroupId,
							apply_to_partial_period: this.applyToPartialPeriod,
							start_date_partial_period: this.startDate,
							end_date_partial_period: this.endDate,
							overwrite_assignments: this.overwriteAssignments	
						}
					}					
				).subscribe(shifts => {				
					this.isLoading = false;
					this.showShiftSelection = true;
					this.shifts = shifts;

					this.shifts.forEach(function(shift) {
						shift.apply_change = true;
					
						shift.infixWithLastname = shift.periodic_candidate == null || shift.periodic_candidate == undefined ? "" : (new Candidate(shift.periodic_candidate.candidate)).infixWithLastname();
						shift.newInfixWithLastname = shift.new_periodic_candidate == null || shift.new_periodic_candidate == undefined ? "" : (new Candidate(shift.new_periodic_candidate.candidate)).infixWithLastname();
						
						shift.formattedPeriod = moment(shift.start_date).isSame(moment(shift.end_date).add(-1, 'days')) ? 
							moment(shift.start_date).format('dd D MMM') : 
							moment(shift.start_date).format('dd D MMM') + " t/m " + moment(shift.end_date).add(-1, 'days').format('dd D MMM');
							shift.formattedDateForDomId = moment(shift.start_date).format('YYYYMMDD') + "-" + moment(shift.end_date).format('YYYYMMDD');
					
						shift.task.computedName = (new Task(shift.task)).getComputedName();
					});
	
				}, (response) => {
					this.isLoading = false;
					this.toastr.error(response.error.error);
					console.error(response);
				});

			} else {
				this.applyToDaySchedule()
			}

		}
	}

	refreshNumberOfSelectedShifts() {
		this.numberOfSelectedShifts = this.shifts.filter(function(x) { return x.apply_change; }).length;
	}

	applyToDaySchedule() {
		this.http.post("default_assignments/apply_to_day_schedule",
			{
				schedule_period_id: this.selectedSchedulePeriod.id,
				shifts: this.shifts.filter(function(x) { return x.apply_change; })
			}
		).subscribe(data => {				
			this.isLoading = false;
			this.toastr.success("Het basisrooster is succesvol toegepast op het dagrooster");
			this.bsModalRef.hide();
		}, (response) => {
			this.isLoading = false;
			this.toastr.error(response.error.error);
			console.error(response);
			this.bsModalRef.hide();
		});
	}

	cancel() {
		this.bsModalRef.hide();
	}

	checkUncheckAllShifts() {
        var self = this;
		this.shifts.forEach(function(shift) {
			shift.apply_change = self.checkAllShifts;
		});

		this.refreshNumberOfSelectedShifts();
	}

	ngOnInit(): void {
		this.applyToPartialPeriod = false;
		this.showShiftSelection = false;
		this.numberOfSelectedShifts = 0;

		this.minDate = moment(this.selectedSchedulePeriod.start_date);
		this.maxDate = moment(this.selectedSchedulePeriod.end_date).subtract(1, 'day');

		// TODO: DRY
		this.selectedSchedulePeriod.nameWithPeriod = this.selectedSchedulePeriod.name + " (" + moment(this.selectedSchedulePeriod.start_date).format("D MMM YYYY") + " - " + moment(this.selectedSchedulePeriod.end_date).subtract(1, 'day').format("D MMM YYYY") + ")";

		this.datePickerConfig = {
			firstDayOfWeek: "mo"
		};
	
		this.startDate = moment(this.selectedSchedulePeriod.start_date);
		this.endDate = moment(this.selectedSchedulePeriod.end_date);
   
		this.initializePeriod();
	}
}
