import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { appRoutingModule } from './../app.routing';
import { ProfileComponent } from './profile';
import { ProfileFormComponent } from './profileForm';

@NgModule({
  declarations: [
    ProfileComponent,
    ProfileFormComponent
  ],
  imports: [
		BrowserModule,
    appRoutingModule,
    FormsModule
	],
  providers: [
  ],
  entryComponents: [
    ProfileFormComponent
  ]
})
export class ProfileModule {}
