import { NgModule } from '@angular/core';
import { MyScrollSenderDirective } from './myScrollSender.directive';
import { ScrollSpyDirective } from './scrollSpy.directive';

@NgModule({
    declarations: [
      MyScrollSenderDirective,
      ScrollSpyDirective
    ],
    exports: [
      MyScrollSenderDirective,
      ScrollSpyDirective
    ],
})
export class DirectivesModule{}