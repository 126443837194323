import { Component, OnInit, OnDestroy } from "@angular/core";
import templateString from './allocationRequests.component.html'
import { Candidate } from "site/app/models/candidate.model";
import * as moment from 'moment';
import { TimeSlot } from "site/app/models/timeSlot.model";
import { BsModalService } from "ngx-bootstrap/modal";
import { EnvironmentService } from "site/app/environment.service";
import { ToastrService } from "ngx-toastr";
import { takeUntil } from "rxjs/operators";
import { forkJoin, Subject } from "rxjs";
import { AllocationRequestFormComponent } from "../allocationRequestForm";
import { HttpClient } from "@angular/common/http";

@Component({ 
	template: templateString
})
export class AllocationRequestsComponent implements OnInit, OnDestroy {
	public selectedSchedulePeriod;
	public user;
	public allocationRequests;
	public weekdays;
	public candidateLastnames;
	public isLoading;
	public allocationRequestStatuses;
	public sortReverse;
	public sortType;
	public destroyed$: any;
	public published_candidate_type_schedule_periods_contains_candidate_type_of_current_user: any;
	public changeLogs: any;

	constructor(
		private http: HttpClient,
		private bsModalService: BsModalService,
		private environmentService: EnvironmentService,
		private toastr: ToastrService
	) { 
		this.destroyed$ = new Subject<boolean>();
	}

	refresh() {
		forkJoin([
			this.http.get('allocation_requests',
				{ 
					params: {
						schedule_period_id: this.selectedSchedulePeriod.id, 
						candidate_id: this.user.isAdmin || this.user.isAllocationRequestAdmin ? "" : this.user.candidateId 	
					}
				}
			),
			this.http.get('change_logs/unread_for_allocation_requests', 
				{
					params: {
						schedule_period_id: this.selectedSchedulePeriod.id
					}
				}
			)
		]).subscribe(data => {
			this.allocationRequests = data[0];
			this.changeLogs = data[1];

			this.changeLogs.forEach(function(changeLog) {
				changeLog.infixWithLastname = (new Candidate(changeLog.periodic_candidate.candidate)).infixWithLastname();
				
				if (changeLog.created_by_user.candidate) {
					changeLog.created_by_user.candidate.infixWithLastname = (new Candidate(changeLog.created_by_user.candidate)).infixWithLastname();
				}
			});
			
			for(var i=0; i < this.allocationRequests.length; i++) {
				var allocationRequest = this.allocationRequests[i];

				allocationRequest.periodic_candidate.candidate.lastnameWithInfix = (new Candidate(allocationRequest.periodic_candidate.candidate)).lastnameWithInfix();

				// allocation_request_type_id is 'compensatieweek' 
				// or date_selection_type_id is 'periode' (for both 'afwezig' and 'geen dienst')
				// or (task_type_id != null and is_period_containing_tasks
				if (
					(allocationRequest.allocation_request_type.task_id != null && allocationRequest.allocation_request_type.is_allocate_preference) || 
					allocationRequest.date_selection_type_id == 1 ||
					(allocationRequest.allocation_request_type.task_type_id != null && 
						allocationRequest.allocation_request_type.is_allocate_preference &&
						!allocationRequest.allocation_request_type.is_period_containing_tasks) ||
					allocationRequest.allocation_request_type.is_time_registration
				) {
					allocationRequest.formattedPeriod = moment(allocationRequest.start_date).format('dd D MMM YYYY') + 
						(moment(allocationRequest.end_date).subtract(1, 'day').isSame(allocationRequest.start_date) ? "" : 
							" t/m " + moment(allocationRequest.end_date).add(-1, 'days').format('dd D MMM YYYY'));
					
					allocationRequest.periodForSort = moment(allocationRequest.start_date).format('YYYYMMDD') + 
						(moment(allocationRequest.end_date).subtract(1, 'day').isSame(allocationRequest.start_date) ? "" : 
							moment(allocationRequest.end_date).add(-1, 'days').format('YYYYMMDD'));


					if (allocationRequest.allocation_request_type.is_time_registration) {
						allocationRequest.formattedPeriod += " (" + moment(allocationRequest.allocation_request_parts[0].overwritten_start_time).format('HH:mm') + " - " +
							moment(allocationRequest.allocation_request_parts[0].overwritten_end_time).format('HH:mm') + ")"
					}
				} else if (allocationRequest.date_selection_type_id == 2) {
					// losse dagen (for both 'afwezig' and 'geen dienst')
					allocationRequest.formattedPeriod = allocationRequest.allocation_request_parts.map(function(x) { 
						return moment(x.start_date).format('dd D MMM YYYY') }).join(", ");

					allocationRequest.periodForSort = allocationRequest.allocation_request_parts.map(function(x) { 
						return moment(x.start_date).format('YYYYMMDD') }).join("");
				} else if (allocationRequest.date_selection_type_id == 3) {
					allocationRequest.formattedPeriod = "Elke " + this.weekdays[allocationRequest.day_of_week-1] + " van " + moment(allocationRequest.start_date).format('dd D MMM YYYY') + " t/m " + 
						moment(allocationRequest.end_date).add(-1, 'days').format('dd D MMM YYYY');
						
					allocationRequest.periodForSort = moment(allocationRequest.start_date).format('YYYYMMDD') + 
						moment(allocationRequest.end_date).add(-1, 'days').format('YYYYMMDD');
				}
				
				this.refreshStatus(allocationRequest);
			}
			
			this.candidateLastnames = this.allocationRequests.map(function(x) { return x.periodic_candidate.candidate.lastnameWithInfix }
				).filter(function(value, index, self) { return self.indexOf(value) === index; }).sort();
			
			this.isLoading = false;
		});
	}
	
	refreshStatus(allocationRequest) {
		if (allocationRequest.allocation_request_parts.filter(function(x) { return x.status_id == 1 }).length == allocationRequest.allocation_request_parts.length) {
			allocationRequest.status = this.allocationRequestStatuses[0];
			allocationRequest.isNotProcessedEntirelyOrPartly = false;
			allocationRequest.statusForSort = 1;
		} else if (allocationRequest.allocation_request_parts.filter(function(x) { return x.status_id == 3 }).length == allocationRequest.allocation_request_parts.length) {
			allocationRequest.status = this.allocationRequestStatuses[2];
			allocationRequest.isNotProcessedEntirelyOrPartly = false;
			allocationRequest.statusForSort = 3;
		} else if (allocationRequest.allocation_request_parts.filter(function(x) { return x.status_id == 2 }).length == 0) {
			allocationRequest.status = this.allocationRequestStatuses[0] + "/" + this.allocationRequestStatuses[2];
			allocationRequest.isNotProcessedEntirelyOrPartly = false;
			allocationRequest.statusForSort = 2;
		} else {
			allocationRequest.status = this.allocationRequestStatuses[1];
			allocationRequest.isNotProcessedEntirelyOrPartly = true;
			allocationRequest.statusForSort = 0;
		}
	}
	
	getSortValue(a, b) {
		if (a < b) {
			return this.sortReverse ? 1 : -1;
		} else if (a > b) {
			return this.sortReverse ? -1 : 1;
		}
		return 0;
	}

	changeSort(sortType, event) {
		var self = this;
		
		if (sortType == this.sortType) {
			this.sortReverse = !this.sortReverse;
		} else {
			this.sortReverse = false;
		}
		this.sortType = sortType;

		this.allocationRequests = this.allocationRequests.sort(function(a, b) { 
			switch(self.sortType) {
				case 'periodic_candidate.candidate.lastnameWithInfix': 					
				  return self.getSortValue(a.periodic_candidate.candidate.lastnameWithInfix, b.periodic_candidate.candidate.lastnameWithInfix)
				case 'allocation_request_type.name': 
				  return self.getSortValue(a.allocation_request_type.name, b.allocation_request_type.name)
			  	case 'periodForSort': 
				  return self.getSortValue(a.periodForSort, b.periodForSort)
				case 'formattedTime': 
				  return self.getSortValue(a.formattedTime, b.formattedTime)
				case 'reason': 
				  return self.getSortValue(a.reason, b.reason)
				case 'comment': 
				  return self.getSortValue(a.comment, b.comment)
			  	case 'created_at': 
				  return self.getSortValue(a.created_at, b.created_at)
				case 'updated_request_part_at': 
				  return self.getSortValue(a.updated_request_part_at, b.updated_request_part_at)
			  	case 'statusForSort': 
				  return self.getSortValue(a.statusForSort, b.statusForSort)
				case 'admin_comment': 
				  return self.getSortValue(a.admin_comment, b.admin_comment)
			}
		});

		event.preventDefault();
	}

  	create() {
		var bsModalRef = this.bsModalService.show(AllocationRequestFormComponent, {
            initialState: {
                allocationRequestId: null,
				selectedSchedulePeriod: this.selectedSchedulePeriod,
				published_candidate_type_schedule_periods_contains_candidate_type_of_current_user: this.published_candidate_type_schedule_periods_contains_candidate_type_of_current_user
            },
            class: 'xlModal'
        });

        bsModalRef.content.action.subscribe(result => {
			this.environmentService.refreshTotalOpenAllocationRequestCount();
	    	this.refresh();
		});
  	}

  	edit(allocationRequestId) {
		var bsModalRef = this.bsModalService.show(AllocationRequestFormComponent, {
            initialState: {
                allocationRequestId: allocationRequestId,
				selectedSchedulePeriod: this.selectedSchedulePeriod,
				published_candidate_type_schedule_periods_contains_candidate_type_of_current_user: this.published_candidate_type_schedule_periods_contains_candidate_type_of_current_user
            },
            class: 'xlModal'
        });

        bsModalRef.content.action.subscribe(result => {
			this.environmentService.refreshTotalOpenAllocationRequestCount();
	    	this.refresh();
		});
  	};

	delete(allocationRequestId) {
		if (window.confirm('Weet je zeker dat je dit verzoek wilt verwijderen?')) {
			this.isLoading = true;

			this.http.delete("allocation_requests/" + allocationRequestId).subscribe(() => {
				this.environmentService.refreshTotalOpenAllocationRequestCount();
				this.refresh();
			});
		}
	};

	approveAll(allocationRequestId) {
		if (window.confirm('Weet je zeker dat je alle niet verwerkte onderdelen in dit verzoek wilt accepteren?')) {
			this.isLoading = true;

			this.http.post("allocation_requests/approve_all", 
				{ id: allocationRequestId }
			).subscribe((result) => {
				var changedShifts = JSON.parse(result['changed']);
			
				if (changedShifts.length > 0){
					window.alert("De volgende activiteiten zijn automatisch toegewezen door het accepteren van de betreffende verzoeksonderdelen: " + 
						changedShifts.map(function(x) { return "\n- " + x.task.name + " (" + moment(x.start_date).format("DD-MM-YYYY") + ") toegewezen aan " + (new Candidate(x.periodic_candidate.candidate)).infixWithLastname() }))
				}
				
				var allocationRequest = this.allocationRequests.filter(function(x) { return x.id == allocationRequestId })[0];
				
				allocationRequest.allocation_request_parts.filter(function(x) { return x.status_id == 2 }).forEach(function(allocationRequestPart) {
					allocationRequestPart.status_id = 3;
				});
				
				allocationRequest.updated_request_part_at = moment().format('YYYY-MM-DD HH:mm');
				this.refreshStatus(allocationRequest);

				this.isLoading = false;
				
				this.toastr.success("De niet-verwerkte onderdelen in dit verzoek zijn succesvol geaccepteerd");
				
				this.environmentService.refreshTotalOpenAllocationRequestCount();
			});
		}
	}

	markAsRead(changeLog) {
		this.isLoading = true;

		this.http.post("change_logs/mark_as_read",
			{ id: changeLog.id }
		).subscribe((result) => {

			for( var i = 0; i < this.changeLogs.length; i++){ 
				if ( this.changeLogs[i].id === changeLog.id) {
					this.changeLogs.splice(i, 1); 
				}
			}

			console.log("hier tijdelijk");

			this.isLoading = false;

			this.toastr.success("Verwijderd verzoek is gemarkeerd als gelezen");

			this.environmentService.refreshTotalOpenAllocationRequestCount();
		});
	}

	initialize() {
		var self = this;

		this.isLoading = true;
		
		// Empty allocationRequests otherwise status of requests in non-published schedule period is visible because suddenly the 
		// selectedSchedulePeriod could be published which triggers the status of the allocationRequests in non-published schedule period
		// to be visible
		this.allocationRequests = [];
		this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();
		
		this.sortType = '';
		this.sortReverse = false;
		
		this.allocationRequestStatuses = ["Geweigerd", "Niet verwerkt", "Geaccepteerd"];
	  	this.user = this.environmentService.getUser();

		this.weekdays = ["maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag", "zondag"];

		var periodicCandidatesForSchedulePeriod = this.user.periodicCandidates.filter(function(x) { return x.schedule_period_id == self.selectedSchedulePeriod.id });
		var candidateTypeIdForSchedulePeriod = periodicCandidatesForSchedulePeriod.length > 0 ? periodicCandidatesForSchedulePeriod[0].candidate_type_id : null;

		this.published_candidate_type_schedule_periods_contains_candidate_type_of_current_user = 
			this.selectedSchedulePeriod.candidate_type_schedule_periods.filter(function(x) { 
				return x.candidate_type_id == candidateTypeIdForSchedulePeriod && x.is_published;
			}).length > 0;

		this.refresh();
	}
	
	ngOnInit() {
		if (this.environmentService.getSchedulePeriodSelection() != null) {
			this.initialize();
		}

		this.environmentService.selectedSchedulePeriodChanged$
			.pipe(takeUntil(this.destroyed$))
			.subscribe(() => {
				this.initialize();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
    }
}
