import { Component, OnInit, Renderer2 } from '@angular/core';
import templateString from './populatedReportDetails.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Candidate } from 'site/app/models/candidate.model';

@Component({ template: templateString })
export class PopulatedReportDetailsComponent implements OnInit {
	public report: any;
	public candidate: any;
	public beginningOfMonth: any;
	public occupationsAndTotals: any;
	
	public isLoading: boolean;
	public occupationsForMonth: any;
	public occupationTotalsForMonth: any;
	public horizontalScrollReceiver2: any;
	public verticalScrollReceiver2: any;

    constructor(
        private bsModalRef: BsModalRef,
        private renderer: Renderer2
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

  	ok() {
		this.bsModalRef.hide();
	}
	  
	cancel() {
		this.bsModalRef.hide();
  	}

	syncScroll(event) {
		this.horizontalScrollReceiver2.scrollLeft = event.scrollLeft;
		this.verticalScrollReceiver2.scrollTop = event.scrollTop;
	}

    ngOnInit() {
		this.candidate = new Candidate(this.candidate);
	}

	ngAfterViewInit() {
		this.horizontalScrollReceiver2 = document.querySelectorAll('.myHorizontalScrollReceiver2')[0];
		this.verticalScrollReceiver2 = document.querySelectorAll('.myVerticalScrollReceiver2')[0];
	}
}
