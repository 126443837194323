import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './taskAccumulationPeriodForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TaskAccumulationMembership } from 'site/app/models/taskAccumulationMembership.model';
import { TaskAccumulationPeriod } from 'site/app/models/taskAccumulationPeriod.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TaskAccumulationPeriodFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public taskAccumulationPeriodId: any;
	public schedulePeriodId: any;
	public taskAccumulationPeriods: any;

	public taskAccumulationPeriod: any;
	public submitted: boolean;
	public actionTitle: string;
	public schedulePeriodsForTasks: any;
	public taskVariantGroupsForTaskVariants: any;
	public combinationAlreadyExists: boolean;
	public accumulationPeriod: any;
	public isLoading: boolean;
	public taskVariantGroups: any;
	public isNew: any;
	public accumulationPeriods: any;
	public taskAccumulationTypes: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
        private toastr: ToastrService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	deleteTaskAccumulationMembership(index) {
		this.taskAccumulationPeriod.task_accumulation_memberships.splice(index, 1);
		this.schedulePeriodsForTasks.splice(index, 1);
		this.taskVariantGroupsForTaskVariants.splice(index, 1);
	}
	
	addTaskAccumulationMembership(hasTaskVariant) {
		this.taskAccumulationPeriod.task_accumulation_memberships.push(new TaskAccumulationMembership({ hasTaskVariant: hasTaskVariant }));
		this.schedulePeriodsForTasks.push(null);
		this.taskVariantGroupsForTaskVariants.push(null);
	}

	changePeriodOrAccumulationType(isInitialize) {
		var self = this;

		if (this.taskAccumulationPeriod.task_accumulation_type_id == null || 
				this.taskAccumulationPeriod.accumulation_period_id == null) {
			this.schedulePeriodsForTasks = [];
			this.taskVariantGroupsForTaskVariants = [];
			this.taskAccumulationPeriod.task_accumulation_memberships = [];
			this.combinationAlreadyExists = false;
		} else {
			if (!isInitialize) {
				this.schedulePeriodsForTasks = [];
				this.taskVariantGroupsForTaskVariants = [];
				this.taskAccumulationPeriod.task_accumulation_memberships = [];
			}
	
			this.combinationAlreadyExists = this.taskAccumulationPeriods.filter(function(x) { 
				return x.id != self.taskAccumulationPeriod.id && 
					x.task_accumulation_type_id == self.taskAccumulationPeriod.task_accumulation_type_id &&
					x.accumulation_period_id == self.taskAccumulationPeriod.accumulation_period_id
			}).length > 0;
	
			this.http.get("accumulation_periods/show_extended",
				{
					params: {
						id: this.taskAccumulationPeriod.accumulation_period_id
					}
				}
			).subscribe(x => {
				this.accumulationPeriod = x;
				
				this.schedulePeriodsForTasks = this.taskAccumulationPeriod.task_accumulation_memberships.map(function(y) {
					return y.hasTaskVariant ? null : self.accumulationPeriod.schedule_periods.filter(function(z) { return z.id == y.task.schedule_period_id })[0];
				});
		
				this.accumulationPeriod.schedule_periods.forEach(function(schedulePeriod) {
					schedulePeriod.tasks.forEach(function(task) {
						task.nameWithEquivalencePosition = task.name + (task.equivalence_position == null ? "" : " (" + task.equivalence_position + ")");
					});
				});
	
				this.isLoading = false;
			});
			
			this.http.get("task_variant_groups").subscribe(x => {
				this.taskVariantGroups = x;
				
				this.taskVariantGroupsForTaskVariants = this.taskAccumulationPeriod.task_accumulation_memberships.map(function(y) {
					return y.hasTaskVariant ? self.taskVariantGroups.filter(function(z) { return z.id == y.task_variant.task_variant_group_id })[0] : null;
				});
			});
		}
	}
	
	ok(isValid) {
		this.submitted = true;
	
		if (isValid && 
			this.taskAccumulationPeriod.task_accumulation_memberships.length > 0 &&
			!this.combinationAlreadyExists
		) {
			this.isLoading = true;

			if (this.isNew) {
				this.http.post("task_accumulation_periods", this.taskAccumulationPeriod).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.error.error);
				});
			} else {
				this.http.put("task_accumulation_periods/" + this.taskAccumulationPeriod.id, this.taskAccumulationPeriod).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.error.error);
				});
			}
		}
	}

  	cancel() {
		this.bsModalRef.hide();
	}
	  
	trackByFunction(index) {
		return index;
	}

    ngOnInit() {
		this.isNew = this.taskAccumulationPeriodId == null;
	
		this.http.get("accumulation_periods/get_by_schedule_period_id",
			{
				params: {
					schedule_period_id: this.schedulePeriodId
				}
			}
		).subscribe(x => {
			this.accumulationPeriods = x;
		});
		
		this.http.get("task_accumulation_types").subscribe(x => {
			this.taskAccumulationTypes = x;
		});

		if (this.isNew) {
			this.actionTitle = "toevoegen";
			this.taskAccumulationPeriod = new TaskAccumulationPeriod();
			this.schedulePeriodsForTasks = [];
			this.taskAccumulationPeriod.task_accumulation_memberships = [];
		} else {
			this.actionTitle = "wijzigen";
			this.http.get("task_accumulation_periods/" + this.taskAccumulationPeriodId).subscribe(x => {
				this.taskAccumulationPeriod = x;
				
				this.taskAccumulationPeriod.task_accumulation_memberships.forEach(function(y, index) {
					y.index = index;
					y.hasTaskVariant = y.task_variant_id != null;
				});
				
				this.changePeriodOrAccumulationType(true);
			});
		}
    }
}
