import { Component, OnInit, OnDestroy } from '@angular/core';
import templateString from './periodicCandidates.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EnvironmentService } from 'site/app/environment.service';
import { Subject } from 'rxjs';
import { Candidate } from 'site/app/models/candidate.model';
import { PeriodicCandidateFormComponent } from '../periodicCandidateForm';
import { PeriodicCandidateCloneComponent } from '../periodicCandidateClone';
import { PeriodicCandidateChangeOrderComponent } from '../periodicCandidateChangeOrder';
import { takeUntil } from 'rxjs/operators';
import { ProfileFormComponent } from 'site/app/profile/profileForm';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

@Component({ template: templateString })
export class PeriodicCandidatesComponent implements OnInit {
    public destroyed$: any;
    public selectedSchedulePeriod: any;
    public periodicCandidates: any;
    public isLoading: boolean;
    public weekdays: string[];
    public workWeekdays: any;
    public user: any;
    public isTestEnvironment: boolean;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService,
        private environmentService: EnvironmentService
	) {
        this.destroyed$ = new Subject<boolean>();
    }

    refresh() {
        var self = this;

        this.http.get("periodic_candidates", 
            {
                params: {
                    schedule_period_id: this.selectedSchedulePeriod.id, 
                    filter_by_location: 'true'
                }
            }
        ).subscribe(x => {
			this.periodicCandidates = x;
			this.periodicCandidates.forEach(function(periodicCandidate) {
				periodicCandidate.fullName = (new Candidate(periodicCandidate.candidate)).fullName();
				
				if (periodicCandidate.candidate.users.length > 0) {
					periodicCandidate.candidate.user = periodicCandidate.candidate.users[0];
				}

                periodicCandidate.candidate.ftesForCurrentYears = periodicCandidate.candidate.yearly_ftes
                    .filter(function(x) { return x.year == moment(self.selectedSchedulePeriod.start_date).year() || x.year == moment(self.selectedSchedulePeriod.end_date).year() })
                    .map(function(x) { return x.fte ? x.fte : 1 })
                    .join("; ");
			});
			
			this.isLoading = false;
		});
	}
	
	changeSchedulePeriod() {
		this.isLoading = true;
		
		this.refresh();
	}

    create() {
        var bsModalRef = this.bsModalService.show(PeriodicCandidateFormComponent, {
            initialState: {
                periodicCandidateId: null,
                schedulePeriod: this.selectedSchedulePeriod
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
            this.isLoading = true;
			this.refresh();
        });
    }
	
    cloneFromPreviousSchedulePeriods() {
        var bsModalRef = this.bsModalService.show(PeriodicCandidateCloneComponent, {
            initialState: {
                periodicCandidatesCurrentSchedulePeriod: this.periodicCandidates,
                currentSchedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
            this.isLoading = true;
			this.refresh();
        });      
    }
	
	sendRegistrationMail(candidateId) {
		if (window.confirm('Weet je het zeker?')) {
			this.isLoading = true;
            
            this.http.post("other_users/send_registration_mail",
                { candidate_id: candidateId }
            ).subscribe(() => {
				this.refresh();
			});
		}
	};

    edit(periodicCandidateId) {
        var bsModalRef = this.bsModalService.show(PeriodicCandidateFormComponent, {
            initialState: {
                periodicCandidateId: periodicCandidateId,
                schedulePeriod: this.selectedSchedulePeriod
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
            this.isLoading = true;
			this.refresh();
        });
    }

	delete(periodicCandidateId) {
		if (window.confirm('Weet je het zeker?')) {
			this.isLoading = true;
            
            this.http.delete("periodic_candidates/" + periodicCandidateId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.isLoading = false;
				this.toastr.error(response.error.error, '', { enableHtml: true });
			});
		}
	};

    changeOrder() {
        var bsModalRef = this.bsModalService.show(PeriodicCandidateChangeOrderComponent, {
            initialState: {
                periodicCandidates: this.periodicCandidates
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
            this.isLoading = true;
			this.refresh();
        });
    }

    editProfile(userId) {
        this.isLoading = true;

		this.http.get("other_users/get_for_profile",
			{
                params: {
                    id: userId, 
                    schedule_period_id: this.selectedSchedulePeriod.id    
                }
			}
		).subscribe(x => {
			var extendedUser = x;

            var bsModalRef = this.bsModalService.show(ProfileFormComponent, {
                initialState: {
                    originalExtendedUser: extendedUser,
                    schedulePeriodId: this.selectedSchedulePeriod.id
                },
                class: 'xlModal'
            });
    
            bsModalRef.content.action.subscribe(result => {
                this.refresh();
            });

			this.isLoading = false;
		});
    }

    setPasswordForTest(userId) {
        this.http.post("other_users/set_password_for_test", { user_id: userId }).subscribe(() => {
            this.toastr.success("Wachtwoord aangepast");
        });
    }
	
	initialize() {
		this.isLoading = true;
        
        this.isTestEnvironment = (window.location.host === 'localhost:3000');
        this.user = this.environmentService.getUser();
		this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();

		this.weekdays = ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"];
		this.workWeekdays = this.weekdays.slice(1, 6);

		this.refresh();
	}
	
    ngOnInit() {
        this.environmentService.selectedSchedulePeriodChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.initialize();
            });

        this.initialize();
    }

    ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
    }
}
