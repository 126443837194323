import * as moment from 'moment';

export class TimeSlot {
	public name;
	public start_time;
	public end_time;
	
	constructor(obj) {
		this.name = obj.name;
		this.start_time = obj.start_time;
		this.end_time = obj.end_time;
	}

	nameWithTime() {
	    return this.name + " (" + moment(this.start_time).format("HH:mm") + " - " + moment(this.end_time).format("HH:mm") + ")";
	};
} 
