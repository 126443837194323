import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './taskAccumulationTypeForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TaskAccumulationType } from 'site/app/models/taskAccumulationType.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TaskAccumulationTypeFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public taskAccumulationTypeId: any;

	public taskAccumulationType: any;
	public submitted: boolean;	
	public actionTitle: string;
	public isLoading: boolean;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
        private toastr: ToastrService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

  	ok(isValid) {
		this.submitted = true;
		
		if (isValid) {
			if (this.taskAccumulationTypeId === null) {
				this.http.post("task_accumulation_types", this.taskAccumulationType).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.error.error);
				});
			} else {
				this.http.put("task_accumulation_types/" + this.taskAccumulationType.id, this.taskAccumulationType).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.error.error);
				});
			}
		}
  	}

  	cancel() {
		this.bsModalRef.hide();
  	}

    ngOnInit() {
		if (this.taskAccumulationTypeId === null) {
			this.actionTitle = "toevoegen";
			this.taskAccumulationType = new TaskAccumulationType();
		} else {
			this.actionTitle = "wijzigen";
			this.http.get("task_accumulation_types/" + this.taskAccumulationTypeId).subscribe(x => {
				this.taskAccumulationType = x;
			});
		}
    }
}
