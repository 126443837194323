import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import templateString from './allocationRequestAccumulationTypeChangeOrder.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class AllocationRequestAccumulationTypeChangeOrderComponent implements OnInit {
    @Output() action = new EventEmitter();
    
    public originalAllocationRequestAccumulationTypes: any;
    
    public allocationRequestAccumulationTypes: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2
    ) { 
        this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
    }

    ok() {
        this.http.post("allocation_request_accumulation_types/update_order", { allocation_request_accumulation_type_ids: this.allocationRequestAccumulationTypes.map(function(x) { return x.id; }) }).subscribe(() => {
            this.action.emit(true);
            this.bsModalRef.hide();
		});
    }

    cancel() {
        this.bsModalRef.hide();
    };

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.allocationRequestAccumulationTypes, event.previousIndex, event.currentIndex);
    }

    ngOnInit() {
        this.allocationRequestAccumulationTypes = JSON.parse(JSON.stringify(this.originalAllocationRequestAccumulationTypes));
    }
}
