import { Component, OnInit, OnDestroy } from '@angular/core';
import templateString from './tasks.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EnvironmentService } from 'site/app/environment.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TaskFormComponent } from '../taskForm';
import { TaskCloneComponent } from '../taskClone';
import { TaskChangeOrderComponent } from '../taskChangeOrder/taskChangeOrder.component';
import { Task } from 'site/app/models/task.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TasksComponent implements OnInit, OnDestroy {
    public tasks;
    public selectedSchedulePeriod;
    public isLoading = false;
    public isSuperAdmin: any;
    public customer: any;
    public destroyed$: any;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService,
        private environmentService: EnvironmentService
	) {
        this.destroyed$ = new Subject<boolean>();
    }

    refresh() {
        this.http.get<any>("tasks",
            { params: { schedule_period_id: this.selectedSchedulePeriod.id } }
        ).subscribe(tasks => {
            this.tasks = tasks.map(function(x) { return new Task(x); });

            var currentTaskDisplayGroupName = null;
            this.tasks.forEach(function(task) {
                task.computedName = task.getComputedName();

                if (task.task_display_group != null && task.task_display_group.name != currentTaskDisplayGroupName) {
                    task.taskDisplayGroupName = task.task_display_group.name;
                    currentTaskDisplayGroupName = task.taskDisplayGroupName;
                }
            });

            this.isLoading = false;
        });
    }

    resetShifts(taskId) {
		if (window.confirm('Weet je het zeker?')) {
			this.isLoading = true;

            this.http.get("tasks/reset_shifts", 
                { params: { task_id: taskId, schedule_period_id: this.selectedSchedulePeriod.id } }
            ).subscribe(() => {
				this.isLoading = false;
				this.toastr.success("reset successful");
			}, () => {
				this.isLoading = false;
				this.toastr.success("reset failed");
			});
		}
	}
	
	testError() { 
		this.http.get("tasks/test_error").subscribe(() => {
			console.error("error not given");
		}, (error) => {
			console.error("error returned");
			console.error(error);
		});
	}

	testErrorClientSide() { 
		var arr = [];
		var b = arr[0].nonexistingproperty;
	}

    create() {
        var bsModalRef = this.bsModalService.show(TaskFormComponent, {
            initialState: {
                taskId: null,
                schedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'xlModal'
        });

        bsModalRef.content.action.subscribe(result => {	
            this.isLoading = true;
			this.refresh();
        });
  	}
  
    cloneFromPreviousSchedulePeriods() {
        var bsModalRef = this.bsModalService.show(TaskCloneComponent, {
            initialState: {
                tasksCurrentSchedulePeriod: this.tasks,
                currentSchedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
            this.isLoading = true;
			this.refresh();
        });
    }

	clone(taskId) {
		if (window.confirm('Weet u zeker dat u deze activiteit wilt dupliceren?')) {
			this.isLoading = true;
            
            this.http.post("tasks/clone",
                { 
                    current_schedule_period_id: this.selectedSchedulePeriod.id, 
                    task_ids: [taskId]
                }
            ).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.isLoading = false;
				this.toastr.error(response.error.error);
			});
		}
	}

    edit(taskId) {
        var bsModalRef = this.bsModalService.show(TaskFormComponent, {
            initialState: {
                taskId: taskId,
                schedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'xlModal'
        });

        bsModalRef.content.action.subscribe(result => {	
            this.isLoading = true;
			this.refresh();
        });
	}

	delete(taskId) {
		if (window.confirm('Weet u het zeker dat u deze activiteit wilt verwijderen?')) {
			this.isLoading = true;
            
            this.http.delete("tasks/" + taskId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.isLoading = false;
				this.toastr.error(response.error.error);
			});
		}
	}
	
    changeOrder() {
        var bsModalRef = this.bsModalService.show(TaskChangeOrderComponent, {
            initialState: {
                tasks: this.tasks,
                schedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
            this.isLoading = true;
			this.refresh();
        });
    }

	initialize() {
		this.isLoading = true;
		
		this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();
		
		this.isSuperAdmin = this.environmentService.getUser().isSuperAdmin;
		this.customer = this.environmentService.getUser().customer;
		
		this.refresh();
    }
    
    ngOnInit() {
        this.environmentService.selectedSchedulePeriodChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.initialize();
            });

        this.initialize();
    }

    ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
    }
}
