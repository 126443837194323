import { NgModule } from "@angular/core";
import { MonthScheduleComponent } from "./monthSchedule";
import { BrowserModule } from "@angular/platform-browser";
import { appRoutingModule } from "../app.routing";
import { FormsModule } from "@angular/forms";
import { ModalModule } from "ngx-bootstrap/modal";
import { PipesModule } from "../pipes";
import { DirectivesModule } from "../directives";
import { TemplatePeriodFormComponent } from "./templatePeriodForm";
import { DpDatePickerModule } from 'ng2-date-picker';
import { SelectShiftChangesForTemplatePeriodsComponent } from "./selectShiftChangesForTemplatePeriods";
import { CanDeactivateGuardMonth } from "./canDeactivateGuardMonth";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ResizableModule } from "angular-resizable-element";


@NgModule({
  	declarations: [
		MonthScheduleComponent,
		TemplatePeriodFormComponent,
		SelectShiftChangesForTemplatePeriodsComponent
	],
  	imports: [
		BrowserModule,
		appRoutingModule,
		FormsModule,
		DirectivesModule,
		ModalModule.forRoot(),
		PipesModule,
		DpDatePickerModule,
		DragDropModule,
		ResizableModule
	],
  	providers: [	
		CanDeactivateGuardMonth
	],
	entryComponents: [
		TemplatePeriodFormComponent,
		SelectShiftChangesForTemplatePeriodsComponent
	]
})
export class MonthScheduleModule {}
