import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './allocationRequestAccumulationPeriodForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AllocationRequestAccumulationPeriodMembership } from 'site/app/models/allocationRequestAccumulationPeriodMembership.model';
import { AllocationRequestAccumulationPeriod } from 'site/app/models/allocationRequestAccumulationPeriod.model';
import { AllocationRequestBudgetComponent } from 'site/app/models/allocationRequestBudgetComponent.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class AllocationRequestAccumulationPeriodFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public allocationRequestAccumulationPeriodId: any;
	public year: any;
	public allocationRequestAccumulationPeriods: any;

	public allocationRequestAccumulationPeriod: any;
	public submitted: boolean;
	public actionTitle: string;
	public schedulePeriodsForAllocationRequestTypes: any;
	public combinationAlreadyExists: boolean;
	public isLoading: boolean;
	public isNew: any;
	public schedulePeriods: any;
	public allocationRequestAccumulationTypes: any;
	public taskVariantGroupsForTaskVariants: any;
	public taskVariantGroups: any;
	public taskTypes: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
        private toastr: ToastrService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	deleteAllocationRequestAccumulationPeriodMembership(index) {
		this.allocationRequestAccumulationPeriod.allocation_request_accumulation_period_memberships.splice(index, 1);
		this.schedulePeriodsForAllocationRequestTypes.splice(index, 1);
		this.taskVariantGroupsForTaskVariants.splice(index, 1);
	}
	
	addAllocationRequestAccumulationPeriodMembership(hasTaskVariant, hasTaskType) {
		this.allocationRequestAccumulationPeriod.allocation_request_accumulation_period_memberships.push(new AllocationRequestAccumulationPeriodMembership({ hasTaskVariant: hasTaskVariant, hasTaskType: hasTaskType }));
		this.schedulePeriodsForAllocationRequestTypes.push(null);
		this.taskVariantGroupsForTaskVariants.push(null);
	}

	deleteAllocationRequestBudgetComponent(index) {
		this.allocationRequestAccumulationPeriod.allocation_request_budget_components.splice(index, 1);
	}
	
	addAllocationRequestBudgetComponent() {
		this.allocationRequestAccumulationPeriod.allocation_request_budget_components.push(new AllocationRequestBudgetComponent());
	}

	changeAccumulationType() {
		var self = this;

		if (this.allocationRequestAccumulationPeriod.allocation_request_accumulation_type_id == null) {
			this.combinationAlreadyExists = false;
		} else {
			this.combinationAlreadyExists = this.allocationRequestAccumulationPeriods.filter(function(x) { 
				return x.id != self.allocationRequestAccumulationPeriod.id && 
					x.task_accumulation_type_id == self.allocationRequestAccumulationPeriod.allocation_request_accumulation_type_id &&
					x.year == self.allocationRequestAccumulationPeriod.year
			}).length > 0;
		}
	}
	
	ok(isValid) {
		this.submitted = true;
	
		if (isValid && 
			this.allocationRequestAccumulationPeriod.allocation_request_accumulation_period_memberships.length > 0 &&
			!this.combinationAlreadyExists
		) {
			this.isLoading = true;

			if (this.isNew) {
				this.http.post("allocation_request_accumulation_periods", this.allocationRequestAccumulationPeriod).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.error.error);
				});
			} else {
				this.http.put("allocation_request_accumulation_periods/" + this.allocationRequestAccumulationPeriod.id, this.allocationRequestAccumulationPeriod).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.error.error);
				});
			}
		}
	}

  	cancel() {
		this.bsModalRef.hide();
	}
	  
	trackByFunction(index) {
		return index;
	}

    ngOnInit() {
		var self = this;

		this.isLoading = true;
		this.isNew = this.allocationRequestAccumulationPeriodId == null;
		
		this.http.get("allocation_request_accumulation_types").subscribe(x => {
			this.allocationRequestAccumulationTypes = x;
		});

		this.schedulePeriodsForAllocationRequestTypes = [];

		this.http.get("schedule_periods/by_year",
			{
				params: {
					year: this.year
				}
			}
		).subscribe(x => {
			this.schedulePeriods = x;
			
			this.http.get("task_variant_groups").subscribe(x => {
				this.taskVariantGroups = x;

				if (this.isNew) {
					this.actionTitle = "toevoegen";
					this.allocationRequestAccumulationPeriod = new AllocationRequestAccumulationPeriod({ year: this.year });
					this.allocationRequestAccumulationPeriod.allocation_request_accumulation_period_memberships = [];
					this.allocationRequestAccumulationPeriod.allocation_request_budget_components = [];
					this.taskVariantGroupsForTaskVariants = [];
					this.isLoading = false;
				} else {
					this.actionTitle = "wijzigen";
					this.http.get("allocation_request_accumulation_periods/" + this.allocationRequestAccumulationPeriodId).subscribe(x => {
						this.allocationRequestAccumulationPeriod = x;
						
						this.allocationRequestAccumulationPeriod.allocation_request_accumulation_period_memberships.forEach(function(y, index) {
							y.index = index;
							y.hasTaskVariant = y.task_variant_id != null;
							y.hasTaskType = y.task_type_id != null;

							self.schedulePeriodsForAllocationRequestTypes.push(
								y.hasTaskVariant || y.hasTaskType ? null : self.schedulePeriods.filter(function(z) { return z.id == y.allocation_request_type.schedule_period_id })[0]
							);
						})

						this.allocationRequestAccumulationPeriod.allocation_request_budget_components.forEach(function(y, index) {
							y.index = index;
						});

						this.taskVariantGroupsForTaskVariants = this.allocationRequestAccumulationPeriod.allocation_request_accumulation_period_memberships.map(function(y) {
							return y.hasTaskVariant ? self.taskVariantGroups.filter(function(z) { return z.id == y.task_variant.task_variant_group_id })[0] : null;
						});
					});	

					this.isLoading = false;
				}
			});
		});

		this.http.get("task_types").subscribe(x => {
			this.taskTypes = x;
		});	
    }
}
