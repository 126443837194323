import { Component, OnInit } from '@angular/core';
import templateString from './allocationRequestTypes.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { EnvironmentService } from 'site/app/environment.service';
import { takeUntil } from 'rxjs/operators';
import { AllocationRequestTypeFormComponent } from '../allocationRequestTypeForm';
import { AllocationRequestTypeChangeOrderComponent } from '../allocationRequestTypeChangeOrder/allocationRequestTypeChangeOrder.component';
import { AllocationRequestTypeCloneComponent } from '../allocationRequestTypeClone';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class AllocationRequestTypesComponent implements OnInit {
    
    public allocationRequestTypes: any;
    public selectedSchedulePeriod: any;
    public destroyed$: any;
    public isLoading: any;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService,
        private environmentService: EnvironmentService
	) { 
        this.destroyed$ = new Subject<boolean>();
    }

    refresh() {
        this.http.get("allocation_request_types", { params: { schedule_period_id: this.selectedSchedulePeriod.id } }).subscribe(x => {
            this.allocationRequestTypes = x;
            this.isLoading = false;
        });
    }
    
    changeSchedulePeriod() {
        this.refresh();
    }

    create() {
        var bsModalRef = this.bsModalService.show(AllocationRequestTypeFormComponent, {
            initialState: {
                allocationRequestTypeId: null,
                schedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    cloneFromPreviousSchedulePeriods() {
        var bsModalRef = this.bsModalService.show(AllocationRequestTypeCloneComponent, {
            initialState: {
                allocationRequestTypesCurrentSchedulePeriod: this.allocationRequestTypes,
                currentSchedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
            this.isLoading = true;
			this.refresh();
        });      
    }

    edit(allocationRequestTypeId) {
        var bsModalRef = this.bsModalService.show(AllocationRequestTypeFormComponent, {
            initialState: {
                allocationRequestTypeId: allocationRequestTypeId,
                schedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    delete(allocationRequestTypeId) {
        if (window.confirm('Weet je het zeker?')) {
            this.http.delete("allocation_request_types/" + allocationRequestTypeId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.toastr.error(response.error.error);
			});
        }
    };
    
    changeOrder() {
        var bsModalRef = this.bsModalService.show(AllocationRequestTypeChangeOrderComponent, {
            initialState: {
                originalAllocationRequestTypes: this.allocationRequestTypes
            }
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    initialize() {
		this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();

		this.refresh();
	}
	
    ngOnInit() {
        this.environmentService.selectedSchedulePeriodChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.initialize();
            });

        this.initialize();
    }

    ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
    }
}
