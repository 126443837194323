import { Component, OnInit, Renderer2 } from '@angular/core';
import templateString from './populatedReportBudgets.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Candidate } from 'site/app/models/candidate.model';

@Component({ template: templateString })
export class PopulatedReportBudgetsComponent implements OnInit {
	public report: any;
	public candidate: any;
	public year: any;
	public budgetsAndTotals: any;
	
    constructor(
        private bsModalRef: BsModalRef,
        private renderer: Renderer2
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

  	ok() {
		this.bsModalRef.hide();
	}
	  
	cancel() {
		this.bsModalRef.hide();
  	}

    ngOnInit() {
		this.candidate = new Candidate(this.candidate);
	}
}
