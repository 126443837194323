import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import templateString from './periodicCandidateChangeOrder.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Candidate } from 'site/app/models/candidate.model';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class PeriodicCandidateChangeOrderComponent implements OnInit {
    @Output() action = new EventEmitter();
    
    public periodicCandidates: any;
    
    public isLoading;
    public allPeriodicCandidates;
    public candidateTypes;
    public selection: any = {};
    public previousSelection;
    public isChanged: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
        private environmentService: EnvironmentService
    ) { 
        this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
    }

    changeCandidateType() {
		if (!this.isChanged || window.confirm('De wijzigingen in de volgorde zijn nog niet opgeslagen. Weet je zeker dat je door wilt gaan?')) {
			this.isLoading = true;
			
			this.previousSelection = { candidateTypeId: this.selection.candidateTypeId };
			this.refreshPeriodicCandidates();
		} else {
			this.selection = { candidateTypeId: this.previousSelection.candidateTypeId };
		}
	}
	
	refreshPeriodicCandidates() {
        var self = this;
		this.periodicCandidates = this.allPeriodicCandidates.filter(function(x) { return x.candidate_type_id == self.selection.candidateTypeId; });
		this.isChanged = false;
		this.isLoading = false;
	} 

    ok() {
        this.isLoading = true;
        
        this.http.post("periodic_candidates/update_order", { periodic_candidate_ids: this.periodicCandidates.map(function(x) { return x.id; }) }).subscribe(() => {
			this.isLoading = false;
            this.action.emit(true);
            this.bsModalRef.hide();
		});
    }

    cancel() {
        this.bsModalRef.hide();
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.periodicCandidates, event.previousIndex, event.currentIndex);
        this.isChanged = true;
    }

    ngOnInit() {
        this.isLoading = true;

        this.allPeriodicCandidates = JSON.parse(JSON.stringify(this.periodicCandidates));
     
        this.allPeriodicCandidates.forEach(function(periodicCandidate) {
            periodicCandidate.fullName = (new Candidate(periodicCandidate.candidate)).fullName();
        });
        
        this.http.get("candidate_types").subscribe(candidateTypes => {
            this.candidateTypes = candidateTypes;
            
            this.selection = { candidateTypeId: this.candidateTypes[0].id };
            this.previousSelection = { candidateTypeId: this.selection.candidateTypeId };
            
            this.refreshPeriodicCandidates();
        });
    }
}
