import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import templateString from './selectRangeForPrint.component.html';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({ template: templateString })
export class SelectRangeForPrintComponent implements OnInit {
	@Output() action = new EventEmitter();

	public startDate;
	public endDate;

	public activeDateStart;
	public activeDateEnd;
	public formattedStartDate;
	public formattedEndDate;
	public displayDateStart;
	public displayDateEnd;
	public datePickerConfig;

	constructor(
		private renderer: Renderer2,
		private bsModalRef: BsModalRef
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	changeStartDate() {
		this.formattedStartDate = moment(this.activeDateStart).format("DD-MM-YYYY");
	}

	changeEndDate() {
		this.formattedEndDate = moment(this.activeDateEnd).format("DD-MM-YYYY");
	}

	ok() {
		this.startDate = moment(this.activeDateStart);
		this.endDate = moment(this.activeDateEnd).add(1, 'day');

		this.action.emit({ startDate: this.startDate, endDate: this.endDate });
		this.bsModalRef.hide();
	};

	cancel() {
		this.bsModalRef.hide();
	};

	ngOnInit(): void {
		this.datePickerConfig = {
			firstDayOfWeek: "mo"
		};

		this.activeDateStart = moment(new Date(this.startDate));
		this.activeDateEnd = moment(new Date(this.endDate.subtract(1, 'day')));
	
		this.displayDateStart = this.activeDateStart;
		this.displayDateEnd = this.activeDateEnd;

		this.changeStartDate();
		this.changeEndDate();
	}
}
