import { Component, OnInit, Renderer2, Output, EventEmitter, ElementRef } from '@angular/core';
import templateString from './changeDefaultAssignmentModal.component.html'
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { Task } from 'site/app/models/task.model';
import { EnvironmentService } from 'site/app/environment.service';
import { DataService } from 'site/app/data.service';
import { Candidate } from 'site/app/models/candidate.model';
import { Shift } from 'site/app/models/shift.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class ChangeDefaultAssignmentModalComponent implements OnInit {
	@Output() action = new EventEmitter();
	
	public task: Task;
	public selectedGanttTasksForMultiSelect;
	
	public isLoading;
	public sortType;
	public sortReverse;
	public shift;
	public data = [];
	public shifts = [];
	public assignedPeriodicCandidate;
	public periodicCandidates;
	public assignedTaskVariant;
	public ganttTasks;
	public selectedSchedulePeriod;
	public fromDate;
	public toDate;
	public taskLengthInDays;
	public modalClosedByAction;
	public numberOfSelectedItems;
	public user: any;

	constructor(
		private bsModalRef: BsModalRef,
		private environmentService: EnvironmentService,
		private http: HttpClient,
		private dataService: DataService,
		private renderer: Renderer2,
		private host: ElementRef
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	changeSort(sortType, event) {
		var self = this;
		
		if (sortType == this.sortType) {
			this.sortReverse = !this.sortReverse;
		} else {
			this.sortReverse = false;
		}
		this.sortType = sortType;
	
		this.data = this.data.sort(function(a, b) { 
			switch(self.sortType) {
			  case 'rank':
				return self.sortReverse ? b.rank - a.rank : a.rank - b.rank;
			  case 'name':
				if (a.name < b.name) {
				    return self.sortReverse ? 1 : -1;
				} else if (a.name > b.name ) {
					return self.sortReverse ? -1 : 1;
				}
				return 0;
			}
		});
	
		event.preventDefault();
	}

	checkIsCancelled() {
		if (this.shift.is_cancelled) {
			this.shift.is_cancelled = false;
		}
	};

	toggleAssignee(periodicCandidateId, performCheckIsCancelled) {
		if (performCheckIsCancelled) {
			this.checkIsCancelled();
		}

		var clickedGanttRow = this.data.filter(function(x) { return x.periodicCandidateId == periodicCandidateId })[0];

		clickedGanttRow.isSelected = !clickedGanttRow.isSelected;

		this.updateAssignedPeriodicCandidate(periodicCandidateId, clickedGanttRow.isSelected);
	}

	toggleTaskVariant(taskVariantId) {
		var clickedGanttRow = this.data.filter(function(x) { return x.taskVariantId == taskVariantId })[0];

		clickedGanttRow.isSelected = !clickedGanttRow.isSelected;

		this.updateAssignedTaskVariant(taskVariantId, clickedGanttRow.isSelected);
	}

	updateAssignedPeriodicCandidate(periodicCandidateId, isSelected) {
		var self = this;

		if (isSelected) {
			if (this.shift.periodic_candidate_id != null) {
				this.data.filter(function(x) { return x.isSelected && x.periodicCandidateId != periodicCandidateId; })[0].isSelected = false;
			}
			this.shift.periodic_candidate_id = periodicCandidateId;
		} else {
			this.shift.periodic_candidate_id = null;
		}

		this.assignedPeriodicCandidate = this.shift.periodic_candidate_id == null ? null : this.periodicCandidates.filter(function(x) { return x.id == self.shift.periodic_candidate_id; })[0];
	}

	updateAssignedTaskVariant(taskVariantId, isSelected) {
		var self = this;
		if (isSelected) {
			if (this.shift.task_variant_id != null) {
				this.data.filter(function(x) { return x.isSelected && x.taskVariantId != taskVariantId; })[0].isSelected = false;
			}
			this.shift.task_variant_id = taskVariantId;
		} else {
			this.shift.task_variant_id = null;
		}

		this.assignedTaskVariant = this.shift.task_variant_id == null ? null : this.task.task_variant_group.task_variants.filter(function(x) { return x.id == self.shift.task_variant_id; })[0];
	}

	toggleIsCancelled(isCancelled) {
		this.shift.is_cancelled = isCancelled;

	  if (isCancelled && this.shift.periodic_candidate_id != null) {
		  this.toggleAssignee(this.shift.periodic_candidate_id, false);
	  }
	};

	ok() {
		this.action.emit(this.shift);
		this.bsModalRef.hide();
	}

  	dismiss() {
		this.bsModalRef.hide();
  	}

	cancel() {
		this.bsModalRef.hide();
	}

	getColor(colorId) {
        return this.dataService.colors[colorId];
    }

	ngOnInit() {
		var self = this;
		this.isLoading = true;

		this.user = this.environmentService.getUser();

	 	if (this.task == null) {
	 		this.ganttTasks = this.selectedGanttTasksForMultiSelect;
	 	} else {
	 		this.ganttTasks = [this.task];
	 	}

		this.shifts = this.ganttTasks.map(function(x) { return x.shift; });

		this.data = [];
		var ganttRows = [];

		this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();

		this.sortType = 'rank';
		this.sortReverse = false;

		if (this.ganttTasks.length == 1) {
			this.shift = this.shifts[0];
			
			this.fromDate = moment(this.ganttTasks[0].from);
			this.toDate = moment(this.ganttTasks[0].to).subtract(1, 'day');
			this.taskLengthInDays = moment(this.ganttTasks[0].to).diff(moment(this.ganttTasks[0].from), 'days');

			// needed for when comment is edited, then dismissing the modal should still refresh the schedule
			this.modalClosedByAction = false;
		} else {
			this.shift = new Shift();
			this.shift.is_cancelled = false;
			this.numberOfSelectedItems = this.selectedGanttTasksForMultiSelect.length;
		}

		this.http.get('tasks/' + this.ganttTasks[0].task.id).subscribe(fetchedTask => {
			this.task = new Task(fetchedTask);
			this.task.computedName = this.task.getComputedName();

			if (this.task.periodic_candidate_id == null) {
				
				this.http.get('periodic_candidates/for_default_assignment_modal', 
					{ 
						params: {
							'task_ids[]': this.shifts.map(function(x) { return x.task_id; })
						}
					}
				).subscribe(x => {
					this.periodicCandidates = x;

					if (this.shift == null || this.shift.periodic_candidate_id == null) {
						this.assignedPeriodicCandidate = null;
					} else {
						this.assignedPeriodicCandidate = this.periodicCandidates.filter(function(x) { return x.id == self.shift.periodic_candidate_id; })[0];
						
						this.assignedPeriodicCandidate.infixWithLastname = 
							self.user.customer.use_firstnames ?
								(new Candidate(this.assignedPeriodicCandidate.candidate)).firstnameWithInitial() :
								(new Candidate(this.assignedPeriodicCandidate.candidate)).infixWithLastname();
					}

					this.periodicCandidates.forEach(function(periodicCandidate) {

						periodicCandidate.candidateName =
							self.user.customer.use_firstnames ?
								(new Candidate(periodicCandidate.candidate)).firstnameWithInitial() :
								(new Candidate(periodicCandidate.candidate)).infixWithLastname();

						periodicCandidate.infixWithLastname = (new Candidate(periodicCandidate.candidate)).infixWithLastname();
						periodicCandidate.lastnameWithInfix = (new Candidate(periodicCandidate.candidate)).lastnameWithInfix();

						var ganttRow = {
							name: (self.user.customer.use_firstnames ? (new Candidate(periodicCandidate.candidate)).firstnameWithInitial() : (new Candidate(periodicCandidate.candidate)).lastnameWithInfix()) + " (" + periodicCandidate.candidate_type.name + ")",
							periodicCandidateId: periodicCandidate.id,
							alias: periodicCandidate.alias,
							isSelected: self.shift.periodic_candidate_id == periodicCandidate.id,
							candidateTypeId: periodicCandidate.candidate_type_id,
							rank: periodicCandidate.rank
						};

						ganttRows.push(ganttRow);
					});

					this.data = ganttRows;

					this.isLoading = false;
				});

			} else {

				if (this.shift == null || this.shift.task_variant_id == null) {
					this.assignedTaskVariant = null;
				} else {
					this.assignedTaskVariant = this.task.task_variant_group.task_variants.filter(function(x) { return x.id == self.shift.task_variant_id; })[0];
				}

				this.task.task_variant_group.task_variants.forEach(function(taskVariant) {

					var ganttRow = {
						name: taskVariant.name,
						taskVariantId: taskVariant.id,
						colorId: taskVariant.color_id,
						alias: taskVariant.alias,
						isSelected: self.shift.task_variant_id == taskVariant.id,
						rank: taskVariant.position
					};

					ganttRows.push(ganttRow);
				});

				this.data = ganttRows;

				this.isLoading = false;
			}

		}, () => {
			this.bsModalRef.hide();
		});
	}

	ngAfterViewInit() {	
		if (this.user.customer.id == 19) {
			setTimeout(()=>{
				var nameFilter = this.host.nativeElement.querySelector('.nameFilterString');
				nameFilter.focus();
			}, 1000)
		}
	}

}