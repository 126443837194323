import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import templateString from './trainingPeriodForm.component.html';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EnvironmentService } from 'site/app/environment.service';
import { Candidate } from 'site/app/models/candidate.model';
import { TrainingPeriod } from 'site/app/models/trainingPeriod.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TrainingPeriodFormComponent implements OnInit {
	@Output() action = new EventEmitter();

	public trainingPeriod;
	public trainingPeriodTask;
	public filterOptionId;
	
	public user: any;
	public showDeleteButton: boolean;
	public modalClosedByAction: boolean;
	public datePickerConfig: { firstDayOfWeek: string; };
	public candidates: any;
	public trainingPeriodAccumulationTypes: any;
	public activeDateStart;
	public activeDateEnd;
	public isNew: boolean;
	public actionTitle: string;
	public locations: any;
	public submitted: boolean;
	public displayDateStart: any;
	public displayDateEnd: any;
	
	constructor(
		private renderer: Renderer2,
		private http: HttpClient,
		private bsModalRef: BsModalRef,
		private environmentService: EnvironmentService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	changeStartDate() {
		this.trainingPeriod.formattedStartDate = moment(this.activeDateStart).format("DD-MM-YYYY");

		if (this.activeDateStart >= this.activeDateEnd) {
			var newEndDate = moment(this.activeDateStart).add(1, 'month');

			this.displayDateEnd = moment(newEndDate);

			// this is a hack because changing displayDate and ngModel date at the same time will only change one of them...
			setTimeout(() => {
				this.activeDateEnd = moment(newEndDate);
				this.changeEndDate();
			}, 500);
		}
	}

	changeEndDate() {
		this.trainingPeriod.formattedEndDate = moment(this.activeDateEnd).format("DD-MM-YYYY");
	}

	initializePeriod(startDate, endDate) {
		this.activeDateStart = startDate;
		this.activeDateEnd = endDate;
		
		this.displayDateStart = this.activeDateStart;
		this.displayDateEnd = this.activeDateEnd;

		this.changeStartDate();
		this.changeEndDate();
	}

	ok(isValid) {
		var self = this;

		this.submitted = true;
		
		if (isValid && moment(this.activeDateStart) <= moment(this.activeDateEnd)) {
			this.trainingPeriod.training_period_accumulation_type = this.trainingPeriodAccumulationTypes.filter(function(x) { return x.id == self.trainingPeriod.training_period_accumulation_type_id })[0];
			this.trainingPeriod.candidate = this.candidates.filter(function(x) { return x.id == self.trainingPeriod.candidate_id })[0];
			this.trainingPeriod.location = this.locations.filter(function(x) { return x.id == self.trainingPeriod.location_id })[0];

			this.trainingPeriod.start_date = moment(this.activeDateStart);
			this.trainingPeriod.end_date = moment(this.activeDateEnd).add(1, 'day');

			this.trainingPeriod.ratio = this.trainingPeriod.ratio.toString().replace(',', '.');

			this.modalClosedByAction = true;

			this.action.emit([false, this.trainingPeriod]);
			this.bsModalRef.hide();
		}
	};

	delete() {
		this.modalClosedByAction = true;
		this.action.emit([true, this.trainingPeriod]);
		this.bsModalRef.hide();
	}

	cancel() {
		this.bsModalRef.hide();
	}

	ngOnInit(): void {
		this.user = this.environmentService.getUser();

		this.showDeleteButton = false;

		this.modalClosedByAction = false;

		this.datePickerConfig = {
			firstDayOfWeek: "mo"
		};

		this.http.get("candidates/index_trainee_candidates", 
			{ params: { show_current_and_future_candidates: (this.filterOptionId == 1).toString() } }
		).subscribe(candidates => {
			this.candidates = candidates;
			
			this.candidates.forEach(function(candidate) {
				candidate.lastnameWithInfix = (new Candidate(candidate)).lastnameWithInfix();
			});
		});

		this.http.get("training_period_accumulation_types").subscribe(x => {
			this.trainingPeriodAccumulationTypes = x;
		});

		this.http.get("locations").subscribe(x => {
			this.locations = x;
		});

		if (this.trainingPeriod === null) {
			this.isNew = true;
			this.actionTitle = "toevoegen";
			this.trainingPeriod = new TrainingPeriod({ is_locked: false });
			this.trainingPeriod.ratio = 1.0;
			this.trainingPeriod.candidate_id = this.trainingPeriodTask == null ? null : this.trainingPeriodTask.row.id;
			
			var today = new Date();
			var oneWeekFromNow = new Date();
			oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);
	
			this.initializePeriod(
				this.trainingPeriodTask == null ? moment(today) : moment(new Date(this.trainingPeriodTask.from)),
				this.trainingPeriodTask == null ? moment(oneWeekFromNow) : moment(this.trainingPeriodTask.to).subtract(1, 'day')
			);
		} else {
			this.isNew = false;
			this.actionTitle = "wijzigen";
			this.showDeleteButton = true;
			this.trainingPeriod = this.trainingPeriod;
			
			this.initializePeriod(moment(new Date(this.trainingPeriod.start_date)), moment(this.trainingPeriod.end_date).subtract(1, 'day'));
		}
	
		this.trainingPeriod.ganttTaskId = this.trainingPeriodTask == null ? null : this.trainingPeriodTask.id;
	}
}
