import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import templateString from './templatePeriodForm.component.html';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Candidate } from 'site/app/models/candidate.model';
import { TemplatePeriod } from 'site/app/models/templatePeriod.model';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TemplatePeriodFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public schedulePeriod;
	public templatePeriod;
	public templatePeriodTask;
	
	public showDeleteButton: boolean;
	public modalClosedByAction: boolean;
	public periodicCandidates: any;
	public activeDateStart;
	public activeDateEnd;
	public submitted: boolean;
	public tasks: any;
	public isNew: boolean;
	public actionTitle: string;
	public datePickerConfig;
	public minDate;
	public maxDate;
	public displayDateStart;
	public displayDateEnd;

	constructor(
		private renderer: Renderer2,
		private http: HttpClient,
		private bsModalRef: BsModalRef,
		private environmentService: EnvironmentService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	changeStartDate() {
		this.templatePeriod.formattedStartDate = moment(this.activeDateStart).format("DD-MM-YYYY");

		if (this.activeDateStart >= this.activeDateEnd) {
			var newEndDate = moment(this.activeDateStart).add(1, 'month');

			if (newEndDate.isAfter(moment(this.schedulePeriod.end_date))) {
				newEndDate = moment(this.schedulePeriod.end_date).subtract(1, 'day');
			}

			this.displayDateEnd = moment(newEndDate);

			// this is a hack because changing displayDate and ngModel date at the same time will only change one of them...
			setTimeout(() => {
				this.activeDateEnd = moment(newEndDate);
				this.changeEndDate();
			}, 500);
		}
	}
	
	changeEndDate() {
		this.templatePeriod.formattedEndDate = moment(this.activeDateEnd).format("DD-MM-YYYY");
	}
	
	initializePeriod(startDate, endDate) {
		this.activeDateStart = startDate;
		this.activeDateEnd = endDate;
		
		this.displayDateStart = this.activeDateStart;
		this.displayDateEnd = this.activeDateEnd;

		this.changeStartDate();
		this.changeEndDate();
	}

	ok(isValid) {
		var self = this;

		this.submitted = true;
		
		if (isValid && moment(this.activeDateStart) <= moment(this.activeDateEnd)) {
			this.templatePeriod.task = this.tasks.filter(function(x) { return x.id == self.templatePeriod.task_id })[0];
			this.templatePeriod.periodicCandidate = this.periodicCandidates.filter(function(x) { return x.id == self.templatePeriod.periodic_candidate_id })[0];
	
			this.templatePeriod.start_date = moment(self.activeDateStart).format("YYYY-MM-DD");
			this.templatePeriod.end_date = moment(self.activeDateEnd).add(1, 'day').format("YYYY-MM-DD");;
	
			this.modalClosedByAction = true;

			this.action.emit([false, this.templatePeriod]);
			this.bsModalRef.hide();
		}
	};
	
	delete() {
		this.modalClosedByAction = true;
		this.action.emit([true, this.templatePeriod]);
		this.bsModalRef.hide();
	};
	
	cancel() {
		this.bsModalRef.hide();
	};

	ngOnInit() {
		this.showDeleteButton = false;

		this.modalClosedByAction = false;

		this.minDate = moment(this.schedulePeriod.start_date);
		this.maxDate = moment(this.schedulePeriod.end_date).subtract(1, 'day');

		this.datePickerConfig = {
			firstDayOfWeek: "mo"
		};

		// TODO: DRY
		this.schedulePeriod.nameWithPeriod = this.schedulePeriod.name + " (" + moment(this.schedulePeriod.start_date).format("D MMM YYYY") + " - " + moment(this.schedulePeriod.end_date).subtract(1, 'day').format("D MMM YYYY") + ")";

		this.http.get("periodic_candidates/index_alphabetically", 
			{ params: { schedule_period_id: this.schedulePeriod.id } }
		).subscribe(periodicCandidates => {
			this.periodicCandidates = periodicCandidates;
			
			this.periodicCandidates.forEach(function(periodicCandidate) {
				periodicCandidate.lastnameWithInfix = (new Candidate(periodicCandidate.candidate)).lastnameWithInfix();
			});
		});

		this.http.get("tasks",
			{ 
				params: {
					schedule_period_id: this.schedulePeriod.id, 
					is_included_in_template_schedule: 'true'
				}
			}
		).subscribe(tasks => {
			this.tasks = tasks;
		});

		if (this.templatePeriod === null) {
			this.isNew = true;
			this.actionTitle = "toevoegen";
			this.templatePeriod = new TemplatePeriod();
			this.templatePeriod.periodic_candidate_id = this.templatePeriodTask == null ? null : parseInt(this.templatePeriodTask.row.id.substring("template_".length));
		
			this.initializePeriod(
				this.templatePeriodTask == null ? moment(this.schedulePeriod.start_date) : moment(new Date(this.templatePeriodTask.from)),
				this.templatePeriodTask == null ? moment(this.schedulePeriod.start_date).add(7, 'days') : moment(this.templatePeriodTask.to).subtract(1, 'day')
			);
		} else {
			this.isNew = false;
			this.actionTitle = "wijzigen";
			this.showDeleteButton = true;
			this.templatePeriod = this.templatePeriod;
			
			this.initializePeriod(moment(new Date(this.templatePeriod.start_date)), moment(this.templatePeriod.end_date).subtract(1, 'day'));
		}
		
		this.templatePeriod.ganttTaskId = this.templatePeriodTask == null ? null : this.templatePeriodTask.id;
	}
}
