import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './shiftAccumulationTypeForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ShiftAccumulationType } from 'site/app/models/shiftAccumulationType.model';
import { ShiftAccumulationTypeMembership } from 'site/app/models/shiftAccumulationTypeMembership.model';
import { forkJoin } from 'rxjs';
import { ShiftAccTypeDayScheduleTabMembership } from 'site/app/models/shiftAccTypeDayScheduleTabMembership.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class ShiftAccumulationTypeFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public shiftAccumulationTypeId: any;
	public schedulePeriodId: any;

	public shiftAccumulationType: any;
	public submitted: boolean;	
	public actionTitle: string;
	public tasks: any;
	public isLoading: boolean;
	public taskVariantGroups: any;
	public taskVariantGroupsForTaskVariants: any;
	public dayScheduleTabs: any;
	public allocationRequestTypes: any;
	public taskTypes: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
        private toastr: ToastrService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	deleteShiftAccumulationTypeMembership(index) {
		this.shiftAccumulationType.shift_accumulation_type_memberships.splice(index, 1);
		this.taskVariantGroupsForTaskVariants.splice(index, 1);
	}
	
	addShiftAccumulationTypeMembership(shiftAccumulationTypeMembershipTypeId) {
		this.shiftAccumulationType.shift_accumulation_type_memberships.push(new ShiftAccumulationTypeMembership({ shiftAccumulationTypeMembershipTypeId: shiftAccumulationTypeMembershipTypeId, factor: 1 }));
		this.taskVariantGroupsForTaskVariants.push(null);
	}

	deleteShiftAccTypeDayScheduleTabMembership(index) {
		this.shiftAccumulationType.shift_acc_type_day_schedule_tab_memberships.splice(index, 1);
	}
	
	addShiftAccTypeDayScheduleTabMembership() {
		this.shiftAccumulationType.shift_acc_type_day_schedule_tab_memberships.push(new ShiftAccTypeDayScheduleTabMembership());
	}

  	ok(isValid) {
		this.submitted = true;
		
		if (isValid) {
			if (this.shiftAccumulationTypeId === null) {
				this.shiftAccumulationType.schedule_period_id = this.schedulePeriodId;

				this.http.post("shift_accumulation_types", this.shiftAccumulationType).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.error.error);
				});
			} else {
				this.http.put("shift_accumulation_types/" + this.shiftAccumulationType.id, this.shiftAccumulationType).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.error.error);
				});
			}
		}
  	}

  	cancel() {
		this.bsModalRef.hide();
  	}

	trackByFunction(index) {
		return index;
	}

    ngOnInit() {
		var self = this;

		this.http.get("task_variant_groups").subscribe(x => {
			this.taskVariantGroups = x;

			if (this.shiftAccumulationTypeId === null) {
				this.actionTitle = "toevoegen";
				this.shiftAccumulationType = new ShiftAccumulationType();
				this.shiftAccumulationType.shift_accumulation_type_memberships = [];
				this.taskVariantGroupsForTaskVariants = [];
				this.shiftAccumulationType.shift_acc_type_day_schedule_tab_memberships = []
			} else {
				this.actionTitle = "wijzigen";
				this.http.get("shift_accumulation_types/" + this.shiftAccumulationTypeId).subscribe(x => {
					this.shiftAccumulationType = x;
					
					this.shiftAccumulationType.shift_accumulation_type_memberships.forEach(function(y, index) {
						y.index = index;
						if (y.task_id != null) {
							y.shiftAccumulationTypeMembershipTypeId = 1;
						} else if (y.task_variant_id != null) {
							y.shiftAccumulationTypeMembershipTypeId = 2;
						} else if (y.allocation_request_type_id != null) {
							y.shiftAccumulationTypeMembershipTypeId = 3;
						} else if (y.task_type_id != null) {
							y.shiftAccumulationTypeMembershipTypeId = 4;
						}

						if (!y.factor) {
							y.factor = 1;
						}
					});
						
					this.taskVariantGroupsForTaskVariants = this.shiftAccumulationType.shift_accumulation_type_memberships.map(function(y) {
						return y.shiftAccumulationTypeMembershipTypeId == 2 ? self.taskVariantGroups.filter(function(z) { return z.id == y.task_variant.task_variant_group_id })[0] : null;
					});
				});
			}
		});
		
		forkJoin([
			this.http.get("tasks", { params: { schedule_period_id: this.schedulePeriodId } }),
			this.http.get("day_schedule_tabs", { params: { schedule_period_id: this.schedulePeriodId } }),
			this.http.get("allocation_request_types", { params: { schedule_period_id: this.schedulePeriodId } }),
			this.http.get("task_types")
		]).subscribe(data => {
			this.tasks = data[0];
			this.dayScheduleTabs = data[1];
			this.allocationRequestTypes = data[2];
			this.taskTypes = data[3];
	
			this.tasks.forEach(function(task) {
				task.nameWithEquivalencePosition = task.name + (task.equivalence_position == null ? "" : " (" + task.equivalence_position + ")");
			});
	
			this.isLoading = false;
		});
    }
}
