import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import templateString from './agendaSyncLink.component.html';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EnvironmentService } from 'site/app/environment.service';

@Component({ template: templateString })
export class AgendaSyncLinkComponent implements OnInit {
	@Output() action = new EventEmitter();

	public agendaIdentificationHash;
	public isCustomAgenda;
	public customAgenda;
	public candidate;
	
	public agendaSyncLink: string;
	public agendaName: string;
	public selectedTab: any;
	
	constructor(
		private renderer: Renderer2,
		private bsModalRef: BsModalRef,
		private environmentService: EnvironmentService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	changeTab(tabNumber) {
		this.selectedTab = tabNumber;
	}

	openAgendaSyncLink() {
		window.open(this.agendaSyncLink);
	}

	ok() {
		this.bsModalRef.hide();
	};

	cancel() {
		this.bsModalRef.hide();
	};

	ngOnInit(): void {
		this.agendaSyncLink = "webcal://easyplanner.nl/agenda/" + this.agendaIdentificationHash + "/show.ics";
		
		var customer = this.environmentService.getUser().customer;
		this.agendaName = this.isCustomAgenda ? 'easyplanner ' + this.customAgenda.name + " - " + customer.name : 'easyplanner ' + customer.name;

		this.changeTab(1);
	}
}
