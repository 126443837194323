import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './optimizationGroupRunForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { Candidate } from 'site/app/models/candidate.model';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class OptimizationGroupRunFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public optimizationGroupRunId: any;
	
	public isLoading: any;
	public optimizationGroupRun: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
        private toastr: ToastrService,
		private environmentService: EnvironmentService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	cancel() {
		this.bsModalRef.hide();
  	}

    ngOnInit() {
		this.http.get("optimization_group_runs/" + this.optimizationGroupRunId).subscribe(x => {
			this.optimizationGroupRun = x;

			this.optimizationGroupRun.optimization_group_run_shift_assignments.forEach(function(y) {
				y.formattedPeriod = moment(y.shift.start_date).format('D MMM YYYY') + 
					(moment(y.shift.end_date).subtract(1, 'day').isSame(y.shift.start_date) ? "" : 
					" t/m " + moment(y.shift.end_date).add(-1, 'days').format('D MMM YYYY'));
				y.formattedPeriodicCandidate = (new Candidate(y.periodic_candidate.candidate)).infixWithLastname();
			});
		});
	}
}
