import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import templateString from './trainingSchedule.component.html'
import * as moment from 'moment';
import { BsModalService } from "ngx-bootstrap/modal";
import { TrainingPeriodFormComponent } from "../trainingPeriodForm";
import { Candidate } from "site/app/models/candidate.model";
import { DataService } from "site/app/data.service";
import { TrainingPeriod } from "site/app/models/trainingPeriod.model";
import { ToastrService } from "ngx-toastr";
import { EnvironmentService } from "site/app/environment.service";
import { Subject, Observable, forkJoin } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { ResizeEvent } from 'angular-resizable-element';
import { TrainingScheduleVersionFormComponent } from "../trainingScheduleVersionForm";
import { TrainingScheduleVersion } from "site/app/models/trainingScheduleVersion.model";
import { CandidateViolationsComponent } from "../candidateViolations";
import { CandidateType } from "site/app/models/candidateType.model";
import { TrainingScheduleVersionEvaluationFormComponent } from "../trainingScheduleVersionEvaluationForm";
import { HttpClient } from "@angular/common/http";

@Component({ 
	template: templateString,
	styles: [
		`
		  mwlResizable {
			box-sizing: border-box; // required for the enableGhostResize option to work
		  }		  
		`
	  ],
})
export class TrainingScheduleComponent implements OnInit, OnDestroy {
	public destroyed$;
	public isLoading: boolean;
	public optimizationProgress: number;
	public filterOptions: { id: number; title: string; }[];
	public filterOptionId: number;
	public filterOptionsOwnLocation: { id: number; title: string; }[];
	public filterOptionOwnLocationId: number;
	public hasBeenOptimized: boolean;
	public kpis: { name: string; title: string; iconName: string; }[];
	public isEdit: any;
	public deletedTrainingPeriodIds: any;
	public selectedTrainingScheduleVersionId: any;
	public fromDate;
	public toDate;
	public user: any;
	public timespans: any[];
	public trainingPeriodAccumulationTypes: any;
	public data: any;
	public kpiValuesForChart: any;
	public candidates: any;
	public changedTrainingPeriods: any[];
	public tabs: any;
	public horizontalScrollReceivers1 = {};
	public verticalScrollReceivers1 = {};
	public verticalScrollReceivers2 = {};
	public mouseover;
	public mouseout;
	public currentHovered = null;
	public tempIndex = 0;
	public columnWidth = 3;
	public isVisible: boolean;
	public Arr = Array;
	public numberOfDays;
	public monthColumns;
	public weekColumns;
	public dateHeaderRows;
	public isDragging: boolean = false;
	public isRecalculatingKpis: any;
	public trainingScheduleVersions: any;
	public versionsVisible: boolean = false;
	public optimizationStrategies: any;
	public trainingScheduleVersionsOpened;
	public kpiNames = ["", "Number of changes", "Location capacity", "Preference", "Number of splits"];
	public archivedTrainingScheduleVersions: any;
	public filterOptionTrainingPeriodAccumulationTypeId;
	public filterOptionLocationId;
	public filterOptionTrainingLocationId;
	public locations;
	public trainingLocations;
	public years;
	public filterOptionYear: any;
	public showOccupationTable: any;
	public allTrainingScheduleVersions: any;
	public compareWithOther;

	view: any[] = [];

	// options
	legend: boolean = false;
	showLabels: boolean = false;
	animations: boolean = true;
	xAxis: boolean = false;
	yAxis: boolean = false;
	showYAxisLabel: boolean = false;
	showXAxisLabel: boolean = false;
	timeline: boolean = true;

	colorScheme = {
		domain: ['rgba(255,0,0,0.6)', 'rgba(255,165,0,0.8)', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
	};
	
	onSelect(data): void {
		console.log('Item clicked', JSON.parse(JSON.stringify(data)));
	}

	constructor(
		private bsModalService: BsModalService,
		private http: HttpClient,
		private dataService: DataService,
		private toastr: ToastrService,
		private environmentService: EnvironmentService,
		private domSanitizer: DomSanitizer,
		private router: Router,
		private changeDetectorRef: ChangeDetectorRef
	) { 
		var self = this;
		this.destroyed$ = new Subject<boolean>();
		
		// use 1 event listener for all tooltips and show tooltip based on event target, for better performance
		// see also https://github.com/valor-software/ngx-bootstrap/issues/1536#issuecomment-539576500
		// create event listener according to https://javascript.info/mousemove-mouseover-mouseout-mouseenter-mouseleave
        this.mouseover = function(event: Event){
            // before entering a new element, the mouse always leaves the previous one
			// if currentElem is set, we didn't leave the previous <td>,
			// that's a mouseover inside it, ignore the event
			if (self.currentHovered != null) return;

			let target = self.closestByClass(event.target, 'for-tooltip');

			// we moved not into a <td> - ignore
		  	if (!target) return;

		  	self.currentHovered = target;
			self.tempIndex += 1;

			let boundingRectangle = target.getBoundingClientRect();

			self.environmentService.setHoveredElement({
				text: self.getTooltipText(self.currentHovered),
				bottom: boundingRectangle.bottom,
				left: boundingRectangle.left,
				right: boundingRectangle.right
			});
        }

		document.addEventListener('mouseover', this.mouseover);
		
		this.mouseout = function(event: Event) {
			// if we're outside of any <td> now, then ignore the event
			// that's probably a move inside the table, but out of <td>,
			// e.g. from <tr> to another <tr>
			if (!self.currentHovered) return;

			// we're leaving the element – where to? Maybe to a descendant?
		  	let relatedTarget = (<any> event).relatedTarget;

		  	while (relatedTarget) {
				// go up the parent chain and check – if we're still inside currentElem
				// then that's an internal transition – ignore it
			    if (relatedTarget == self.currentHovered) return;
				
				relatedTarget = relatedTarget.parentNode;
			}

			// we left the <td>. really.
			self.currentHovered = null;
			self.environmentService.setHoveredElement(null);
		}

		document.addEventListener('mouseout', this.mouseout);
	}

	closestByClass(el, tooltipClassName) {
	    // Traverse the DOM up with a while loop
	    while (!el.classList || !el.classList.contains(tooltipClassName)) {
	        // Increment the loop to the parent node
	        el = el.parentNode;
	        if (!el) {
	            return null;
	        }
	    }
	    // At this point, the while loop has stopped and `el` represents the element that has
	    // the class you specified in the second parameter of the function `clazz`

	    // Then return the matched element
	    return el;
	}

	clickGanttTask(trainingPeriodTask, row) {
		if (this.user.customer.regional_customer_id == null) {
			if (this.isDragging) {
				this.isDragging = false;
			} else {
				if (this.isEdit) {
					var bsModalRef = this.bsModalService.show(TrainingPeriodFormComponent, {
						initialState: {
							trainingPeriodTask: trainingPeriodTask,
							trainingPeriod: trainingPeriodTask.trainingPeriod,
							filterOptionId: this.filterOptionId
						},
						class: 'modal-lg'
					});
			
					bsModalRef.content.action.subscribe(isDeleteAndTrainingPeriod => {	
						var isDelete = isDeleteAndTrainingPeriod[0];
						var trainingPeriod = isDeleteAndTrainingPeriod[1];
			
						trainingPeriod.ganttTaskId = trainingPeriodTask.id;
						this.deleteFromGanttChart(row.id, trainingPeriod);
			
						if (isDelete) {
							if (trainingPeriod.id != null) {
								this.deletedTrainingPeriodIds.push(trainingPeriod.id);
							}
	
							this.refreshTrainingPeriodAccumulationCounts();
							this.recalculateKpis([this.selectedTrainingScheduleVersionId]);
						} else {
							this.addToGanttChart(trainingPeriod.candidate_id, trainingPeriod, false).subscribe(ganttTaskId => {
			
								trainingPeriodTask.trainingPeriod.ganttTaskId = ganttTaskId;
				
								this.addChangedTrainingPeriod(trainingPeriodTask.trainingPeriod);
								
								this.refreshTrainingPeriodAccumulationCounts();
								this.recalculateKpis([this.selectedTrainingScheduleVersionId]);
							});
						}
					});
				}
			}
		}
	}

	createTrainingPeriod(trainingPeriodTask) {
		var bsModalRef = this.bsModalService.show(TrainingPeriodFormComponent, {
            initialState: {
				trainingPeriodTask: trainingPeriodTask,
                trainingPeriod: null,
                filterOptionId: this.filterOptionId
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(isDeleteAndTrainingPeriod => {
			var trainingPeriod = isDeleteAndTrainingPeriod[1];
	
			this.addToGanttChart(trainingPeriod.candidate_id, trainingPeriod, false).subscribe(ganttTaskId => {
				trainingPeriod.ganttTaskId = ganttTaskId;
				
				this.addChangedTrainingPeriod(trainingPeriod);
					
				this.refreshTrainingPeriodAccumulationCounts();
				this.recalculateKpis([this.selectedTrainingScheduleVersionId]);
			});
		});
	}

	changeFilterOption(filterOptionId) {
		this.filterOptionId = filterOptionId;
		this.filterChanged(0);
	}

	changeFilterOptionOwnLocation(filterOptionId) {
		this.filterOptionOwnLocationId = filterOptionId;
		this.filterChanged(this.selectedTrainingScheduleVersionId);
	}

	getLength(from, to) {
		return moment(to).diff(moment(from), 'days');
	}

	getOffset(from) {
		return moment(from).diff(this.fromDate, 'days');
	}

	refreshTrainingPeriods(trainingScheduleVersionId) {
		this.isLoading = true;

		var self = this;

		this.data[trainingScheduleVersionId] = [];
		this.kpiValuesForChart[trainingScheduleVersionId] = [];
		
		this.fromDate = moment(this.user.customer.start_date);
		this.toDate = moment(this.fromDate).add(16, 'years').subtract(1, 'day');

		this.numberOfDays = this.toDate.diff(this.fromDate, 'days') + 1;
		
		var yearColumns = [];
		this.monthColumns = [];
		this.weekColumns = [];

		var currentDate = moment(this.fromDate);
		var index = 0;

		var tempYear = currentDate.format("YYYY");
		var tempYearIndex = 0;

		var tempMonth = currentDate.format("MMM");
		var tempMonthIndex = 0;

		var tempWeek = currentDate.format("W");
		var tempWeekIndex = 0;

		while (currentDate <= this.toDate) {
			var currentYear = currentDate.format("YYYY");
			var currentMonth = currentDate.format("MMM");
			var currentWeek = currentDate.format("W");

			if (currentYear != tempYear) {
				yearColumns.push({ title: tempYear, offsetInNumberOfCells: tempYearIndex, widthInNumberOfCells: index - tempYearIndex });

				tempYear = currentYear;
				tempYearIndex = index;
			}

			if (currentMonth != tempMonth) {
				this.monthColumns.push({ title: tempMonth, offsetInNumberOfCells: tempMonthIndex, widthInNumberOfCells: index - tempMonthIndex });

				tempMonth = currentMonth;
				tempMonthIndex = index;
			}

			if (currentWeek != tempWeek) {
				this.weekColumns.push({ title: tempWeek, offsetInNumberOfCells: tempWeekIndex, widthInNumberOfCells: index - tempWeekIndex });

				tempWeek = currentWeek;
				tempWeekIndex = index;
			}

			if (currentDate.isSame(this.toDate)) {
				yearColumns.push({ title: currentYear, offsetInNumberOfCells: tempYearIndex, widthInNumberOfCells: index + 1 - tempYearIndex });
				this.monthColumns.push({ title: currentMonth, offsetInNumberOfCells: tempMonthIndex, widthInNumberOfCells: index + 1 - tempMonthIndex });
				this.weekColumns.push({ title: currentWeek, offsetInNumberOfCells: tempWeekIndex, widthInNumberOfCells: index + 1 - tempWeekIndex });
			}

			currentDate = moment(currentDate).add(1, 'day');
			index++;
		}

		this.dateHeaderRows = [yearColumns, this.monthColumns, this.weekColumns];

		this.timespans = [];

		this.http.get("training_period_accumulation_types").subscribe(trainingPeriodAccumulationTypes => {
			this.trainingPeriodAccumulationTypes = trainingPeriodAccumulationTypes;
		

		// if (this.user.customer.customer_type_id == 2) {
		// 	self.data[trainingScheduleVersionId].push({
		// 		id: "numberOfChanges",
		// 		name: "Aantal wijzigingen",
		// 		classes: ""
		// 	});
		
		// 	self.data[trainingScheduleVersionId].push({
		// 		id: "numberOfSplits",
		// 		name: "Aantal opdelingen",
		// 		classes: "separatingTask"
		// 	});
		// }

		// this.http.get("locations").subscribe(locations => {
		// 	if (this.user.customer.customer_type_id == 2) {
		// 		locations.forEach(function(location, i) {
		// 			self.data[trainingScheduleVersionId].push({
		// 				id: "location_" + location.id,
		// 				name: location.alias + " | capaciteit",
		// 				classes: i == locations.length-1 ? "separatingTask" : ""
		// 			});
		// 		});
		// 	}

			this.http.get("candidates/index_with_calculated_training_period_accumulation_type_balances", 
				{ 
					params: {
						training_schedule_version_id: trainingScheduleVersionId,
						show_current_and_future_candidates: (this.filterOptionId == 1).toString(), 
						with_corrections: 'false', //tabIndices[0] > 0, 
						with_targets_and_balances: (this.user.customer.regional_customer_id == null).toString(),
						location_id: this.user.customer.has_only_access_to_own_location ? this.user.customer.regional_location_id : 0,
						training_period_accumulation_type_id: this.user.has_only_access_to_training_period_accumulation_type_id ? this.user.has_only_access_to_training_period_accumulation_type_id : 0	
					}
				}
			).subscribe(candidates => {
				this.candidates = candidates;

				this.candidates.forEach(function(candidate) {
					candidate.nameField = (new Candidate(candidate)).infixWithLastname();
					if (self.user.customer.regional_customer_id != null) {
						candidate.nameField = candidate.nameField + " (" + (candidate.location ? candidate.location.training_origin_name + ", " : "") + 
							moment(candidate.training_start_date).format('MM-YY') + ")"
					}
					
					self.data[trainingScheduleVersionId].push({
						id: candidate.id,
						name: candidate.nameField,
						locationId: candidate.location_id
					});
				});
				
				this.candidates.forEach(function(candidate) {
					var ganttTasks = [];

					candidate.training_periods.forEach(function(x) { 
						if (x.unavailability_periods != undefined && x.unavailability_periods.length > 0) {
							ganttTasks.push(self.dataService.createTrainingPeriodTaskForCandidate(
								new TrainingPeriod({
									candidate_id: x.candidate_id,
									start_date: x.start_date,
									end_date: x.unavailability_periods[0].start_date,
									id: x.id + "-1",
									is_locked: x.locked,
									location: x.location,
									location_id: x.location_id,
									ratio: x.ratio,
									training_period_accumulation_type: x.training_period_accumulation_type,
									training_period_accumulation_type_id: x.training_period_accumulation_type_id,
									is_changed: x.is_changed,
									unavailability_periods: x.unavailability_periods // only for the first trainingPeriod, needed for recalculate_kpis
								}), 
								candidate, self.user.customer.regional_customer_id != null));
							ganttTasks.push(this.dataService.createTrainingPeriodTaskForCandidate(
								new TrainingPeriod({
									candidate_id: x.candidate_id,
									start_date: x.unavailability_periods[0].end_date,
									end_date: x.end_date,
									id: x.id + "-2",
									is_locked: x.locked,
									location: x.location,
									location_id: x.location_id,
									ratio: x.ratio,
									training_period_accumulation_type: x.training_period_accumulation_type,
									training_period_accumulation_type_id: x.training_period_accumulation_type_id,
									is_changed: x.is_changed
								}), 
								candidate, self.user.customer.regional_customer_id != null));
						} else {
							ganttTasks.push(self.dataService.createTrainingPeriodTaskForCandidate(x, candidate, self.user.customer.regional_customer_id != null));
						}
					});
					ganttTasks = ganttTasks.concat(
						candidate.candidate_unavailability_periods_for_training_schedule_version.map(function(x) { 
							return self.dataService.createCandidateUnavailabilityPeriod(x) })
					);
					
					self.data[trainingScheduleVersionId].filter(function(x) { return x.id == candidate.id })[0].tasks = ganttTasks;
				});

				if (this.user.customer.regional_customer_id != null) {
					this.refreshKpis(trainingScheduleVersionId);

					if (this.user.customer.has_only_access_to_own_location) {
						this.filterChanged(0);
					}
				} else {
					this.filterChanged(0);
					this.isLoading = false;
				}

				this.scrollToStartHorizon();

				// TODO necessary?
				this.refreshTrainingPeriodAccumulationCounts();



				// if (this.user.customer.customer_type_id == 2) {
				// 	this.http.get("optimization/get_kpis").subscribe(response => {
				// 		var kpis = response.kpis;
				// 		var kpisPerMonth = response.kpis_per_month;

				// 		if (tabIndices[0] > 0) {
				// 			this.recalculateKpis(tabIndices);
				// 		} else {
				// 			this.http.get("training_periods/get_training_period_differences").subscribe(data => {
				// 				this.updateKpis(tabIndices, false, data["to_delete"].length + data["to_add"].length + data["to_change"].length + data["candidate_unavailability_periods"].length, kpis["Location capacity"], "-", "-");
				// 			});

				// 			this.updateKpisPerMonth(tabIndices, kpisPerMonth["Location capacity"], [], []);
				// 		}
				// 	});
				// }
			});
		});
	}

	scrollToStartHorizon() {
		this.scrollTo(moment(this.user.customer.training_schedule_start_date));
	}

	scrollTo(scrollToDate) {
		var self = this;

		setTimeout(function() {
			var scrollableDiv = document.querySelector('#gantt-scrollable-div');
			var offset = scrollToDate.diff(moment(self.user.customer.start_date), 'years') *12*91.5 -10;
			scrollableDiv.scrollLeft = offset;
		}, 10);
	}

	filterChanged(trainingScheduleVersionId) {
		if (this.user.customer.regional_customer_id != null) {
			this.refreshKpis(trainingScheduleVersionId);
		}
		this.refreshTrainingPeriodSelection(trainingScheduleVersionId);
	}

	refreshTrainingPeriodSelection(trainingScheduleVersionId) {
		var self = this;

		self.data[trainingScheduleVersionId].forEach(function(candidate) {
			if (self.filterOptionId == 1) {
				// var lastGanttTask = candidate.tasks && candidate.tasks.length > 0 ? candidate.tasks[candidate.tasks.length-1] : null;

				// if (lastGanttTask && moment(lastGanttTask.to) >= moment()) {
				// 	candidate.isHidden = false;
				// } else {
				// 	candidate.isHidden = true;
				// }
			} else if (self.filterOptionTrainingLocationId[trainingScheduleVersionId] != 0 &&
					candidate.locationId != self.filterOptionTrainingLocationId[trainingScheduleVersionId]) {
				candidate.isHidden = true;
			} else {
				var showCandidate = false;
				candidate.tasks.forEach(function(ganttTask) {
					var showTransparent = false;
	
					if (ganttTask.trainingPeriod) {
						if (self.filterOptionTrainingPeriodAccumulationTypeId[trainingScheduleVersionId] != 0 &&
								ganttTask.trainingPeriod.training_period_accumulation_type_id != self.filterOptionTrainingPeriodAccumulationTypeId[trainingScheduleVersionId]) {
							showTransparent = true;
						}
	
						if (self.filterOptionLocationId[trainingScheduleVersionId] != 0 &&
								ganttTask.trainingPeriod.location_id != self.filterOptionLocationId[trainingScheduleVersionId]) {
							showTransparent = true;
						}

						if (self.filterOptionYear[trainingScheduleVersionId] != 0 &&
							(
								moment(ganttTask.trainingPeriod.start_date) > moment([self.filterOptionYear[trainingScheduleVersionId] + 1, 1, 1]) ||
								moment(ganttTask.trainingPeriod.end_date) < moment([self.filterOptionYear[trainingScheduleVersionId], 1, 1])
							)
						) {
							showTransparent = true;
						}
	
						ganttTask.showTransparent = showTransparent;
	
						if (!showTransparent) {
							showCandidate = true;
						}
					}	
				});	
				
				candidate.isHidden = !showCandidate;
			}
		});
	}

	refreshKpis(trainingScheduleVersionId) {
		var self = this;
		var selectedTab = self.tabs.filter(function(x) { return x.trainingScheduleVersion.id == self.selectedTrainingScheduleVersionId })[0];

		var recalculateKpiCalls = [
			this.http.post("optimization/recalculate_kpis",
				{ 
					training_schedule_version_id: trainingScheduleVersionId,
					training_period_accumulation_type_id: this.filterOptionTrainingPeriodAccumulationTypeId[trainingScheduleVersionId],
					location_id: this.filterOptionLocationId[trainingScheduleVersionId],
					year: this.filterOptionYear[trainingScheduleVersionId],
					training_location_id: this.filterOptionTrainingLocationId[trainingScheduleVersionId],
				}
			)
		];

		if (this.compareWithOther[trainingScheduleVersionId] && this.compareWithOther[trainingScheduleVersionId] != -1) {
			recalculateKpiCalls.push(
				this.http.post("optimization/recalculate_kpis",
					{ 
						training_schedule_version_id: this.compareWithOther[trainingScheduleVersionId],
						training_period_accumulation_type_id: this.filterOptionTrainingPeriodAccumulationTypeId[trainingScheduleVersionId],
						location_id: this.filterOptionLocationId[trainingScheduleVersionId],
						year: this.filterOptionYear[trainingScheduleVersionId],
						training_location_id: this.filterOptionTrainingLocationId[trainingScheduleVersionId],
					}
				)
			)
		}

		forkJoin(recalculateKpiCalls).subscribe(data => {
			
			var locationCapacityMinMaxViolations = data[0]["location_capacity_min_max_violations"];
			var locationCapacityTargetViolations = data[0]["location_capacity_target_violations"];
			selectedTab.preferredOrderViolationsPerCandidate = data[0]["preferred_order_violations_per_candidate"];
			selectedTab.preferredOrderViolationsCountPerCandidate = data[0]["preferred_order_violations_count_per_candidate"];
			selectedTab.preferredLocationViolationsPerCandidate = data[0]["preferred_location_violations_per_candidate"];
			selectedTab.preferredLocationViolationsCountPerCandidate = data[0]["preferred_location_violations_count_per_candidate"];
			selectedTab.changesPerCandidate = data[0]["changes_per_candidate"];
			selectedTab.changesCountPerCandidate = data[0]["changes_count_per_candidate"];
			selectedTab.formerTrainingPeriodsPerCandidate = data[0]["former_training_periods_per_candidate"];
			selectedTab.newTrainingPeriodsPerCandidate = data[0]["new_training_periods_per_candidate"];
			var totalLocationCapacityMinMaxViolationCount = data[0]["total_location_capacity_min_max_violation_count"];
			var totalLocationCapacityTargetViolationCount = data[0]["total_location_capacity_target_violation_count"];
			var totalPreferredOrderViolationCount = data[0]["total_preferred_order_violation_count"];
			var totalPreferredLocationViolationCount = data[0]["total_preferred_location_violation_count"];
			var totalChangesCount = data[0]["total_changes_count"];
			var occupation = data[0]["occupation"];

			var occupationOther;
			var totalLocationCapacityMinMaxViolationCountOther;
			var totalLocationCapacityTargetViolationCountOther;
			var totalPreferredOrderViolationCountOther;
			var totalPreferredLocationViolationCountOther;
			var totalChangesCountOther;

			if (this.compareWithOther[trainingScheduleVersionId] && this.compareWithOther[trainingScheduleVersionId] != -1) {
				occupationOther = data[1]["occupation"];
				totalLocationCapacityMinMaxViolationCountOther = data[1]["total_location_capacity_min_max_violation_count"];
				totalLocationCapacityTargetViolationCountOther = data[1]["total_location_capacity_target_violation_count"];
				totalPreferredOrderViolationCountOther = data[1]["total_preferred_order_violation_count"];
				totalPreferredLocationViolationCountOther = data[1]["total_preferred_location_violation_count"];
				totalChangesCountOther = data[1]["total_changes_count"];
			}

			selectedTab.kpis["Location capacity"] = totalLocationCapacityMinMaxViolationCount + (this.compareWithOther[trainingScheduleVersionId] && this.compareWithOther[trainingScheduleVersionId] != -1 ? " (" + totalLocationCapacityMinMaxViolationCountOther + ")" : "") + " : " + 
				totalLocationCapacityTargetViolationCount + (this.compareWithOther[trainingScheduleVersionId] && this.compareWithOther[trainingScheduleVersionId] != -1 ? " (" + totalLocationCapacityTargetViolationCountOther + ")" : "");
			selectedTab.kpis["Preference"] = totalPreferredOrderViolationCount + (this.compareWithOther[trainingScheduleVersionId] && this.compareWithOther[trainingScheduleVersionId] != -1 ? " (" + totalPreferredOrderViolationCountOther + ")" : "") + " : " + 
				totalPreferredLocationViolationCount + (this.compareWithOther[trainingScheduleVersionId] && this.compareWithOther[trainingScheduleVersionId] != -1 ? " (" + totalPreferredLocationViolationCountOther + ")" : "");
			selectedTab.kpis["Number of changes"] = totalChangesCount + (this.compareWithOther[trainingScheduleVersionId] && this.compareWithOther[trainingScheduleVersionId] != -1 ? " (" + totalChangesCountOther + ")" : "");

			this.kpiValuesForChart[self.selectedTrainingScheduleVersionId] = [
				{
					"name": "Capaciteit min/max",
					"series": locationCapacityMinMaxViolations
				},
				{
					"name": "Capaciteit richtwaarde",
					"series": locationCapacityTargetViolations
				}
			];

			if (self.showOccupationTable[self.selectedTrainingScheduleVersionId]) {
				if (self.filterOptionTrainingPeriodAccumulationTypeId[self.selectedTrainingScheduleVersionId] == 0) {
					var filteredTrainingPeriodAccumulationTypes = JSON.parse(JSON.stringify(this.trainingPeriodAccumulationTypes));

					var locationCapacitiesPerLocation = {};

					self.locations.forEach(function(z) {
						locationCapacitiesPerLocation[z.id] = {};
						
						var locationCapacities = self.locations.filter(function(l) { return l.id == z.id; })[0].location_capacities;
						filteredTrainingPeriodAccumulationTypes.forEach(function(x) {
							locationCapacitiesPerLocation[z.id][x.id] = locationCapacities.filter(function(lc) { return lc.training_period_accumulation_type_id == x.id; })[0];
						});
					});

					filteredTrainingPeriodAccumulationTypes.forEach(function(x) {
						var tasks = [];

						Object.keys(occupation).forEach(function(y) {
							var occupationAmount = 0;
							var occupationAmountOther = 0;

							self.locations.forEach(function(z) {
								if (!self.filterOptionLocationId[self.selectedTrainingScheduleVersionId] || self.filterOptionLocationId[self.selectedTrainingScheduleVersionId] == z.id) {
									var amount = parseInt(occupation[y][z.id][x.id])
									if (!isNaN(amount)) {
										occupationAmount += amount;
									}

									if (self.compareWithOther[trainingScheduleVersionId] && self.compareWithOther[trainingScheduleVersionId] != -1) {
										var amount = parseInt(occupationOther[y][z.id][x.id])
										if (!isNaN(amount)) {
											occupationAmountOther += amount;
										}
									}
								}
							});

							var locationCapacity = null;

							if (self.filterOptionLocationId[self.selectedTrainingScheduleVersionId]) {
								var locationCapacities = locationCapacitiesPerLocation[self.filterOptionLocationId[self.selectedTrainingScheduleVersionId]];
								if (locationCapacities) {
									locationCapacity = locationCapacities[x.id];
								}
							}

							tasks.push(self.dataService.createTrainingPeriodAccumulationTypeCount({ 
								start_date: y, 
								occupation_amount: occupationAmount,
								occupation_amount_other: occupationAmountOther,
								location_capacity: locationCapacity
							}));
						});
						x.tasks = tasks;
					});

					selectedTab.filteredTrainingPeriodAccumulationTypes = filteredTrainingPeriodAccumulationTypes;
				} else {
					var filteredLocations = JSON.parse(JSON.stringify(this.locations));

					if (self.filterOptionLocationId[self.selectedTrainingScheduleVersionId] != 0) {
						filteredLocations = filteredLocations.filter(function(x) { return x.id == self.filterOptionLocationId[self.selectedTrainingScheduleVersionId]; });
					}

					var locationCapacitiesPerTrainingPeriodAccumulationType = {};

					self.trainingPeriodAccumulationTypes.forEach(function(z) {
						locationCapacitiesPerTrainingPeriodAccumulationType[z.id] = {};
						
						filteredLocations.forEach(function(x) {
							locationCapacitiesPerTrainingPeriodAccumulationType[z.id][x.id] = self.locations.filter(function(l) { return l.id == x.id; })[0].location_capacities.filter(function(lc) { return lc.training_period_accumulation_type_id == z.id; })[0];
						});
					});

					filteredLocations.forEach(function(x) {
						var tasks = [];

						Object.keys(occupation).forEach(function(y) {
							var occupationAmount = 0;
							var occupationAmountOther = 0;

							self.trainingPeriodAccumulationTypes.forEach(function(z) {
								if (self.filterOptionTrainingPeriodAccumulationTypeId[self.selectedTrainingScheduleVersionId] == 0 || self.filterOptionTrainingPeriodAccumulationTypeId[self.selectedTrainingScheduleVersionId] == z.id) {
									var amount = parseInt(occupation[y][x.id][z.id])
									if (!isNaN(amount)) {
										occupationAmount += amount;
									}

									if (self.compareWithOther[trainingScheduleVersionId] && self.compareWithOther[trainingScheduleVersionId] != -1) {
										var amount = parseInt(occupationOther[y][x.id][z.id])
										if (!isNaN(amount)) {
											occupationAmountOther += amount;
										}
									}
								}
							});

							var locationCapacity = null;

							var locationCapacities = locationCapacitiesPerTrainingPeriodAccumulationType[self.filterOptionTrainingPeriodAccumulationTypeId[self.selectedTrainingScheduleVersionId]];
							if (locationCapacities) {
								locationCapacity = locationCapacities[x.id];
							}

							tasks.push(self.dataService.createTrainingPeriodAccumulationTypeCount({ 
								start_date: y, 
								occupation_amount: occupationAmount,
								occupation_amount_other: occupationAmountOther,
								location_capacity: locationCapacity
							}));
						});
						x.tasks = tasks;
					});

					selectedTab.filteredLocations = filteredLocations;
				}
			}

			selectedTab.isRecalculatingKpis = false;

			self.isLoading = false;
			// this.updateKpis(tabIndices, false, 0, kpis["Location capacity"], "-", kpis["Number of splits"]);
			// this.updateKpisPerMonth(tabIndices, kpisPerMonth["Location capacity"], [], kpisPerMonth["Number of splits"]);
		});
	}

	setOperational(trainingScheduleVersionId) {
		if (window.confirm('Weet je het zeker?')) {
			this.isLoading = true;

			this.http.post("training_schedule_versions/set_operational",
				{ training_schedule_version_id: trainingScheduleVersionId }
			).subscribe(data => {
				this.initialize();
			});
		}
	}

	refreshTrainingPeriodAccumulationCounts() {
		var self = this;
		
		if (this.user.customer.regional_customer_id == null) {
		
			// clear everything first
			this.candidates.forEach(function(candidate) {
				var candidateGanttRow = self.data[self.selectedTrainingScheduleVersionId].filter(function(x) { return x.id == candidate.id })[0];
			
				self.trainingPeriodAccumulationTypes.forEach(function(trainingPeriodAccumulationType) {
					var trainingPeriodAccumulationTypeFieldId = "trainingPeriodAccumulationType" + trainingPeriodAccumulationType.id;

					var startBalance = candidate.training_period_accumulation_type_balances.filter(function(x) { 
						return x.training_period_accumulation_type_id == trainingPeriodAccumulationType.id })[0].value;

					candidateGanttRow[trainingPeriodAccumulationTypeFieldId] = startBalance == 0 ? null : Math.round(startBalance * 10) / 10;
				});
			});

			// recalculate counts
			this.candidates.forEach(function(candidate) {
				var candidateGanttRow = self.data[self.selectedTrainingScheduleVersionId].filter(function(x) { return x.id == candidate.id })[0];
			
				candidateGanttRow.tasks.forEach(function(ganttTask) {

					var lengthOfTrainingPeriod = moment(ganttTask.trainingPeriod.end_date).diff(moment(ganttTask.trainingPeriod.start_date), 'months', true);

					var lengthOfTrainingPeriodAdjustedToRatio = lengthOfTrainingPeriod * ganttTask.trainingPeriod.ratio;

					var trainingPeriodAccumulationTypeFieldId = "trainingPeriodAccumulationType" + ganttTask.trainingPeriod.training_period_accumulation_type_id;

					if (candidateGanttRow[trainingPeriodAccumulationTypeFieldId] == null) {
						candidateGanttRow[trainingPeriodAccumulationTypeFieldId] = 0;
					}

					candidateGanttRow[trainingPeriodAccumulationTypeFieldId] += Math.round(lengthOfTrainingPeriodAdjustedToRatio * 10) / 10;
				});
			
				self.trainingPeriodAccumulationTypes.forEach(function(trainingPeriodAccumulationType) {
					var trainingPeriodAccumulationTypeFieldId = "trainingPeriodAccumulationType" + trainingPeriodAccumulationType.id;

					var trainingPeriodAccumulationTypeTarget = candidate.training_period_accumulation_type_targets.filter(function(x) { 
						return x.training_period_accumulation_type_id == trainingPeriodAccumulationType.id })[0];
				
					if (candidateGanttRow[trainingPeriodAccumulationTypeFieldId] == null && trainingPeriodAccumulationTypeTarget.value == null) {
						// both count and target are 0, so don't display anything
						candidateGanttRow[trainingPeriodAccumulationTypeFieldId] = null;
					} else {
						candidateGanttRow[trainingPeriodAccumulationTypeFieldId] = 
							(candidateGanttRow[trainingPeriodAccumulationTypeFieldId] == null ? 0 : parseFloat(candidateGanttRow[trainingPeriodAccumulationTypeFieldId].toFixed(1))) + 
							" / " + 
							(trainingPeriodAccumulationTypeTarget.value == null ? "?" : trainingPeriodAccumulationTypeTarget.value);
					}
				});
			});
		}
	}

	addChangedTrainingPeriod(trainingPeriod) {
		if (this.changedTrainingPeriods.filter(function(x) { return x.ganttTaskId == trainingPeriod.ganttTaskId }).length == 0) {
			this.changedTrainingPeriods.push(trainingPeriod);
		}
	} 
	
	addChangedTrainingPeriodFromOptimization(trainingPeriod) {
		if (this.changedTrainingPeriods.filter(function(x) { return x.id == trainingPeriod.id }).length == 0) {
			this.changedTrainingPeriods.push(trainingPeriod);
		}
	} 

	addToGanttChart(candidateId, trainingPeriod, isOptimizing) {
		var ganttRow = this.data[this.selectedTrainingScheduleVersionId].filter(function(x) { return x.id == candidateId; })[0];
		var candidate = this.candidates.filter(function(x) { return x.id == candidateId })[0];
		
		ganttRow.tasks.push(this.dataService.createTrainingPeriodTaskForCandidate(trainingPeriod, candidate, this.user.customer.regional_customer_id != null));
		
		return new Observable(observer => {
            setTimeout(() => {
				// if we're optimizing return the gantt task based on the trainingPeriod id (which is always present when optimizing)
				// otherwise, get the gantt task based on the last one added, because id is not available when adding a gantt task manually
				if (isOptimizing) {
					observer.next(ganttRow.tasks.filter(function(x) { return trainingPeriod.id == x.trainingPeriod.id })[0].id);
				} else {
					observer.next(ganttRow.tasks[ganttRow.tasks.length-1].id);
				}
            }, 500);
     	});
	}
	
	addToGanttChartFromOptimization(candidateId, trainingPeriod) {
		var ganttRow = this.data[this.selectedTrainingScheduleVersionId].filter(function(x) { return x.id == candidateId; })[0];
		var candidate = this.candidates.filter(function(x) { return x.id == candidateId })[0];
		
		if (trainingPeriod.unavailability_periods != undefined && trainingPeriod.unavailability_periods.length > 0) {
			ganttRow.tasks.push(this.dataService.createTrainingPeriodTaskForCandidate(
				new TrainingPeriod({
					candidate_id: trainingPeriod.candidate_id,
					start_date: trainingPeriod.start_date,
					end_date: trainingPeriod.unavailability_periods[0].start_date,
					id: trainingPeriod.id + "-1",
					is_locked: trainingPeriod.locked,
					location: trainingPeriod.location,
					location_id: trainingPeriod.location_id,
					ratio: trainingPeriod.ratio,
					training_period_accumulation_type: trainingPeriod.training_period_accumulation_type,
					training_period_accumulation_type_id: trainingPeriod.training_period_accumulation_type_id,
					is_changed: trainingPeriod.is_changed,
					unavailability_periods: trainingPeriod.unavailability_periods // only for the first trainingPeriod, needed for recalculate_kpis
				}), 
				candidate, this.user.customer.regional_customer_id != null));
				
			ganttRow.tasks.push(this.dataService.createTrainingPeriodTaskForCandidate(
				new TrainingPeriod({
					candidate_id: trainingPeriod.candidate_id,
					start_date: trainingPeriod.unavailability_periods[0].end_date,
					end_date: trainingPeriod.end_date,
					id: trainingPeriod.id + "-2",
					is_locked: trainingPeriod.locked,
					location: trainingPeriod.location,
					location_id: trainingPeriod.location_id,
					ratio: trainingPeriod.ratio,
					training_period_accumulation_type: trainingPeriod.training_period_accumulation_type,
					training_period_accumulation_type_id: trainingPeriod.training_period_accumulation_type_id,
					is_changed: trainingPeriod.is_changed
				}), 
				candidate, this.user.customer.regional_customer_id != null));
		} else {
			ganttRow.tasks.push(this.dataService.createTrainingPeriodTaskForCandidate(trainingPeriod, candidate, this.user.customer.regional_customer_id != null));
		}
	}

	deleteFromGanttChart(candidateId, trainingPeriod) {
		var ganttRow = this.data[this.selectedTrainingScheduleVersionId].filter(function(x) { return x.id == candidateId; })[0];

		// // lookup trainingPeriod in list of changedTrainingPeriods if it has been added through the optimization routine
		// // (then it doesn't have a ganttTaskId)
		// if (lookupWithIdInsteadOfGanttTaskId) {
		// 	trainingPeriod = $scope.changedTrainingPeriods.filter(function(x) { return x.id == trainingPeriod.id })[0];
		// }

		var index = -1;
		ganttRow.tasks.some(function(el, i) {
	    if (
				el.ganttTaskType == "trainingPeriod" &&
				(
					// (lookupWithIdInsteadOfGanttTaskId && el.trainingPeriod.id == trainingPeriod.id) ||
					// (!lookupWithIdInsteadOfGanttTaskId && el.id == trainingPeriod.ganttTaskId)
					(trainingPeriod.id != null && el.trainingPeriod.id == trainingPeriod.id) ||
					(trainingPeriod.id == null && el.id == trainingPeriod.ganttTaskId)
				)
				
			) {
				index = i;
       	return true;
	    }
		});

		if (index > -1) {
			ganttRow.tasks.splice(index, 1);
		}

		// delete from changedPeriods
		var changedPeriodsIndex = -1;
		this.changedTrainingPeriods.some(function(tp, i) {
	    if (
				// (lookupWithIdInsteadOfGanttTaskId && tp.id == trainingPeriod.id) ||
				// (!lookupWithIdInsteadOfGanttTaskId && tp.ganttTaskId == trainingPeriod.ganttTaskId)
				(trainingPeriod.id != null && tp.id == trainingPeriod.id) ||
				(trainingPeriod.id == null && tp.ganttTaskId == trainingPeriod.ganttTaskId)

			) {
				changedPeriodsIndex = i;
        return true;
	    }
		});
		
		if (changedPeriodsIndex > -1) {
			this.changedTrainingPeriods.splice(changedPeriodsIndex, 1);
		}
	}

	save() {
		var self = this;
		var validationErrors = [];
		
		this.changedTrainingPeriods.forEach(function(changedTrainingPeriod) {
			var trainingPeriodsForOverlapCheck = self.data[self.selectedTrainingScheduleVersionId].filter(function(x) { return x.id == changedTrainingPeriod.candidate_id; }
					)[0].tasks.map(function(x) { return x.trainingPeriod; });
			
			trainingPeriodsForOverlapCheck.forEach(function(trainingPeriodForOverlapCheck) {
				if (changedTrainingPeriod.ganttTaskId != trainingPeriodForOverlapCheck.ganttTaskId &&
					// this will make sure we see each overlap validation error only once
					changedTrainingPeriod.ganttTaskId < trainingPeriodForOverlapCheck.ganttTaskId && 
					(
						moment(changedTrainingPeriod.start_date).isBefore(moment(trainingPeriodForOverlapCheck.end_date)) &&
						moment(changedTrainingPeriod.end_date).isAfter(moment(trainingPeriodForOverlapCheck.start_date))
					)
				) {
					validationErrors.push("Overlap tussen opleidingsonderdelen [" + (new Candidate(changedTrainingPeriod.candidate)).infixWithLastname() + 
						", " + changedTrainingPeriod.training_period_accumulation_type.name + 
						", " + moment(changedTrainingPeriod.start_date).format('D MMM') + " t/m " + moment(changedTrainingPeriod.end_date).subtract(1, 'day').format('D MMM') + 
						"] en [" + (new Candidate(trainingPeriodForOverlapCheck.candidate)).infixWithLastname() + 
						", " + trainingPeriodForOverlapCheck.training_period_accumulation_type.name + 
						", " + moment(trainingPeriodForOverlapCheck.start_date).format('D MMM') + " t/m " + moment(trainingPeriodForOverlapCheck.end_date).subtract(1, 'day').format('D MMM') + 
						"]");
				}
			});
		});

		if (validationErrors.length > 0) {
			alert("Kan de wijzigingen niet opslaan om de volgende reden(en):" + validationErrors.map(function(x) { return "\n- " + x }).join() )
		} else {
			// prevent circular reference errors
			this.changedTrainingPeriods.forEach(function(trainingPeriod) {
				trainingPeriod.candidate.training_periods = [];
			});

			var changedTrainingPeriodsToSend = this.changedTrainingPeriods.splice(0);
			changedTrainingPeriodsToSend.forEach(function(x) {
				var startDate = moment(x.start_date);
				var endDate = moment(x.end_date);
				startDate.locale('en');
				endDate.locale('en');
				x.start_date = startDate.format("ddd D MMM YYYY");
				x.end_date = endDate.format("ddd D MMM YYYY");
			});

			this.http.post("training_periods/save_bulk",
				{ 
					changed_training_periods: changedTrainingPeriodsToSend,
					deleted_training_period_ids: this.deletedTrainingPeriodIds
				}
			).subscribe(() => {

				this.toastr.success("De opleidingsonderdelen zijn succesvol opgeslagen");

				// if we don't refresh the tasks then we get the bug for the situation
				// when we add a new training period and we save it, and without refresh of page we delete it, the id is not in 
				// deletedTrainingPeriodIds
//				this.refreshTrainingPeriods(this.selectedTrainingScheduleVersionId);
				
				this.changedTrainingPeriods = [];
				this.deletedTrainingPeriodIds = [];
			}, function () {
				// dismiss
				this.changedTrainingPeriods = [];
				this.deletedTrainingPeriodIds = [];
	  		});
		}
	}

	startEdit() {
		this.isEdit = true;
	}
	
	cancelEdit() {
		this.isEdit = false;
		
		// TODO: does this work?
		this.initialize();
	}

	createGanttTask(event: MouseEvent, row) {
		if (this.user.customer.regional_customer_id == null) {
			var startDate = moment(this.fromDate).add(this.getDayOffsetByPixelOffset(event.offsetX), 'days');
			var endDate = moment(startDate).add(1, 'month');
			if (endDate.isAfter(moment(this.toDate).add(1, 'day'))) {
				endDate = moment(this.toDate).add(1, 'day');
			}

			this.createTrainingPeriod({ row: row, from: startDate.format('YYYY-MM-DD'), to: endDate.format('YYYY-MM-DD') })
		}
	}

	onResizeEnd(event: ResizeEvent, trainingPeriodTask, row): void {
		if (event.edges) {
			this.changeStartEndDateByDelta(event.edges.left, event.edges.right, trainingPeriodTask, row);
		}
	}

	dragStarted() {
		this.isDragging = true
	}

	dragEnded(event: CdkDragEnd, trainingPeriodTask, row) {
		this.changeStartEndDateByDelta(event.distance.x, event.distance.x, trainingPeriodTask, row);
	}

	changeStartEndDateByDelta(startDateDelta, endDateDelta, trainingPeriodTask, row) {
		var trainingPeriod = trainingPeriodTask.trainingPeriod;

		if (startDateDelta && startDateDelta != 0) {
			trainingPeriod.start_date = moment(trainingPeriod.start_date).add(this.getDayOffsetByPixelOffset(<number> startDateDelta), 'days').format('YYYY-MM-DD');
		}
		
		if (endDateDelta && endDateDelta != 0) {
			trainingPeriod.end_date = moment(trainingPeriod.end_date).add(this.getDayOffsetByPixelOffset(<number> endDateDelta), 'days').format('YYYY-MM-DD');
		}

		setTimeout(() => {
			trainingPeriod.ganttTaskId = trainingPeriodTask.id;
			this.deleteFromGanttChart(row.id, trainingPeriod);

			var ganttTaskId = this.addToGanttChart(trainingPeriod.candidate_id, trainingPeriod, false);
			trainingPeriodTask.trainingPeriod.ganttTaskId = ganttTaskId;
			this.addChangedTrainingPeriod(trainingPeriodTask.trainingPeriod);
		}, 100);
	}

	getDayOffsetByPixelOffset(pixelOffset) {
		return Math.round(pixelOffset / this.columnWidth);
	}

	startOptimization() {
		if (window.confirm('Weet je het zeker?')) {
			this.isLoading = true;

			this.http.post("optimization/optimize",
				{ 
					training_schedule_version_id: this.selectedTrainingScheduleVersionId
				}
			).subscribe(response => {
				this.refreshTrainingPeriods(this.selectedTrainingScheduleVersionId);
			});

			// 	$scope.eventSource = new EventSource('/optimization?target=' + $scope.tabs[$scope.selectedTabIndex].target + '&current_kpi_locations=' + $scope.tabs[0].kpis["Location capacity"] + '&location_weight=' + ($scope.selectedTabIndex == 2 ? 10 : 1));
			// 	var timeout = 50; //100; //50
			// 	var timeoutDeadline = moment().add(timeout, 'seconds');
			// 	$scope.isOptimizing = true;
			// 	$scope.eventSource.onmessage = function(event) {
			// 		$scope.$apply(function () {
			// 			var responseObject = JSON.parse(event.data);

			// 			if (responseObject["training_periods"] != null) {
			// 				$scope.hasBeenOptimized = true;

			// 				$scope.data[$scope.selectedTabIndex].forEach(function(ganttRow) {
			// 					ganttRow.tasks = ganttRow.tasks.filter(function(x) { return x.ganttTaskType == "candidateUnavailabilityPeriod" });
			// 				});
					
			// 				var changedTrainingPeriods = responseObject["training_periods"];
			// 				var kpis = responseObject["kpis"];
			// 				var kpisPerMonth = responseObject["kpis_per_month"];
								
			// 				changedTrainingPeriods.forEach(function(changedTrainingPeriod, index) {

			// 					// only delete trainingPeriods when it's not a new training period being added
			// 					if (!changedTrainingPeriod.is_added) {
			// 						$scope.deleteFromGanttChart(changedTrainingPeriod.candidate_id, changedTrainingPeriod);
			// 					}

			// 					$scope.addToGanttChartFromOptimization(changedTrainingPeriod.candidate_id, changedTrainingPeriod);
			// 					$scope.addChangedTrainingPeriodFromOptimization(changedTrainingPeriod);
			// 				});
					
			// 				$scope.updateKpis([$scope.selectedTabIndex], true, 0, kpis["Location capacity"], kpis["Number of changes"], kpis["Number of splits"]);
			// 				$scope.updateKpisPerMonth([$scope.selectedTabIndex], kpisPerMonth["Location capacity"], kpisPerMonth["Number of changes"], kpisPerMonth["Number of splits"])

			// 			} else {
			// 				$scope.optimizationProgress = responseObject["optimization_progress"];
			// 			}
			// 		});
					
			// 		if (moment() > timeoutDeadline) {
			// 			$scope.$apply(function () {
			// 				$scope.stopOptimization();
			// 			});
			// 		}
			// };
		}
	};

	stopOptimization () {
		// $scope.eventSource.close();
		// $scope.isOptimizing = false;
	}

	changeTab(trainingScheduleVersionId) {
		this.selectedTrainingScheduleVersionId = trainingScheduleVersionId;
	}

	closeTab(tabIndex, event) {
		var trainingScheduleVersionId = this.tabs[tabIndex].trainingScheduleVersion.id;

		this.trainingScheduleVersionsOpened[trainingScheduleVersionId] = false;

		this.tabs.splice(tabIndex, 1);
		delete this.data[trainingScheduleVersionId];
		delete this.kpiValuesForChart[trainingScheduleVersionId];
		delete this.horizontalScrollReceivers1[trainingScheduleVersionId];
		delete this.verticalScrollReceivers1[trainingScheduleVersionId];
		delete this.verticalScrollReceivers2[trainingScheduleVersionId];
		delete this.filterOptionLocationId[trainingScheduleVersionId];
		delete this.filterOptionTrainingLocationId[trainingScheduleVersionId];
		delete this.filterOptionTrainingPeriodAccumulationTypeId[trainingScheduleVersionId];
		delete this.filterOptionYear[trainingScheduleVersionId];
		delete this.showOccupationTable[trainingScheduleVersionId];
		delete this.compareWithOther[trainingScheduleVersionId];

		if (this.selectedTrainingScheduleVersionId == trainingScheduleVersionId) {
			this.selectedTrainingScheduleVersionId = this.tabs.length > 0 ? this.tabs[0].trainingScheduleVersion.id : null;;
		}

		if (event != null) {
			event.stopPropagation();
		}
	}

	showCandidateViolations(tab, candidateId, candidateName) {
		this.bsModalService.show(CandidateViolationsComponent, {
			initialState: {
				candidateId: candidateId,
				candidateName: candidateName,
				preferredOrderViolations: tab.preferredOrderViolationsPerCandidate[candidateId],
				preferredOrderViolationCount: tab.preferredOrderViolationsCountPerCandidate[candidateId],
				preferredLocationViolations: tab.preferredLocationViolationsPerCandidate[candidateId],
				preferredLocationViolationCount: tab.preferredLocationViolationsCountPerCandidate[candidateId],
				changes: tab.changesPerCandidate[candidateId],
				changesCount: tab.changesCountPerCandidate[candidateId],
				formerTrainingPeriods: tab.formerTrainingPeriodsPerCandidate[candidateId],
				newTrainingPeriods: tab.newTrainingPeriodsPerCandidate[candidateId],
				numberOfDays: this.numberOfDays, 
				columnWidth: this.columnWidth,
				fromDate: this.fromDate,
				dateHeaderRows: this.dateHeaderRows,
				monthColumns: this.monthColumns,
				candidate: this.candidates.filter(function(x) { return x.id == candidateId })[0]
			},
			class: 'modal-lg'
		});
	}
	
	// createProposal() {
	// 	this.tabs = this.tabs.concat([
	// 		{ 
	// 			title: "", 
	// 			target: "Number of changes", 
	// 			kpis: { "Location capacity": "-", "Number of changes": "-", "Preference": "-", "Number of splits": "-" },
	// 			savedKpis: { "Location capacity": "", "Number of changes": "", "Preference": "", "Number of splits": "" }
	// 		},
	// 		{ 
	// 			title: "", 
	// 			target: "Location capacity", 
	// 			kpis: { "Location capacity": "-", "Number of changes": "-", "Preference": "-", "Number of splits": "-" },
	// 			savedKpis: { "Location capacity": "", "Number of changes": "", "Preference": "", "Number of splits": "" }
	// 		}
	// 	]);
		
	// 	this.refresh([1, 2]);
	// }
	
	saveProposal() {		
		// 	var modalInstance = $uibModal.open({
		// 	templateUrl: "<%= asset_path('descriptionSaveProposal.html') %>",
		//   controller: DescriptionSaveProposalController,
		// 		size: 'sm',
		// 		resolve: {
		// 		}
		// 	});

		// 	modalInstance.result.then(function () {

		// 		for (var i=$scope.tabs.length; i > 0; i--) {
		// 			if (i == $scope.selectedTabIndex) {
		// 				$scope.tabs[$scope.selectedTabIndex].title = "Voorstel"
		// 			} else {
		// 				$scope.tabs.splice(i, 1);
		// 				$scope.data.splice(i, 1);
		// 			}
		// 		}
			
		// 		$scope.selectedTabIndex = 1;

		// 	}, function () {
		// 		// dismiss
		// 	});
	}

	updateKpis(tabIndices, updatedSavedKpis, hardViolations, locationCapacity, numberOfChanges, numberOfSplits) {
		// tabIndices.forEach(function(tabIndex) {
		// 	$scope.tabs[tabIndex].kpis["Hard violations"] = hardViolations;
		// 	$scope.tabs[tabIndex].kpis["Location capacity"] = locationCapacity;
		// 	$scope.tabs[tabIndex].kpis["Number of changes"] = numberOfChanges;
		// 	$scope.tabs[tabIndex].kpis["Number of splits"] = numberOfSplits;
		
		// 	if (updatedSavedKpis) {
		// 		$scope.tabs[tabIndex].savedKpis["Hard violations"] = hardViolations;
		// 		$scope.tabs[tabIndex].savedKpis["Location capacity"] = locationCapacity;
		// 		$scope.tabs[tabIndex].savedKpis["Number of changes"] = numberOfChanges;
		// 		$scope.tabs[tabIndex].savedKpis["Number of splits"] = numberOfSplits;
		// 	}
		// });

		// $scope.isLoading = false;
	}
	
	recalculateKpis(tabIndices) {
		// var self = this;

		// if (this.user.customer.customer_type_id == 2) {
		// 	this.isRecalculatingKpis = true;
			
		// 	var trainingPeriods = {};
			
		// 	this.candidates.forEach(function(candidate) {
		// 		tabIndices.forEach(function(tabIndex) {
		// 			var tasks = self.data[tabIndex].filter(function(x) { return x.id == candidate.id })[0].tasks.filter(function(x) { return x.ganttTaskType == "trainingPeriod" });
					
		// 			tasks.forEach(function(task) {
		// 				task.candidate = null;
		// 				task.trainingPeriod.candidate = null;
		// 			});
				
		// 			trainingPeriods[candidate.id] = tasks;
		// 		});
		// 	});
			
		// 	this.http.post("optimization/recalculate_kpis",
		// 		{ 
		// 			training_periods: JSON.stringify(trainingPeriods)
		// 		}
		// 	).subscribe(response => {
		// 		var responseObject = response.error;
		// 		var kpis = responseObject["kpis"];
		// 		var kpisPerMonth = responseObject["kpis_per_month"];

		// 		this.updateKpis(tabIndices, false, 0, kpis["Location capacity"], "-", kpis["Number of splits"]);
		// 		this.updateKpisPerMonth(tabIndices, kpisPerMonth["Location capacity"], [], kpisPerMonth["Number of splits"]);
				
		// 		this.isRecalculatingKpis = false;
		// 	});
		// }
	}
	
	updateKpisPerMonth(trainingScheduleVersionIds, locationCapacityPerMonth, numberOfChangesPerMonth, numberOfSplitsPerMonth) {
		var self = this;
		Object.keys(locationCapacityPerMonth).forEach(function(locationId) {
			trainingScheduleVersionIds.forEach(function(trainingScheduleVersionId) {
				self.data[trainingScheduleVersionId].filter(function(x) { return x.id == "location_" + locationId })[0].tasks = locationCapacityPerMonth[locationId].filter(function(x) { return x.value > 0 }).map(function(x) { return self.dataService.createLocationViolationCount(x) });
			});
		});

		trainingScheduleVersionIds.forEach(function(trainingScheduleVersionId) {
			self.data[trainingScheduleVersionId].filter(function(x) { return x.id == "numberOfChanges" })[0].tasks = numberOfChangesPerMonth.filter(function(x) { return x.value > 0 }).map(function(x) { return self.dataService.createChangesCount(x) });
			self.data[trainingScheduleVersionId].filter(function(x) { return x.id == "numberOfSplits" })[0].tasks = numberOfSplitsPerMonth.filter(function(x) { return x.value > 0 }).map(function(x) { return self.dataService.createSplitsCount(x) });
		});
	}

	getTooltipText(element) {
		var task = JSON.parse(element.getAttribute("taskasjson"));

		return this.domSanitizer.bypassSecurityTrustHtml(
			(task.ganttTaskType == "locationViolationCount" ?
				"<div>" +
					task.tooltipTitle + "<br />" +
					"<small>" +
						task.tooltipSubtitle + "<br />" +
						task.dateLabel +
					"</small>" + 
				"</div>" : 
				"<div>" + 
					task.tooltipTitle + "<br />" +
					"<small>" +
						(task.candidate ? task.candidate.nameField : "") + "<br />" +
						task.dateLabel +
					"</small>" + 
				"</div>"
			)
		);
	}

	syncScroll(event) {
		this.horizontalScrollReceivers1[this.selectedTrainingScheduleVersionId].scrollLeft = event.scrollLeft;
		this.verticalScrollReceivers1[this.selectedTrainingScheduleVersionId].scrollTop = event.scrollTop;
		this.verticalScrollReceivers2[this.selectedTrainingScheduleVersionId].scrollTop = event.scrollTop;
	}

	refreshTrainingScheduleVersions(isInitialization, openLast) {
		var self = this;

		this.http.get("training_schedule_versions").subscribe(x => {
			this.addTargetIconsAndOperationalTrainingScheduleVersion(x);

			this.trainingScheduleVersions.filter(function(y) { return y.id != 0; }).forEach(function(trainingScheduleVersion) {
				trainingScheduleVersion.lastTrainingScheduleVersionEvaluationsPerLocation = {}

				var isAcceptedByAll = true;
				self.trainingLocations.filter(function(location) {
					var trainingScheduleVersionEvaluations = trainingScheduleVersion.training_schedule_version_evaluations.filter(function(y) { return y.location_id == location.id });

					if (trainingScheduleVersionEvaluations.length > 0) {
						trainingScheduleVersion.lastTrainingScheduleVersionEvaluationsPerLocation[location.id] = trainingScheduleVersionEvaluations[trainingScheduleVersionEvaluations.length-1]

						if (trainingScheduleVersion.lastTrainingScheduleVersionEvaluationsPerLocation[location.id].status_id != 3) {
							isAcceptedByAll = false;	
						}
					} else {
						trainingScheduleVersion.lastTrainingScheduleVersionEvaluationsPerLocation[location.id] = { status_id: 2 };
						isAcceptedByAll = false;
					}
				});

				trainingScheduleVersion.isAcceptedByAll = isAcceptedByAll;
			});

			if (isInitialization) {
				this.trainingScheduleVersions.forEach(x => {
					self.trainingScheduleVersionsOpened[x.id] = false;
				});
	
				if (this.isVisible) {
					this.isLoading = false;
					this.isEdit = this.user.isAdmin;
					this.changedTrainingPeriods = [];
					this.deletedTrainingPeriodIds = [];
			
					this.openTrainingScheduleVersion(this.trainingScheduleVersions[0]);
				}	
			}

			if (openLast) {
				this.openTrainingScheduleVersion(this.trainingScheduleVersions[this.trainingScheduleVersions.length-1]);
			}
		})
	}

	openTrainingScheduleVersion(trainingScheduleVersion) {
		if (this.trainingScheduleVersionsOpened[trainingScheduleVersion.id]) {
			this.changeTab(trainingScheduleVersion.id);
		} else {
			this.tabs = this.tabs.concat([
				{ 
					title: trainingScheduleVersion.name, 
					target: this.kpiNames[trainingScheduleVersion.optimization_strategy_id], 
					kpis: { "Location capacity": "-", "Number of changes": "-", "Preference": "-", "Number of splits": "-" },
					savedKpis: { "Location capacity": "", "Number of changes": "", "Preference": "", "Number of splits": "" },
					trainingScheduleVersion: trainingScheduleVersion,
					isRecalculatingKpis: true,
					filteredTrainingPeriodAccumulationTypes: [],
					filteredLocations: []
				},
			]);
	
			this.trainingScheduleVersionsOpened[trainingScheduleVersion.id] = true;

			this.filterOptionLocationId[trainingScheduleVersion.id] = this.user.customer.has_only_access_to_own_location ? this.user.customer.regional_location_id : 0;
			this.filterOptionTrainingLocationId[trainingScheduleVersion.id] = 0;
			this.filterOptionTrainingPeriodAccumulationTypeId[trainingScheduleVersion.id] = this.user.hasOnlyAccessToTrainingPeriodAccumulationTypeId ? this.user.hasOnlyAccessToTrainingPeriodAccumulationTypeId : 0;
			this.filterOptionYear[trainingScheduleVersion.id] = 0;
			this.showOccupationTable[trainingScheduleVersion.id] = false;
			this.compareWithOther[trainingScheduleVersion.id] = -1;

			this.refreshTrainingPeriods(trainingScheduleVersion.id);
	
			this.changeTab(trainingScheduleVersion.id);
	
			this.initializeScroll(trainingScheduleVersion.id);	
		}
	}

	toggleTrainingScheduleVersion(trainingScheduleVersion) {
		if (this.trainingScheduleVersionsOpened[trainingScheduleVersion.id]) {
			this.closeTab(this.tabs.map(function(x) { return x.trainingScheduleVersion.id }).indexOf(trainingScheduleVersion.id), null)
		} else {
			this.openTrainingScheduleVersion(trainingScheduleVersion);
		}
	}

	duplicateTrainingScheduleVersion(trainingScheduleVersion) {
		// Detach view otherwise the modal gets very slow
		this.changeDetectorRef.detach();

		var bsModalRef = this.bsModalService.show(TrainingScheduleVersionFormComponent, {
            initialState: {
				referenceTrainingScheduleVersion: trainingScheduleVersion,
				isDuplicate: true
            },
            class: 'xxlModal'
        });

		this.bsModalService.onHide.subscribe(result => {
			this.changeDetectorRef.reattach();
		});

        bsModalRef.content.action.subscribe(result => {
			this.changeDetectorRef.reattach();
			this.refreshTrainingScheduleVersions(false, true);		
		});
	}

	editTrainingScheduleVersion(trainingScheduleVersion) {
		// Detach view otherwise the modal gets very slow
		this.changeDetectorRef.detach();

        var bsModalRef = this.bsModalService.show(TrainingScheduleVersionFormComponent, {
            initialState: {
				referenceTrainingScheduleVersion: trainingScheduleVersion,
				isDuplicate: false
            },
            class: 'xxlModal'
        });

		this.bsModalService.onHide.subscribe(result => {
			this.changeDetectorRef.reattach();
		});

        bsModalRef.content.action.subscribe(result => {
			this.changeDetectorRef.reattach();
			this.refreshTrainingScheduleVersions(false, false);
        });
    }

    deleteTrainingScheduleVersion(trainingScheduleVersionId) {
		if (window.confirm('Weet je het zeker?')) {
			this.http.delete("training_schedule_versions/" + trainingScheduleVersionId).subscribe(() => {
				var tabIndex = this.tabs.map(function(x) { return x.trainingScheduleVersion.id }).indexOf(trainingScheduleVersionId);

				if (tabIndex >= 0) {
					this.closeTab(tabIndex, null);
				}

				delete this.trainingScheduleVersionsOpened[trainingScheduleVersionId];
				
				this.refreshTrainingScheduleVersions(false, false);
			}, (response) => {
				this.toastr.error(response.error.error);
			});
        }
	}

	toggleVersionsVisible() {
		this.versionsVisible = !this.versionsVisible;
	}

	changeFilterOptionLocationId(filterOptionLocationId) {
		this.filterOptionLocationId[this.selectedTrainingScheduleVersionId] = filterOptionLocationId;
		this.filterChanged(this.selectedTrainingScheduleVersionId);
	}

	changeFilterOptionTrainingLocationId(filterOptionTrainingLocationId) {
		this.filterOptionTrainingLocationId[this.selectedTrainingScheduleVersionId] = filterOptionTrainingLocationId;
		this.filterChanged(this.selectedTrainingScheduleVersionId);
	}

	changeFilterOptionTrainingPeriodAccumulationTypeId(filterOptionTrainingPeriodAccumulationTypeId) {
		this.filterOptionTrainingPeriodAccumulationTypeId[this.selectedTrainingScheduleVersionId] = filterOptionTrainingPeriodAccumulationTypeId;
		this.filterChanged(this.selectedTrainingScheduleVersionId);
	}

	changeFilterOptionYear(filterOptionYear) {
		this.filterOptionYear[this.selectedTrainingScheduleVersionId] = filterOptionYear;
		this.filterChanged(this.selectedTrainingScheduleVersionId);

		if (filterOptionYear == "0") {
			this.scrollToStartHorizon();
		} else {
			this.scrollTo(moment(filterOptionYear + "-01-01"));
		}
	}

	changeShowOccupationTable(showOccupationTable) {
		this.showOccupationTable[this.selectedTrainingScheduleVersionId] = showOccupationTable;
		this.compareWithOther[this.selectedTrainingScheduleVersionId] = -1;
		this.filterChanged(this.selectedTrainingScheduleVersionId);
	}

	changeCompareWithOther(trainingScheduleVersionId) {
		this.compareWithOther[this.selectedTrainingScheduleVersionId] = trainingScheduleVersionId;
		if (trainingScheduleVersionId && trainingScheduleVersionId != -1) {
			this.showOccupationTable[this.selectedTrainingScheduleVersionId] = true;
		}		
		this.filterChanged(this.selectedTrainingScheduleVersionId);
	}
	
	addTrainingScheduleVersionEvaluation(trainingScheduleVersionId, lastTrainingScheduleVersionEvaluationForLocation) {
		var bsModalRef = this.bsModalService.show(TrainingScheduleVersionEvaluationFormComponent, {
			initialState: {
				trainingScheduleVersionId: trainingScheduleVersionId,
				lastTrainingScheduleVersionEvaluationForLocation: lastTrainingScheduleVersionEvaluationForLocation
			}
		});

		bsModalRef.content.action.subscribe(result => {	
			this.refreshTrainingScheduleVersions(false, false);
		});

		event.preventDefault();
	}

	initialize() {
		var self = this;

		if (this.user == undefined) {
			this.router.navigate(['/login']);
		}

		if (this.user.customer.regional_customer_id != null) {
			this.filterOptionId = 2;
			//this.environmentService.refreshTrainingPeriodDifferencesOverview();
		}
		
		this.isVisible = this.user != null && (this.user.isAdmin || !this.user.customer.training_schedule_is_temporarily_hidden);
		this.tabs = [];
		this.selectedTrainingScheduleVersionId = 0;
		this.data = {};
		this.kpiValuesForChart = {};
		this.trainingScheduleVersionsOpened = {};
		this.filterOptionLocationId = {};
		this.filterOptionTrainingLocationId = {};
		this.filterOptionTrainingPeriodAccumulationTypeId = {};
		this.filterOptionYear = {};
		this.showOccupationTable = {};
		this.compareWithOther = {};

		this.years = [2024, 2025, 2026, 2027, 2028, 2029, 2030];

		this.http.get<any>("locations/index_with_location_capacities").subscribe(x => {
			this.locations = x;
			this.trainingLocations = x.filter(function(y) { return y.is_training_location; });

			this.refreshTrainingScheduleVersions(true, false);
		});
	}

	addTargetIconsAndOperationalTrainingScheduleVersion(trainingScheduleVersions) {
		var self = this;

		var operationalTrainingScheduleVersion = new TrainingScheduleVersion();
		operationalTrainingScheduleVersion.id = 0;
		operationalTrainingScheduleVersion.name = "Huidig";

		this.trainingScheduleVersions = [operationalTrainingScheduleVersion].concat(trainingScheduleVersions.filter(function(x) { return !x.is_archived; }));
		this.archivedTrainingScheduleVersions = trainingScheduleVersions.filter(function(x) { return x.is_archived; });

		this.allTrainingScheduleVersions = this.trainingScheduleVersions.concat(this.archivedTrainingScheduleVersions);

		this.trainingScheduleVersions.forEach(x => {
			var matchedKpis = this.kpis.filter(function(y) { return y.name == self.kpiNames[x.optimization_strategy_id] });

			x.targetIconName = matchedKpis.length > 0 ? matchedKpis[0].iconName : null;
		});
	}

	hasUnsavedData() {
        return this.isVisible && 
			((this.changedTrainingPeriods != null && this.changedTrainingPeriods.length > 0) || 
				(this.deletedTrainingPeriodIds != null && this.deletedTrainingPeriodIds.length > 0));
	}

	initializeScroll(trainingScheduleVersionId) {
		var self = this;

		var trainingScheduleVersionClass = '.tabTrainingScheduleVersion' + trainingScheduleVersionId.toString();

		setTimeout(function() {
			self.horizontalScrollReceivers1[trainingScheduleVersionId] = document.querySelectorAll(trainingScheduleVersionClass + ' .myHorizontalScrollReceiver')[0];
			var verticalScrollReceivers = document.querySelectorAll(trainingScheduleVersionClass + ' .myVerticalScrollReceiver');
			self.verticalScrollReceivers1[trainingScheduleVersionId] = verticalScrollReceivers[0];
			self.verticalScrollReceivers2[trainingScheduleVersionId] = verticalScrollReceivers[1];	
		}, 1000);
	}

	ngOnInit(): void {	
		this.isLoading = true;

		this.user = this.environmentService.getUser();

		this.view = [(moment(this.user.customer.training_schedule_end_date).diff(moment(this.user.customer.start_date), 'years') + 1) *12*91.5-18+35, 175];  // 8766px + 50

		this.optimizationProgress = 0;
		
		this.filterOptions = [
			{ id: 1, title: "Huidige/toekomstige " + this.environmentService.translateForIndustry('candidates') },
			{ id: 2, title: "Alle " + this.environmentService.translateForIndustry('candidates') }
		];

		if (this.user.location == null) {
			this.filterOptionsOwnLocation = [
				{ id: 1, title: "AIOS " },
				{ id: 2, title: "Alle AIOS" }
			];

			this.filterOptionOwnLocationId = 2;
		} else {
			this.filterOptionsOwnLocation = [
				{ id: 1, title: "AIOS " + this.user.location.training_origin_name },
				{ id: 2, title: "Alle AIOS" }
			];

			this.filterOptionOwnLocationId = 1;
		}
		
		if (this.user.customer.regional_customer_id != null) {
			this.filterOptionId = 2;
		} else {
			this.filterOptionId = 1;
		}
		
		this.hasBeenOptimized = false;
		
		this.kpis = [
			{ name: "Location capacity", title: "Capaciteit min/max : richtwaarde", iconName: "users" },
			{ name: "Number of changes", title: "Wijzigingen", iconName: "exchange" },
			{ name: "Preference", title: "Voorkeuren volgorde : locatie", iconName: "star" },
			//{ name: "Number of splits", title: "Opdelingen", iconName: "scissors" },
		];

		this.optimizationStrategies = ["Aantal wijzigingen", "Capaciteit", "Voorkeuren", "Opdelingen"];
		
		this.initialize();
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();

		document.removeEventListener('mouseover', this.mouseover);
		document.removeEventListener('mouseout', this.mouseout);
    }
}

// function DescriptionSaveProposalController ($scope, $uibModalInstance, $filter, EnvironmentService, Candidate, Task, DataService, $rootScope, TrainingPeriodAccumulationType, Location) {

//   $scope.ok = function () {
// 		$uibModalInstance.close();
// 	};

//   $scope.cancel = function () {
//     $uibModalInstance.close();
//   };
// };