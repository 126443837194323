import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
 name: 'truncate'
})

export class TruncatePipe implements PipeTransform {

	transform(value: string, limit: number): string {
	  if (!value) {
	    return;
	  }

	  if (isNaN(limit)) {
	    limit = 10;
	  }

	  var trail = "...";

	  if (value.length <= limit || value.length - trail.length <= length) {
	    return value;
	  } else {
	    return String(value).substring(0, limit - trail.length) + trail;
	  }
	}
}