import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './taskConflictExceptionForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TaskConflictExceptionFormComponent implements OnInit {
    @Output() action = new EventEmitter();

	public schedulePeriodId: any;
	public isSideActivity: any;

	public isLoading: boolean;
	public tasks: any;
	public taskConflictExceptionEntries: any;
	public horizontalScrollReceiver1: any;
	public verticalScrollReceiver1: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	toggleIsException(isException, firstTaskId, secondTaskId) {
		this.taskConflictExceptionEntries[firstTaskId][secondTaskId] = isException;
		this.taskConflictExceptionEntries[secondTaskId][firstTaskId] = isException;
	}
	
	ok() {
		this.isLoading = true;

		this.http.post("task_conflict_exceptions/update_bulk",
			{
				schedule_period_id: this.schedulePeriodId,
				task_conflict_exception_entries: this.taskConflictExceptionEntries
			}
		).subscribe(() => {
			this.isLoading = false;
			this.action.emit(true);
			this.bsModalRef.hide();
		});
	}

  	cancel() {
		this.bsModalRef.hide();
	}

	syncScroll(event) {
		this.horizontalScrollReceiver1.scrollLeft = event.scrollLeft;
		this.verticalScrollReceiver1.scrollTop = event.scrollTop;
	}

    ngOnInit() {
		this.isLoading = true;
		
		this.http.get<any>("tasks", { params: { schedule_period_id: this.schedulePeriodId } }).subscribe(tasks => {
			this.tasks = this.isSideActivity ? 
				tasks.filter(function(x) { return !x.is_non_working && (x.is_side_activity || x.task_type.is_side_activity); }) :
				tasks.filter(function(x) { return !x.is_non_working && !x.is_side_activity && !x.task_type.is_side_activity; });

			this.tasks.forEach(function(task) {
				task.nameWithEquivalencePosition = task.name + (task.equivalence_position == null ? "" : " (" + task.equivalence_position + ")");
			});
		});

		this.http.get<any>("task_conflict_exceptions",
			{ params: { schedule_period_id: this.schedulePeriodId } }
		).subscribe(x => {
			this.taskConflictExceptionEntries = x.task_conflict_exception_entries;
			
			this.isLoading = false;
		});
	}
	
	ngAfterViewInit() {
		this.horizontalScrollReceiver1 = document.querySelectorAll('.myHorizontalScrollReceiver')[0];
		this.verticalScrollReceiver1 = document.querySelectorAll('.myVerticalScrollReceiver')[0];
	}
}
