import { Component, OnDestroy } from '@angular/core';
import templateString from './app.component.html'
import { Router, NavigationEnd } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  	selector: 'app',
	template: templateString
})
export class AppComponent implements OnDestroy {
	public isHomePage;
	public destroyed$;
	
	constructor(private router: Router) {
		this.destroyed$ = new Subject<boolean>();

		this.router.events
			.pipe(takeUntil(this.destroyed$))
			.subscribe((event) => {
				if (event instanceof NavigationEnd) {
					this.isHomePage = event.url == "/";
				}
			});
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
}
