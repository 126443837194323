export class AllocationRequestPart {
	public is_allocate_preference;
	public shift_id;
	public task_type_id;
	public start_date;
	public end_date;
	public status_id;
	public formatted_end_date;
	public openAllocationRequestPartCount;
	public acceptedAllocationRequestPartCount;
	public start_date_for_order;
	public start_date_time;
	public is_all_day;
	
	constructor(obj) {
		this.is_allocate_preference = obj.is_allocate_preference;
		this.shift_id = obj.shift_id;
		this.task_type_id = obj.task_type_id;
		this.start_date = obj.start_date;
		this.end_date = obj.end_date;
		this.status_id = obj.status_id;
		this.formatted_end_date = obj.formatted_end_date;
		this.openAllocationRequestPartCount = obj.openAllocationRequestPartCount;
		this.acceptedAllocationRequestPartCount = obj.acceptedAllocationRequestPartCount;
		this.start_date_for_order = obj.start_date_for_order;
		this.start_date_time = obj.start_date_time;
		this.is_all_day = obj.is_all_day;
	}
} 
