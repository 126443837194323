export class ReportPart {
	public index;
	public report_part_allocation_request_accumulation_types;
	public report_part_task_accumulation_types;
	
	constructor(obj) {
		this.index = obj.index;
		this.report_part_allocation_request_accumulation_types = obj.report_part_allocation_request_accumulation_types;
		this.report_part_task_accumulation_types = obj.report_part_task_accumulation_types;
	}
} 
