import { Component, OnInit } from '@angular/core';
import templateString from './candidateAllocationRequestBudgetComponents.component.html';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CandidateAllocationRequestBudgetComponentFormComponent } from '../candidateAllocationRequestBudgetComponentForm';
import { EnvironmentService } from 'site/app/environment.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Candidate } from 'site/app/models/candidate.model';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class CandidateAllocationRequestBudgetComponentsComponent implements OnInit {
    public destroyed$: any;
    public isLoading: boolean;
    public year: any;
    public allocationRequestAccumulationPeriods: any;
    public candidates: any;
    public customer: any;

    constructor(
        private http: HttpClient,
        private bsModalService: BsModalService,
        private environmentService: EnvironmentService
	) { 
        this.destroyed$ = new Subject<boolean>();
    }

	refresh() {
        this.isLoading = true;

        this.http.get<any>("allocation_request_accumulation_periods", { params: { year: this.year } }).subscribe(x => {
			this.allocationRequestAccumulationPeriods = x.filter(function(y) { return y.allocation_request_budget_components.length > 0; });
			
			if (this.allocationRequestAccumulationPeriods.length > 0) {
                this.http.get("candidates/index_for_budget_components", { params: { year: this.year } }).subscribe(x => {
					this.candidates = x;

                    this.candidates.forEach(function(candidate) {
						candidate.lastnameWithInfix = (new Candidate(candidate)).lastnameWithInfix();
					});
					
					this.isLoading = false;
				});
			} else {
				this.candidates = [];
				this.isLoading = false;
			}
		});
    }

    edit() {
        var bsModalRef = this.bsModalService.show(CandidateAllocationRequestBudgetComponentFormComponent, {
            initialState: {
                year: this.year,
                allocationRequestAccumulationPeriods: this.allocationRequestAccumulationPeriods,
                candidates: this.candidates
            },
            class: 'xlModal'
        });

        bsModalRef.content.action.subscribe(result => {
			this.refresh();
        });
    }

	initialize() {
        this.isLoading = true;
        
        this.candidates = [];

 		var selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();
        
        // TODO: DRY
        if (moment(selectedSchedulePeriod.start_date).year() == moment(selectedSchedulePeriod.end_date).year()) {
            this.year = moment(selectedSchedulePeriod.start_date).year();
        } else {
            // define year by calculating where largest part of current schedule_period lies in
            if (
                moment.duration(moment(selectedSchedulePeriod.start_date).endOf('year').diff(moment(selectedSchedulePeriod.start_date))).asDays() >
                moment.duration(moment(selectedSchedulePeriod.end_date).diff(moment(selectedSchedulePeriod.end_date).startOf('year'))).asDays()
            ) {
                this.year = moment(selectedSchedulePeriod.start_date).year();
            } else {
                this.year = moment(selectedSchedulePeriod.end_date).year();
            }
        }

 		this.customer = this.environmentService.getUser().customer;
		
 		this.refresh();
 	}
		
    ngOnInit() {
        this.environmentService.selectedSchedulePeriodChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.initialize();
            });

        this.initialize();
    }

    ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
    }
}
