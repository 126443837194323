import { Component, OnInit } from '@angular/core';
import templateString from './environment.component.html';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EnvironmentFormComponent } from '../environmentForm';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class EnvironmentComponent implements OnInit {
    public customer: any;
    public isLoading: boolean;
    public user: any;

    constructor(
        private http: HttpClient,
        private bsModalService: BsModalService,
        private environmentService: EnvironmentService
    ) { }
    
	refresh() {
        this.http.get("customers/" + this.user.customer.id).subscribe(x => {
			this.customer = x;

			this.isLoading = false;
		});
    }
    
    edit() {
        var bsModalRef = this.bsModalService.show(EnvironmentFormComponent, {
            initialState: {
                originalCustomer: this.customer
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

	initialize() {
	    this.user = this.environmentService.getUser();

	    this.refresh();
    }

    ngOnInit() {
        this.isLoading = true;

        this.initialize();
    }
}
