import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import templateString from './customAgendaChangeOrder.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class CustomAgendaChangeOrderComponent implements OnInit {
    @Output() action = new EventEmitter();
    
    public originalCustomAgendas: any;
    
    public customAgendas: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2
    ) { 
        this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
    }

    ok() {
        this.http.post("custom_agendas/update_order", { custom_agenda_ids: this.customAgendas.map(function(x) { return x.id; }) }).subscribe(() => {
            this.action.emit(true);
            this.bsModalRef.hide();
		});
    }

    cancel() {
        this.bsModalRef.hide();
    };

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.customAgendas, event.previousIndex, event.currentIndex);
    }

    ngOnInit() {
        this.customAgendas = JSON.parse(JSON.stringify(this.originalCustomAgendas));
    }
}
