import { Component, OnInit } from '@angular/core';
import templateString from './candidateTypeTaskMemberships.component.html';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CandidateTypeTaskMembershipFormComponent } from '../candidateTypeTaskMembershipForm';
import { EnvironmentService } from 'site/app/environment.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Task } from 'site/app/models/task.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class CandidateTypeTaskMembershipsComponent implements OnInit {
    public destroyed$: any;
    public isLoading: boolean;
    public accumulationPeriod: any;
    public selectedSchedulePeriod: any;
    public candidateTypes: any;
    public tasks: any;
    public isSuperAdmin: any;
    public customer: any;

    constructor(
        private http: HttpClient,
        private bsModalService: BsModalService,
        private environmentService: EnvironmentService
	) { 
        this.destroyed$ = new Subject<boolean>();
    }

	refresh() {
        var self = this;

        this.isLoading = true;

        this.http.get("candidate_types").subscribe(x => {
			this.candidateTypes = x;
			
			if (this.candidateTypes.length > 0) {
                this.http.get("tasks/index_for_candidate_type_task_memberships", 
                    { 
                        params: {
                            schedule_period_id: this.selectedSchedulePeriod.id, 
                        }
                    }
                ).subscribe(tasks => {
					this.tasks = tasks;

                    this.tasks.forEach(function(task) {
                        task.computedName = (new Task(task)).getComputedName();
                        
                        task.candidate_type_task_memberships_dictionary = {}
                        self.candidateTypes.forEach(function(candidateType) { 
                            task.candidate_type_task_memberships_dictionary[candidateType.id] = 
                                task.candidate_type_task_memberships.filter(function(x) { return x.candidate_type_id == candidateType.id }).length > 0;
                        });
					});
					
					this.isLoading = false;
				});
			} else {
				this.tasks = [];
				this.isLoading = false;
			}
		});
    }

    edit() {
        var bsModalRef = this.bsModalService.show(CandidateTypeTaskMembershipFormComponent, {
            initialState: {
                candidateTypes: this.candidateTypes,
                tasks: this.tasks
            },
            class: 'xlModal'
        });

        bsModalRef.content.action.subscribe(result => {
			this.refresh();
        });
    }

	initialize() {
        this.isLoading = true;
		
 		this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();
		
        this.isSuperAdmin = this.environmentService.getUser().isSuperAdmin;
 		this.customer = this.environmentService.getUser().customer;
		
 		this.refresh();
 	}
		
    ngOnInit() {
        this.environmentService.selectedSchedulePeriodChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.initialize();
            });

        this.initialize();
    }

    ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
    }
}
