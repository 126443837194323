import { Component } from '@angular/core';
import templateString from './profile.component.html'
import { ProfileFormComponent } from '../profileForm';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EnvironmentService } from 'site/app/environment.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class ProfileComponent {
	public user: any;
	public selectedSchedulePeriod: any;
	public extendedUser: any;
	public isLoading: boolean;
	public destroyed$: any;
	public allocationRequestTypesForNotificationSelection: any;
	public taskVariantGroups: any;
	public candidateTypes: any;
	public allIncludedForAccumulations: any;
	public allIncludedForAllocationRequests: any;
	public taskDisplayGroups: any;
	public allTaskDisplayGroupsAllowedForAdmin: any;
	public taskTypes: any;
	public allTaskTypesAllowedForAdmin: any;

	constructor(
		private http: HttpClient,
		private bsModalService: BsModalService,
		private environmentService: EnvironmentService
	) { 
		this.destroyed$ = new Subject<boolean>();
	}

	refresh() {
		this.http.get("other_users/get_for_profile",
			{
				params: {
					id: this.user.id, 
					schedule_period_id: this.selectedSchedulePeriod.id	
				}
			}
		).subscribe(x => {
			this.extendedUser = x;

			this.isLoading = false;
		});

		this.http.get("candidate_types", 
			{ 
				params: {
					for_user_id: this.user.id
				}
			}
		).subscribe(x => {
			this.candidateTypes = x;

			this.allIncludedForAllocationRequests = this.candidateTypes.filter(function(x) { return !x.is_included_for_allocation_requests; }).length == 0;
			this.allIncludedForAccumulations = this.candidateTypes.filter(function(x) { return !x.is_included_for_accumulations; }).length == 0;
		});

		this.http.get("task_display_groups/index_for_user_for_schedule_period", 
			{ 
				params: {
					user_id: this.user.id,
					schedule_period_id: this.selectedSchedulePeriod.id	
				}
			}
		).subscribe(x => {
			this.taskDisplayGroups = x;

			this.allTaskDisplayGroupsAllowedForAdmin = this.taskDisplayGroups.filter(function(x) { return !x.is_allowed_for_admin; }).length == 0;
		});

		this.http.get("task_types/index_for_user", 
			{ 
				params: {
					user_id: this.user.id
				}
			}
		).subscribe(x => {
			this.taskTypes = x;

			this.allTaskTypesAllowedForAdmin = this.taskTypes.filter(function(x) { return !x.is_allowed_for_admin; }).length == 0;
		});
	}

  	edit() {
		var bsModalRef = this.bsModalService.show(ProfileFormComponent, {
            initialState: {
                originalExtendedUser: this.extendedUser,
                schedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'xlModal'
        });

        bsModalRef.content.action.subscribe(result => {
	    	this.refresh();
		});
	}

	initialize() {
		this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();
	  	this.user = this.environmentService.getUser();

		this.http.get("allocation_request_types",
		{ 
			params: {
				schedule_period_id: this.selectedSchedulePeriod.id,
				for_notification_selection: 'true'
			}
		}
		).subscribe(x => {
			this.allocationRequestTypesForNotificationSelection = x;
		});

		this.refresh();
	}

	ngOnInit() {
		this.isLoading = true;

        this.environmentService.selectedSchedulePeriodChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.initialize();
            });

		this.http.get("task_variant_groups").subscribe(x => {
			this.taskVariantGroups = x;
		});

        this.initialize();
    }

    ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
    }
}
