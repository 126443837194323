import { NgModule } from '@angular/core';
import { NameFilterPipe } from './nameFilter.pipe';
import { SafePipe } from './safe.pipe';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
    declarations: [
      TruncatePipe,
      NameFilterPipe,
      SafePipe
    ],
    exports: [
      TruncatePipe,
      NameFilterPipe,
      SafePipe
    ],
})
export class PipesModule{}