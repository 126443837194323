import * as moment from 'moment';

export class RecurringPeriod {
	public index;
	public start_day_of_week;
	public end_day_of_week;
	public location;
	public is_optional;
	public is_cancelled;
	
	constructor(obj) {
		this.index = obj.index;
		this.start_day_of_week = obj.start_day_of_week;
		this.end_day_of_week = obj.end_day_of_week;
		this.location = obj.location;
		this.is_optional = obj.is_optional;
		this.is_cancelled = obj.is_cancelled;
	}
	
  formattedTaskDays(task) {
		var self = this;
		
		var taskDayDescriptions = [];
	
		task.task_days.filter(function(taskDay) { return self.start_day_of_week <= self.end_day_of_week ? 
			taskDay.day_of_week >= self.start_day_of_week && taskDay.day_of_week <= self.end_day_of_week :
			!(taskDay.day_of_week > self.end_day_of_week && taskDay.day_of_week < self.start_day_of_week) }
		).forEach(function(taskDay) {
			if (taskDay.is_all_day) {
				taskDayDescriptions.push("Hele dag");
			} else {
				taskDayDescriptions.push(moment(taskDay.start_time).format("HH:mm") + "-" + moment(taskDay.end_time).format("HH:mm"));
			}
		});
	
		return taskDayDescriptions.filter(function(value, index, self2) { return self2.indexOf(value) === index; }).join(", ");
	}	
}