import { Component, OnInit } from '@angular/core';
import templateString from './home.component.html';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvironmentService } from './../environment.service';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'app',
	template: templateString
})
export class HomeComponent implements OnInit {
	public currentSection = 'pagetop';
	public hasScrolledDown = false;
	public menuUncollapsed = false;
	public pageId: any;
	public featureIndex: any; 
	public contactType;
	public submitted: any;
	public contactContents: any;
	public contactMailSent = false;
	public intervalTimer: any;
	public featureIds: any;
	public articleGroups: any = [];
	public article: any;

	// $scope.user = EnvironmentService.getUser();
	// if ($scope.user != undefined) {
	// 	$state.go("app.schedule");
	// }
	
	constructor(
		private router: Router,
		private environmentService: EnvironmentService,
		private route: ActivatedRoute,
		private http: HttpClient
	) { }

	toggleMenuCollapsed() {
		this.menuUncollapsed = !this.menuUncollapsed;
	}

	switchFeatureAndKillIntervalTimer(featureIndex) {
		clearInterval(this.intervalTimer);
		this.switchFeature(featureIndex);
	}

	switchFeature(featureIndex) {
		this.featureIndex = featureIndex;
	}

	ok(isValid) {
		this.submitted = true;
	
		if (isValid) {
			this.http.post('other_users/send_contact_mail',
                { contact_contents: this.contactContents }
            ).subscribe(result => {
                this.contactMailSent = true;
            });		
		}
	}

	showArticle(article) {
		this.article = article;
	}

	initialize() {
		var self = this;

		// if (this.environmentService.user != null) {
		// 	this.router.navigate(["/app/daySchedule"]);
		// } else if (this.environmentService.getUser() != null) {
		// 	// toastr.error("Uw sessie is verlopen. Log opnieuw in.");
		// 	this.router.navigate(["/login"]);
		// }
		var user = this.environmentService.getUser();

		if (user != null) {
			if (user.customer.customer_type_id == 2) {
				this.router.navigate(["/app/trainingSchedule"]);
			} else {
				this.router.navigate(["/app/daySchedule"]);
			}	
		} else {
			this.featureIds;

			if (this.pageId.startsWith("/contact")) {
				this.submitted = false;
	
				this.contactContents = {
					contactType: this.route.snapshot.queryParams["contactType"] ? this.route.snapshot.queryParams["contactType"] : "question",
					product: this.route.snapshot.queryParams["product"] ? this.route.snapshot.queryParams["product"] : ""
				};
			} else if (this.pageId == '/products?p=dagplanner-medisch-specialisten') {
				this.featureIds = [1, 21, 3, 4, 5, 6, 7, 8, 81, 9, 91, 12, 13, 14, 15, 16, 17, 18, 19];
			} else if (this.pageId == '/products?p=dagplanner-arts-assistenten') {
				this.featureIds = [1, 2, 3, 4, 5, 6, 7, 8, 81, 9, 91, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
			} else if (this.pageId == '/products?p=regionale-opleidingsplanner') {
				this.featureIds = [101, 102, 103, 104, 105, 106, 107, 108];
			}

			if (this.featureIds) {
				var i = 0; 

				this.featureIndex = this.featureIds[i++];

				if (this.intervalTimer) {
					clearInterval(this.intervalTimer);
				}

				this.intervalTimer = setInterval(() => {
					self.switchFeature(self.featureIds[i++ % self.featureIds.length]);
				}, 4000);
			}
			
		}
	}

	ngOnInit() {
		var self = this;

	    this.route.queryParams.subscribe(
            params => {
                this.pageId = this.router.url;
                this.initialize();
            }
        );

		this.http.get<any>("articles").subscribe(x => {
			var matchingArticles = x.filter(function(y) { return y.seo_id == self.route.snapshot.params["seoId"]; })

			if (matchingArticles.length > 0) {
				this.article = matchingArticles[0];
			} else {
				this.article = null;
			}

			for(var i=0; i < x.length; i++) {
				if (i+1 < x.length) {
					this.articleGroups.push([x[i], x[i+1]]);
					i++;
				} else {
					this.articleGroups.push([x[i]]);
				}
			}
        });
	}

	ngOnDestroy() {
		if (this.intervalTimer) {
			clearInterval(this.intervalTimer);
		}
	}
}