import { Candidate } from './candidate.model';
import { RecurringPeriod } from './recurringPeriod.model';
import * as moment from 'moment';

export class Task {
	public id: number;
	public name: string;
	public alias: string;
	public computedName: string;
	public periodic_candidate_id: number;
	public schedule_period_id: number;
	public task_variant_group;
	public periodic_candidate;
	public recurring_periods: RecurringPeriod[];
	public is_opened_when_public_holiday: boolean;
	public is_closed_when_public_holiday: boolean;
	public public_holiday_is_all_day;
	public public_holiday_start_time;
	public public_holiday_end_time;
	public task_type;
	public task_days;
	public task_display_group_id;
	public task_display_group;
	public is_included_in_main_schedule;
	public task_allowed_tasks;
	public task_allowed_task_types;
	public task_conflict_exceptions;
	public is_side_activity: boolean;
	public has_selection_of_allowed_tasks: boolean;
	public day_schedule_tab_ids: any;
	public has_continuous_periods: any;
	public continuous_periods_start_date: any;
	public continuous_period_length: any;
	public continuous_period_interval_length: any;
	
	constructor(obj) {
		this.id = obj.id;
		this.name = obj.name;
		this.alias = obj.alias;		
		this.computedName = obj.computedName;
		this.periodic_candidate_id = obj.periodic_candidate_id;
		this.schedule_period_id = obj.schedule_period_id;
		this.task_variant_group = obj.task_variant_group;
		this.periodic_candidate = obj.periodic_candidate;
		this.recurring_periods = obj.recurring_periods;
		this.is_opened_when_public_holiday = obj.is_opened_when_public_holiday;
		this.is_closed_when_public_holiday = obj.is_closed_when_public_holiday;
		this.public_holiday_is_all_day = obj.public_holiday_is_all_day;
		this.public_holiday_start_time = obj.public_holiday_start_time;
		this.public_holiday_end_time = obj.public_holiday_end_time;
		this.task_type = obj.task_type;
		this.task_days = obj.task_days;
		this.task_display_group_id = obj.task_display_group_id;
		this.task_display_group = obj.task_display_group;
		this.is_included_in_main_schedule = obj.is_included_in_main_schedule;
		this.task_allowed_tasks = obj.task_allowed_tasks;
		this.task_allowed_task_types = obj.task_allowed_task_types;
		this.task_conflict_exceptions = obj.task_conflict_exceptions;
		this.is_side_activity = obj.is_side_activity;
		this.has_selection_of_allowed_tasks = obj.has_selection_of_allowed_tasks;
		this.day_schedule_tab_ids = obj.day_schedule_tab_ids;
		this.has_continuous_periods = obj.has_continuous_periods;
		this.continuous_periods_start_date = obj.continuous_periods_start_date;
		this.continuous_period_length = obj.continuous_period_length;
		this.continuous_period_interval_length = obj.continuous_period_interval_length;
	}
	
  	getComputedName() {
		return this.periodic_candidate != null && this.periodic_candidate != undefined ? (new Candidate(this.periodic_candidate.candidate)).lastnameWithInfix() + " " + this.name : this.name;
  	};

	formattedRecurringPeriods() {
		var self = this;
		
		var periods = [];

		if (this.has_continuous_periods) {
			periods = [
				"Doorlopend",
				"Start: " + moment(this.continuous_periods_start_date).format('D MMM YYYY'),
				"Duur: " + this.continuous_period_length.toString() + " dagen",
				"Interval: " + this.continuous_period_interval_length.toString() + " dagen"
			];
		} else {
			var dayNamesAbbreviations = [null, "ma", "di", "wo", "do", "vr", "za", "zo"];
			
			this.recurring_periods.forEach(function(recurringPeriod) {
				var periodDescription = "";
				
				if (recurringPeriod.start_day_of_week === recurringPeriod.end_day_of_week) {
					periodDescription += dayNamesAbbreviations[recurringPeriod.start_day_of_week];
				} else {
					periodDescription += dayNamesAbbreviations[recurringPeriod.start_day_of_week] + " t/m " + dayNamesAbbreviations[recurringPeriod.end_day_of_week];
				}
				
				var properties = [];
	
				properties.push((new RecurringPeriod(recurringPeriod)).formattedTaskDays(self));
	
				if (recurringPeriod.location != null) {
					properties.push(recurringPeriod.location.alias);
				}
				
				if (recurringPeriod.is_optional) {
					properties.push("opt");
				}
				
				if (recurringPeriod.is_cancelled) {
					properties.push("geann");
				}
	
				if (properties.length > 0) {
					periodDescription += " (" + properties.join(", ")  + ")";
				}
				
				periods.push(periodDescription);
			});
	
			if (self.is_opened_when_public_holiday) {
				var publicHolidayDescription = "";
	
				if (self.public_holiday_is_all_day) {
					publicHolidayDescription = "Hele dag";
				} else {
					publicHolidayDescription = moment(self.public_holiday_start_time).format("HH:mm") + "-" + moment(self.public_holiday_end_time).format("HH:mm");
				}
				
				periods.push("feestdag (" + publicHolidayDescription + ")");
			}
		}

		return periods;
 	};	
}