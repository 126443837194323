import { Component, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './privacyStatement.component.html'
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({ template: templateString })
export class PrivacyStatementComponent {
	@Output() action = new EventEmitter();
	
	constructor(
		private bsModalRef: BsModalRef,
		private renderer: Renderer2
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	ok() {
		this.action.emit(true);
		this.bsModalRef.hide();
	}

  	dismiss() {
		this.bsModalRef.hide();
  	}

	cancel() {
		this.bsModalRef.hide();
	}
}