import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './candidateAllocationRequestBudgetComponentForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Candidate } from 'site/app/models/candidate.model';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class CandidateAllocationRequestBudgetComponentFormComponent implements OnInit {
    @Output() action = new EventEmitter();

	public allocationRequestAccumulationPeriods: any;
	public candidates: any;

	public isLoading: boolean;
	public horizontalScrollReceiver1: any;
	public verticalScrollReceiver1: any;
	public allocationRequestBudgetComponentsLength: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
		private environmentService: EnvironmentService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}
	
	ok() {
 		this.isLoading = true;

		this.http.post("candidate_allocation_request_budget_components/update_bulk",
			{ candidates: this.candidates }
		).subscribe(() => {
			this.isLoading = false;
			this.action.emit(true);
			this.bsModalRef.hide();
		});
	}

  	cancel() {
		this.bsModalRef.hide();
	}

	syncScroll(event) {
		this.horizontalScrollReceiver1.scrollLeft = event.scrollLeft;
		this.verticalScrollReceiver1.scrollTop = event.scrollTop;
	}

    ngOnInit() {
		var allocationRequestBudgetComponentsLength = 0;

		this.allocationRequestAccumulationPeriods.forEach(x => {
			allocationRequestBudgetComponentsLength += x.allocation_request_budget_components.length;
		});

		this.allocationRequestBudgetComponentsLength = allocationRequestBudgetComponentsLength;		 
	}
	
	ngAfterViewInit() {
		this.horizontalScrollReceiver1 = document.querySelectorAll('.myHorizontalScrollReceiver')[0];
		this.verticalScrollReceiver1 = document.querySelectorAll('.myVerticalScrollReceiver')[0];
	}
}
