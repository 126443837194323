import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  	selector: '[myscrollsender]'
})
export class MyScrollSenderDirective {
	private element;
	private scrollTop;
	private scrollLeft;
	
	@Output() propagateScroll = new EventEmitter();
	
	@HostListener('scroll', ['$event']) onScroll(event) {
		this.scrollTop = this.element.nativeElement.scrollTop;
		this.scrollLeft = this.element.nativeElement.scrollLeft;

		this.propagateScroll.emit({ scrollTop: this.scrollTop, scrollLeft: this.scrollLeft });
	}
	
  	constructor(private el: ElementRef) {
		this.element = el;
 	}
}