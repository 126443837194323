export class TaskAccumulationTarget {
	public task_accumulation_period_id;
	public task_accumulation_period;
	public is_absolute;
	public relative_value;
	
	constructor(obj) {
		this.task_accumulation_period_id = obj.task_accumulation_period_id;
		this.task_accumulation_period = obj.task_accumulation_period;
		this.is_absolute = obj.is_absolute;
		this.relative_value = obj.relative_value;
	}
} 
