import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './schedulePeriodForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { SchedulePeriod } from 'site/app/models/schedulePeriod.model';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class SchedulePeriodFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public schedulePeriodId: any;
	
	public schedulePeriod: any;
	public activeDateStart;
	public activeDateEnd;
	public displayDateStart;
	public displayDateEnd;
	public datePickerConfig: { firstDayOfWeek: string; };
	public actionTitle: string;
	public submitted: boolean;
	public formerIsDefault;
	public user;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
        private toastr: ToastrService,
		private environmentService: EnvironmentService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}
	
	changeStartDate() {
		this.schedulePeriod.formattedStartDate = moment(this.activeDateStart).format("DD-MM-YYYY");
	}
	
	changeEndDate() {
		this.schedulePeriod.formattedEndDate = moment(this.activeDateEnd).format("DD-MM-YYYY");
	}
	
	initializePeriod() {
		this.activeDateStart = moment(this.schedulePeriod.start_date);
		this.activeDateEnd = moment(this.schedulePeriod.end_date).subtract(1, 'day');
		
		this.displayDateStart = this.activeDateStart;
		this.displayDateEnd = this.activeDateEnd;

		this.changeStartDate();
		this.changeEndDate();
	}

	ok(isValid) {
		this.submitted = true;

		if (isValid && this.schedulePeriod.task_display_groups.filter(function(x) { return x.hasPublishedStartDate && x.hasPublishedEndDate && x.tempPublishedStartDate > x.tempPublishedEndDate }).length == 0) {
			var startDate = moment(this.activeDateStart);
			var endDate = moment(this.activeDateEnd).add(1, 'day');
			startDate.locale('en');
			endDate.locale('en');
			this.schedulePeriod.start_date = startDate.format("ddd D MMM YYYY");
			this.schedulePeriod.end_date = endDate.format("ddd D MMM YYYY");

			this.schedulePeriod.task_display_groups.forEach(taskDisplayGroup => {
				if (taskDisplayGroup.hasPublishedStartDate) {
					var publishedStartDate = moment(taskDisplayGroup.tempPublishedStartDate);
					publishedStartDate.locale('en');
					taskDisplayGroup.published_start_date = publishedStartDate.format("ddd D MMM YYYY");
				} else {
					taskDisplayGroup.published_start_date = null;
				}
				
				if (taskDisplayGroup.hasPublishedEndDate) {
					var publishedEndDate = moment(taskDisplayGroup.tempPublishedEndDate).add(1, 'day');
					publishedEndDate.locale('en');
					taskDisplayGroup.published_end_date = publishedEndDate.format("ddd D MMM YYYY");
				} else {
					taskDisplayGroup.published_end_date = null;
				}
			});

			this.schedulePeriod.candidate_type_schedule_periods.forEach(candidateTypeSchedulePeriod => {
				if (candidateTypeSchedulePeriod.hasPublishedStartDate) {
					var publishedStartDate = moment(candidateTypeSchedulePeriod.tempPublishedStartDate);
					publishedStartDate.locale('en');
					candidateTypeSchedulePeriod.published_start_date = publishedStartDate.format("ddd D MMM YYYY");
				} else {
					candidateTypeSchedulePeriod.published_start_date = null;
				}
				
				if (candidateTypeSchedulePeriod.hasPublishedEndDate) {
					var publishedEndDate = moment(candidateTypeSchedulePeriod.tempPublishedEndDate).add(1, 'day');
					publishedEndDate.locale('en');
					candidateTypeSchedulePeriod.published_end_date = publishedEndDate.format("ddd D MMM YYYY");
				} else {
					candidateTypeSchedulePeriod.published_end_date = null;
				}
			});

			if (this.schedulePeriodId === null) {
				this.http.post("schedule_periods", this.schedulePeriod).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.error.error);
				});
			} else {
				this.http.put("schedule_periods/" + this.schedulePeriod.id, this.schedulePeriod).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.error.error);
				});
			}
		}
	}

  	cancel() {
    	this.bsModalRef.hide();
	}

    ngOnInit() {
		this.user = this.environmentService.getUser();

		this.datePickerConfig = {
			firstDayOfWeek: "mo"
		};

		var now = new Date();
		var tomorrow = new Date();
		tomorrow.setDate(now.getDate() + 1);
	
		if (this.schedulePeriodId === null) {
			this.actionTitle = "toevoegen";
			
			this.schedulePeriod = new SchedulePeriod();
			
			this.schedulePeriod.start_date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			this.schedulePeriod.end_date = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate());
			this.schedulePeriod.task_display_groups = [];
			this.schedulePeriod.candidate_type_schedule_periods = [];

			this.initializePeriod();
			
		} else {
			this.actionTitle = "wijzigen";
			
			// TODO remove this, now necessary to avoid 'cannot ... getFullYear() of undefined', prob takes too long to give activeDateStart a value
			this.activeDateStart = now;
			this.activeDateEnd = tomorrow;
			
			this.http.get("schedule_periods/" + this.schedulePeriodId).subscribe(x => {
				this.schedulePeriod = x;
				this.formerIsDefault = this.schedulePeriod.is_default;
				
				this.schedulePeriod.task_display_groups.forEach(taskDisplayGroup => {
					if (taskDisplayGroup.published_start_date == null) {
						taskDisplayGroup.hasPublishedStartDate = false;
					} else {
						taskDisplayGroup.hasPublishedStartDate = true;
						taskDisplayGroup.tempPublishedStartDate = moment(taskDisplayGroup.published_start_date);
					}

					if (taskDisplayGroup.published_end_date == null) {
						taskDisplayGroup.hasPublishedEndDate = false;
					} else {
						taskDisplayGroup.hasPublishedEndDate = true;
						taskDisplayGroup.tempPublishedEndDate = moment(taskDisplayGroup.published_end_date).subtract(1, 'day');
					}
				});

				this.schedulePeriod.candidate_type_schedule_periods.forEach(candidateTypeSchedulePeriod => {
					if (candidateTypeSchedulePeriod.published_start_date == null) {
						candidateTypeSchedulePeriod.hasPublishedStartDate = false;
					} else {
						candidateTypeSchedulePeriod.hasPublishedStartDate = true;
						candidateTypeSchedulePeriod.tempPublishedStartDate = moment(candidateTypeSchedulePeriod.published_start_date);
					}

					if (candidateTypeSchedulePeriod.published_end_date == null) {
						candidateTypeSchedulePeriod.hasPublishedEndDate = false;
					} else {
						candidateTypeSchedulePeriod.hasPublishedEndDate = true;
						candidateTypeSchedulePeriod.tempPublishedEndDate = moment(candidateTypeSchedulePeriod.published_end_date).subtract(1, 'day');
					}
				});
	
				this.initializePeriod();
			});
		}
    }
}
