import { Component, OnInit } from '@angular/core';
import templateString from './taskConflictExceptions.component.html';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TaskConflictExceptionFormComponent } from '../taskConflictExceptionForm';
import { EnvironmentService } from 'site/app/environment.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TaskConflictExceptionsComponent implements OnInit {
    public destroyed$: any;
    public isLoading: boolean;
    public tasks: any;
    public isSideActivity: any;
    public taskConflictExceptionEntries: any;
    public selectedSchedulePeriod: any;

    constructor(
        private http: HttpClient,
        private bsModalService: BsModalService,
        private environmentService: EnvironmentService
	) { 
        this.destroyed$ = new Subject<boolean>();
    }

	refresh() {
        var self = this;
        this.isLoading = true;

        this.http.get<any>("tasks", { params: { schedule_period_id: this.selectedSchedulePeriod.id } }).subscribe(tasks => {
            this.tasks = this.isSideActivity ? 
				tasks.filter(function(x) { return !x.is_non_working && (x.is_side_activity || x.task_type.is_side_activity); }) :
				tasks.filter(function(x) { return !x.is_non_working && !x.is_side_activity && !x.task_type.is_side_activity; });
			
			this.tasks.forEach(function(task) {
				task.nameWithEquivalencePosition = task.name + (task.equivalence_position == null ? "" : " (" + task.equivalence_position + ")");
			});

            this.http.get<any>("task_conflict_exceptions", { params: { schedule_period_id: this.selectedSchedulePeriod.id } }).subscribe(x => {
                this.taskConflictExceptionEntries = x.task_conflict_exception_entries;

				this.isLoading = false;
            });
        });
    }

    edit() {
        var bsModalRef = this.bsModalService.show(TaskConflictExceptionFormComponent, {
            initialState: {
                schedulePeriodId: this.selectedSchedulePeriod.id,
                isSideActivity: this.isSideActivity
            },
            class: 'xlModal'
        });

        bsModalRef.content.action.subscribe(result => {
			this.refresh();
        });
    }

	initialize() {
        this.isLoading = true;
		this.isSideActivity = false;
		this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();
		
        this.taskConflictExceptionEntries = null;

		this.refresh();
 	}
		
    ngOnInit() {
        this.environmentService.selectedSchedulePeriodChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.initialize();
            });

        this.initialize();
    }

    ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
    }
}
