import { NgModule } from "@angular/core";
import { TrainingScheduleComponent } from "./trainingSchedule";
import { BrowserModule } from "@angular/platform-browser";
import { appRoutingModule } from "../app.routing";
import { FormsModule } from "@angular/forms";
import { ModalModule } from "ngx-bootstrap/modal";
import { PipesModule } from "../pipes";
import { DirectivesModule } from "../directives";
import { TrainingPeriodFormComponent } from "./trainingPeriodForm";
import { DpDatePickerModule } from 'ng2-date-picker';
import { CanDeactivateGuardTraining } from "./canDeactivateGuardTraining";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ResizableModule } from "angular-resizable-element";
import { TrainingScheduleVersionFormComponent } from "./trainingScheduleVersionForm";
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CandidateViolationsComponent } from "./candidateViolations";
import { TrainingScheduleVersionEvaluationFormComponent } from "./trainingScheduleVersionEvaluationForm";

@NgModule({
  	declarations: [
		TrainingScheduleComponent,
		TrainingPeriodFormComponent,
		TrainingScheduleVersionFormComponent,
		CandidateViolationsComponent,
		TrainingScheduleVersionEvaluationFormComponent
	],
  	imports: [
		BrowserModule,
		appRoutingModule,
		FormsModule,
		DirectivesModule,
		ModalModule.forRoot(),
		PipesModule,
		DpDatePickerModule,
		DragDropModule,
		ResizableModule,
		NgxChartsModule
	],
  	providers: [
		CanDeactivateGuardTraining
	],
	entryComponents: [
		TrainingPeriodFormComponent,
		TrainingScheduleVersionFormComponent,
		CandidateViolationsComponent,
		TrainingScheduleVersionEvaluationFormComponent
	]
})
export class TrainingScheduleModule {}
