export class TrainingPeriodAccumulationTypeTarget {
	public training_period_accumulation_type_id;
	public candidate_id;
	public value;
	
	constructor(obj) {
		this.training_period_accumulation_type_id = obj.training_period_accumulation_type_id;
		this.candidate_id = obj.candidate_id;
		this.value = obj.value;
	}
} 
