import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './taskAccumulationTargetForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Candidate } from 'site/app/models/candidate.model';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TaskAccumulationTargetFormComponent implements OnInit {
    @Output() action = new EventEmitter();

	public schedulePeriodId: any;

	public taskAccumulationPeriods: any;
	public accumulationPeriod: any;
	public isLoading: boolean;
	public candidatesForShiftCountOverview: any;
	public horizontalScrollReceiver1: any;
	public verticalScrollReceiver1: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
		private environmentService: EnvironmentService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}
	
	ok() {
 		this.isLoading = true;
		
 		var taskAccumulationTargets = [];
		
		this.candidatesForShiftCountOverview.forEach(function(candidate) {
			Object.keys(candidate.task_accumulation_targets_dictionary).forEach(function(taskAccumulationPeriodId) {
				taskAccumulationTargets.push(candidate.task_accumulation_targets_dictionary[taskAccumulationPeriodId]);
			});
		});
		
		taskAccumulationTargets.forEach(function(x) {
			if (x.is_absolute) {
				x.relative_value = null;
			} else {
				x.relative_value = x.percentage / 100;
				x.absolute_value = null;
			}
		});
		
		this.http.post("task_accumulation_targets/update_bulk",
			{ task_accumulation_targets: taskAccumulationTargets }
		).subscribe(() => {
			this.isLoading = false;
			this.action.emit(true);
			this.bsModalRef.hide();
		});
	}

  	cancel() {
		this.bsModalRef.hide();
	}

	syncScroll(event) {
		this.horizontalScrollReceiver1.scrollLeft = event.scrollLeft;
		this.verticalScrollReceiver1.scrollTop = event.scrollTop;
	}

    ngOnInit() {
		this.isLoading = true;
	
		this.http.get("accumulation_periods/get_by_schedule_period_id", { params: { schedule_period_id: this.schedulePeriodId } }).subscribe(x => {
            this.accumulationPeriod = x;
        });
		
		this.http.get("task_accumulation_periods/index_for_shift_count_overview", 
            { params: { schedule_period_id: this.schedulePeriodId } }
        ).subscribe(x => {
			this.taskAccumulationPeriods = x;
			
			if (this.taskAccumulationPeriods.length > 0) {
                this.http.post("candidates/index_for_shift_count_overview",
                    { 
                        schedule_period_id: this.schedulePeriodId, 
                        filter_by_location: 'true',
                        filter_by_has_targets: 'true'
                    }
                ).subscribe(candidates => {
					this.candidatesForShiftCountOverview = candidates;
					
					this.candidatesForShiftCountOverview.forEach(function(candidate) {
						candidate.lastnameWithInfix = (new Candidate(candidate)).lastnameWithInfix();

						Object.keys(candidate.task_accumulation_targets_dictionary).forEach(function(taskAccumulationPeriodId) {
							if (!candidate.task_accumulation_targets_dictionary[taskAccumulationPeriodId].isAbsolute) {
								candidate.task_accumulation_targets_dictionary[taskAccumulationPeriodId].percentage = 
									candidate.task_accumulation_targets_dictionary[taskAccumulationPeriodId].relative_value * 100;
							}	
						});
					});
					
					this.isLoading = false;
				});
			} else {
				this.candidatesForShiftCountOverview = [];
				this.isLoading = false;
			}
		});
	}
	
	ngAfterViewInit() {
		this.horizontalScrollReceiver1 = document.querySelectorAll('.myHorizontalScrollReceiver')[0];
		this.verticalScrollReceiver1 = document.querySelectorAll('.myVerticalScrollReceiver')[0];
	}
}
