export class AllocationRequestType {
	public is_allocate_preference;
	public is_period_containing_tasks;
	public allocation_request_type_excepted_task_types;

	constructor(obj) {
		this.is_allocate_preference = obj.is_allocate_preference;
		this.is_period_containing_tasks = obj.is_period_containing_tasks;
		this.allocation_request_type_excepted_task_types = obj.allocation_request_type_excepted_task_types;
	}
} 
