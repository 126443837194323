export class CandidateUnavailabilityPeriod {
	public index;
	public start_date;
	public end_date;
	
	constructor(obj) {
		this.index = obj.index;
		this.start_date = obj.start_date;
		this.end_date = obj.end_date;
	}
} 
