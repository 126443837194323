import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { TrainingScheduleComponent } from "./trainingSchedule";

@Injectable()
export class CanDeactivateGuardTraining implements CanDeactivate<TrainingScheduleComponent> {
  canDeactivate(component: TrainingScheduleComponent): boolean {
    // when logged out, component becomes null, so avoid crash by checking for != null
    if(component != null && component.hasUnsavedData()){
        if (confirm("Er zijn wijzigingen die nog niet zijn opgeslagen. Weet je zeker dat je deze pagina wilt verlaten?")) {
            return true;
        } else {
            return false;
        }
    }
    return true;
  }
}