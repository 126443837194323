import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './taskVariantGroupForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TaskVariantGroup } from 'site/app/models/taskVariantGroup.model';
import { TaskVariant } from 'site/app/models/taskVariant.model';
import { DataService } from 'site/app/data.service';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TaskVariantGroupFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public taskVariantGroupId: any;
	public schedulePeriodId: any;

	public taskVariantGroup: any;
	public submitted: boolean;	
	public actionTitle: string;
	public tasks: any;
	public isLoading: boolean;
	public taskVariantIndex: number;
	public colors: any;
	public candidateTypes: any;
	public user: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
		private toastr: ToastrService,
		private dataService: DataService,
		private environmentService: EnvironmentService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	deleteTaskVariant(index) {
		var matchIndex = -1;
		this.taskVariantGroup.task_variants.some(function(el, i) {
			if (el.index == index) {
				matchIndex = i;
				return true;
			}
		});
		
		this.taskVariantGroup.task_variants.splice(matchIndex, 1);;
	}
	
	addTaskVariant() {
		this.taskVariantGroup.task_variants.push(new TaskVariant({ index: this.taskVariantIndex++ }));
	}

	deleteCandidateTypeId(index) {
		this.taskVariantGroup.candidate_type_ids.splice(index, 1);;
	}
	
	addCandidateTypeId() {
		this.taskVariantGroup.candidate_type_ids.push(null);
	}

  	ok(isValid) {
		this.submitted = true;
		
		if (isValid) {
			if (this.taskVariantGroupId === null) {
				this.http.post("task_variant_groups", { task_variant_group: this.taskVariantGroup }).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.error.error);
				});
			} else {
				this.http.put("task_variant_groups/" + this.taskVariantGroup.id, { task_variant_group: this.taskVariantGroup }).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.error.error);
				});
			}
		}
  	}

  	cancel() {
		this.bsModalRef.hide();
  	}
	
	trackByFunction(index) {
		return index;
	}

    ngOnInit() {
		this.user = this.environmentService.getUser();

		if (this.user.customer.id == 27) {
			this.colors = this.dataService.colors.concat(["white", "white", "white", "rgba(255, 255, 0, 0.5)"]);
		} else {
			this.colors = this.dataService.colors;
		}

		this.http.get("candidate_types").subscribe(candidateTypes => {
			this.candidateTypes = candidateTypes;
		});

		if (this.taskVariantGroupId === null) {
			this.actionTitle = "toevoegen";
			this.taskVariantGroup = new TaskVariantGroup();
			this.taskVariantGroup.task_variants = [];
			this.taskVariantIndex = 0;
			this.taskVariantGroup.candidate_type_ids = [];
		} else {
			this.actionTitle = "wijzigen";
			this.http.get<any>("task_variant_groups/" + this.taskVariantGroupId).subscribe(x => {
				this.taskVariantGroup = x;
				
				this.taskVariantGroup.task_variants.forEach(function(taskVariant, index) {
					taskVariant.index = index;
				});
				
				this.taskVariantIndex = x.task_variants.length;

				this.taskVariantGroup.candidate_type_ids = this.taskVariantGroup.candidate_types.map(function(y) { return y.id; });
			});
		}
    }
}
