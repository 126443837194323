import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { appRoutingModule } from "../app.routing";
import { FormsModule } from "@angular/forms";
import { ModalModule } from "ngx-bootstrap/modal";
import { PipesModule } from "../pipes";
import { DirectivesModule } from "../directives";
import { DpDatePickerModule } from 'ng2-date-picker';
import { SelectRangeForPrintComponent } from "./selectRangeForPrint";
import { AgendaSyncLinkComponent } from "./agendaSyncLink";
import { AgendaComponent } from "./agenda";

@NgModule({
  	declarations: [
		AgendaComponent,
		SelectRangeForPrintComponent,
		AgendaSyncLinkComponent
	],
  	imports: [
		BrowserModule,
		appRoutingModule,
		FormsModule,
		DirectivesModule,
		ModalModule.forRoot(),
		PipesModule,
		DpDatePickerModule
	],
  	providers: [
	],
	entryComponents: [
		SelectRangeForPrintComponent,
		AgendaSyncLinkComponent
	]
})
export class AgendaModule {}
