import { Component, OnInit, EventEmitter, Output, Renderer2 } from '@angular/core';
import templateString from './taskClone.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Task } from 'site/app/models/task.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TaskCloneComponent implements OnInit {
    @Output() action = new EventEmitter();

    public currentSchedulePeriodId: any;
    public tasksCurrentSchedulePeriod: any;

    public isLoading;
    public checkAll: boolean;
    public schedulePeriods: any;
    public selection: any = {};
    public tasks: any;
    
    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2
    ) { 
        this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
    }

	refresh() {
        var self = this;
        this.http.get<any>("tasks", { params: { schedule_period_id: this.selection.schedulePeriodId } }).subscribe((x) => {
        	this.tasks = x.map(function(y) { return new Task(y) });
			this.tasks.forEach(function(task) {
                task.computedName = (new Task(task)).getComputedName();
				task.alreadyExistsInCurrentSchedule = self.tasksCurrentSchedulePeriod.filter(function(x) { return (new Task(x)).getComputedName() == task.computedName; }).length > 0;
                task.checkForClone = task.alreadyExistsInCurrentSchedule;                
            });
            
			this.isLoading = false;
		});
	}
	
	changeSchedulePeriod() {
		this.isLoading = true;
		this.refresh();
	}	
	
	checkAllForClone() {
        var self = this;
		this.tasks.filter(function(x) { return !x.alreadyExistsInCurrentSchedule; }).forEach(function(task) {
			task.checkForClone = self.checkAll;
		});
	}	

    ok() {
        this.isLoading = true;
        this.http.post("tasks/clone", { 
			current_schedule_period_id: this.currentSchedulePeriodId, 
			task_ids: this.tasks
				.filter(function(x) { return x.checkForClone && !x.alreadyExistsInCurrentSchedule; })
				.map(function(x) { return x.id; })
        }).subscribe(() => {
            this.isLoading = false;

            this.action.emit(true);
			this.bsModalRef.hide();
		});
    };

    cancel() {
        this.bsModalRef.hide();
    };

    ngOnInit() {
        this.isLoading = true;
        this.checkAll = false;
        
        this.http.get("schedule_periods").subscribe((schedulePeriods) => {
            this.schedulePeriods = schedulePeriods;
            
            var currentSchedulePeriodIndex = this.schedulePeriods.map(function(x) { return x.id }).indexOf(this.currentSchedulePeriodId);
            
            // get previous schedule period
            if (currentSchedulePeriodIndex > 0) {
                this.selection = { schedulePeriodId: this.schedulePeriods[currentSchedulePeriodIndex-1].id };
                
                this.refresh();
            } else {
                this.isLoading = false;
            }
        })
    }
}


