import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser';
import { appRoutingModule } from './../app.routing';
import { DayScheduleComponent } from './daySchedule';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from './../directives';
import { PipesModule } from './../pipes';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AssignTaskModalComponent } from './assignTaskModal';
import { SelectCustomerComponent } from '../authentication/selectCustomer';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

@NgModule({
  	declarations: [
    	DayScheduleComponent,
		AssignTaskModalComponent,
		SelectCustomerComponent
	],
  	imports: [
		BrowserModule,
		appRoutingModule,
		FormsModule,
		DirectivesModule,
		ModalModule.forRoot(),
		PipesModule,
		TimepickerModule.forRoot()
	],
  	providers: [
		TruncatePipe
	],
	entryComponents: [
		AssignTaskModalComponent,
		SelectCustomerComponent
	]
})
export class DayScheduleModule {}
