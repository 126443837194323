import { Component } from '@angular/core';
import templateString from './forgotPassword.component.html';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class ForgotPasswordComponent {
    public submitted = false;
    public email;

    constructor(
        private http: HttpClient,
        private router: Router,
        private toastr: ToastrService
	) {	}

    sendPasswordResetMail(isValid) {
        this.submitted = true;
        
        if (isValid) {
            this.http.post<any>('other_users/send_password_reset_mail',
                { email: this.email }
            ).subscribe(result => {
                if (result.user_exists) {
                    this.toastr.success("Mail om uw wachtwoord te herstellen is verstuurd");
                } else {
                    this.toastr.error("Emailadres is niet bekend");
                }
    
                this.router.navigate(["/login"]);
            });
        }
    }
}
