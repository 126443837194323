import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './reportForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Report } from 'site/app/models/report.model';
import { ReportPart } from 'site/app/models/reportPart.model';
import { ReportPartAllocationRequestAccumulationType } from 'site/app/models/reportPartAllocationRequestAccumulationType.model';
import { ReportPartTaskAccumulationType } from 'site/app/models/reportPartTaskAccumulationType.model';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class ReportFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public reportId: any;
	public schedulePeriodId: any;

	public report: any;
	public submitted: boolean;	
	public actionTitle: string;
	public isLoading: boolean;
	public reportPartIndex: number;
	public reportPartTypes: { id: number; name: string; }[];
	public allocationRequestAccumulationTypes: any;
	public taskAccumulationTypes: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
		private toastr: ToastrService,
		private environmentService: EnvironmentService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	deleteReportPart(index) {
		var matchIndex = -1;
		this.report.report_parts.some(function(el, i) {
			if (el.index == index) {
				matchIndex = i;
				return true;
			}
		});
		
		this.report.report_parts.splice(matchIndex, 1);;
	}
	
	addReportPart() {
		this.report.report_parts.push(new ReportPart(
			{ 
				index: this.reportPartIndex++,
				report_part_allocation_request_accumulation_types: [],
				reportPartAllocationRequestAccumulationTypeIndex: 0,
				report_part_task_accumulation_types: [],
				reportPartTaskAccumulationTypeIndex: 0
			}
		));
	}

	deleteReportPartAllocationRequestAccumulationType(reportPart, index) {
		var matchIndex = -1;
		reportPart.report_part_allocation_request_accumulation_types.some(function(el, i) {
			if (el.index == index) {
				matchIndex = i;
				return true;
			}
		});
		
		reportPart.report_part_allocation_request_accumulation_types.splice(matchIndex, 1);;
	}

	addReportPartAllocationRequestAccumulationType(reportPart) {
		reportPart.report_part_allocation_request_accumulation_types.push(new ReportPartAllocationRequestAccumulationType({ index: reportPart.reportPartAllocationRequestAccumulationTypeIndex++ }));
	}

	deleteReportPartTaskAccumulationType(reportPart, index) {
		var matchIndex = -1;
		reportPart.report_part_task_accumulation_types.some(function(el, i) {
			if (el.index == index) {
				matchIndex = i;
				return true;
			}
		});
		
		reportPart.report_part_task_accumulation_types.splice(matchIndex, 1);;
	}

	addReportPartTaskAccumulationType(reportPart) {
		reportPart.report_part_task_accumulation_types.push(new ReportPartTaskAccumulationType({ index: reportPart.reportPartTaskAccumulationTypeIndex++ }));
	}

  	ok(isValid) {
		this.submitted = true;
		
		if (isValid) {
			if (this.reportId === null) {
				this.http.post("reports", { report: this.report }).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.error.error);
				});
			} else {
				this.http.put("reports/" + this.report.id, { report: this.report }).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.error.error);
				});
			}
		}
  	}

  	cancel() {
		this.bsModalRef.hide();
  	}
	
	trackByFunction(index) {
		return index;
	}

    ngOnInit() {
		// TODO: DRY
		this.reportPartTypes = [
			{ id: 1, name: "Activiteiten werkdagen" },
			{ id: 2, name: "Afwezigheid werkdagen" },
			{ id: 3, name: "Totaal werkdagen" },
			{ id: 4, name: "Sommatiegroepen activiteiten" },
			{ id: 5, name: "Totaal afwezigheid" },
		];

		this.http.get("allocation_request_accumulation_types").subscribe(allocationRequestAccumulationTypes => {
			this.allocationRequestAccumulationTypes = allocationRequestAccumulationTypes;
		});

		this.http.get("task_accumulation_types").subscribe(taskAccumulationTypes => {
			this.taskAccumulationTypes = taskAccumulationTypes;
		});

		if (this.reportId === null) {
			this.actionTitle = "toevoegen";
			this.report = new Report();
			this.report.report_parts = [];
			this.reportPartIndex = 0;
		} else {
			this.actionTitle = "wijzigen";
			this.http.get<any>("reports/" + this.reportId).subscribe(x => {
				this.report = x;
				
				this.report.report_parts.forEach(function(reportPart, index) {
					reportPart.index = index;

					reportPart.report_part_allocation_request_accumulation_types.forEach(function(reportPartAllocationRequestAccumulationType, index) {
						reportPartAllocationRequestAccumulationType.index = index;
					});

					reportPart.report_part_task_accumulation_types.forEach(function(reportPartTaskAccumulationType, index) {
						reportPartTaskAccumulationType.index = index;
					});
				});
				
				this.reportPartIndex = x.report_parts.length;
			});
		}
    }
}
