import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home';
import { LoginComponent } from './authentication/login';
import { RegisterComponent } from './authentication/register';
import { ForgotPasswordComponent } from './authentication/forgotPassword';
import { DayScheduleComponent } from './daySchedule/daySchedule';
import { ProfileComponent } from './profile/profile';
import { LoggedInLayoutComponent } from './loggedInLayout';
import { ResetPasswordComponent } from './authentication/resetPassword';
import { TasksComponent } from './settings/tasks/tasks';
import { AllocationRequestsComponent } from './allocationRequests/allocationRequests';
import { MonthScheduleComponent } from './monthSchedule/monthSchedule';
import { TrainingScheduleComponent } from './trainingSchedule/trainingSchedule';
import { CanDeactivateGuardMonth } from './monthSchedule/canDeactivateGuardMonth';
import { CanDeactivateGuardTraining } from './trainingSchedule/canDeactivateGuardTraining';
import { AgendaComponent } from './agenda/agenda';
import { PeriodicCandidatesComponent } from './settings/periodicCandidates/periodicCandidates';
import { SchedulePeriodsComponent } from './settings/schedulePeriods/schedulePeriods';
import { OtherUsersComponent } from './settings/otherUsers/otherUsers';
import { SpecialEventsComponent } from './settings/specialEvents/specialEvents';
import { AccumulationPeriodsComponent } from './settings/accumulationPeriods/accumulationPeriods';
import { EnvironmentComponent } from './settings/environment/environment';
import { TaskDisplayGroupsComponent } from './settings/taskDisplayGroups/taskDisplayGroups';
import { TaskTypesComponent } from './settings/taskTypes/taskTypes';
import { AllocationRequestTypesComponent } from './settings/allocationRequestTypes/allocationRequestTypes';
import { CandidateTypesComponent } from './settings/candidateTypes/candidateTypes';
import { CustomAgendasComponent } from './settings/customAgendas/customAgendas';
import { LocationsComponent } from './settings/locations/locations';
import { OptimizationGroupsComponent } from './settings/optimizationGroups/optimizationGroups';
import { ShiftAccumulationTypesComponent } from './settings/shiftAccumulationTypes/shiftAccumulationTypes';
import { TaskAccumulationTypesComponent } from './settings/taskAccumulationTypes/taskAccumulationTypes';
import { TaskVariantGroupsComponent } from './settings/taskVariantGroups/taskVariantGroups';
import { TemplatePeriodAccumulationTypesComponent } from './settings/templatePeriodAccumulationTypes/templatePeriodAccumulationTypes';
import { TrainingPeriodAccumulationTypesComponent } from './settings/trainingPeriodAccumulationTypes/trainingPeriodAccumulationTypes';
import { TaskAccumulationPeriodsComponent } from './settings/taskAccumulationPeriods/taskAccumulationPeriods';
import { TaskAccumulationTargetsComponent } from './settings/taskAccumulationTargets/taskAccumulationTargets';
import { TaskConflictExceptionsComponent } from './settings/taskConflictExceptions/taskConflictExceptions';
import { AllocationRequestAccumulationTypesComponent } from './settings/allocationRequestAccumulationTypes/allocationRequestAccumulationTypes';
import { AllocationRequestAccumulationPeriodsComponent } from './settings/allocationRequestAccumulationPeriods/allocationRequestAccumulationPeriods';
import { PopulatedReportsComponent } from './populatedReports/populatedReports';
import { ReportsComponent } from './settings/reports/reports';
import { CandidateAllocationRequestBudgetComponentsComponent } from './settings/candidateAllocationRequestBudgetComponents/candidateAllocationRequestBudgetComponents';
import { DayScheduleTabsComponent } from './settings/dayScheduleTabs/dayScheduleTabs';
import { CandidateTypeTaskMembershipsComponent } from './settings/candidateTypeTaskMemberships/candidateTypeTaskMemberships';
import { DefaultAssignmentsComponent } from './settings/defaultAssignments/defaultAssignments';
import { ProximityConstraintsComponent } from './settings/proximityConstraints/proximityConstraints';
import { TrainingCandidatesComponent } from './trainingCandidates/trainingCandidates';
import { CustomersComponent } from './settings/customers/customers';
import { ArticlesComponent } from './settings/articles/articles';
import { OptoutRemindersComponent } from './authentication/optoutReminders';
import { SelfRosteringMembershipsComponent } from './settings/selfRosteringMemberships/selfRosteringMemberships';

const routes: Routes = [
	{
		path: 'app',
		//canActivate: [AngularTokenService], TODO: create custom service here to redirect when route is requested and user is not logged in
	  	component: LoggedInLayoutComponent,
	  	children: [
			{ path: 'daySchedule', component: DayScheduleComponent },
			{ path: 'agenda', component: AgendaComponent },
			{ path: 'monthSchedule', component: MonthScheduleComponent, canDeactivate: [CanDeactivateGuardMonth] },
			{ path: 'trainingSchedule', component: TrainingScheduleComponent, canDeactivate: [CanDeactivateGuardTraining] },
			{ path: 'trainingCandidates', component: TrainingCandidatesComponent },
			{ path: 'allocationRequests', component: AllocationRequestsComponent },
			{ path: 'reports', component: PopulatedReportsComponent },
			{ path: 'profile', component: ProfileComponent },
			{ 
				path: 'settings',
				children: [
					{ path: 'tasks', component: TasksComponent },
					{ path: 'periodicCandidates', component: PeriodicCandidatesComponent },
					{ path: 'schedulePeriods', component: SchedulePeriodsComponent },
					{ path: 'otherUsers', component: OtherUsersComponent },
					{ path: 'specialEvents', component: SpecialEventsComponent },
					{ path: 'accumulationPeriods', component: AccumulationPeriodsComponent },
					{ path: 'environment', component: EnvironmentComponent },
					{ path: 'taskDisplayGroups', component: TaskDisplayGroupsComponent },
					{ path: 'taskTypes', component: TaskTypesComponent },
					{ path: 'allocationRequestTypes', component: AllocationRequestTypesComponent },
					{ path: 'candidateTypes', component: CandidateTypesComponent },
					{ path: 'customAgendas', component: CustomAgendasComponent },
					{ path: 'locations', component: LocationsComponent },
					{ path: 'optimizationGroups', component: OptimizationGroupsComponent },
					{ path: 'shiftAccumulationTypes', component: ShiftAccumulationTypesComponent },
					{ path: 'taskAccumulationTypes', component: TaskAccumulationTypesComponent },
					{ path: 'taskVariantGroups', component: TaskVariantGroupsComponent },
					{ path: 'templatePeriodAccumulationTypes', component: TemplatePeriodAccumulationTypesComponent },
					{ path: 'trainingPeriodAccumulationTypes', component: TrainingPeriodAccumulationTypesComponent },
					{ path: 'taskAccumulationPeriods', component: TaskAccumulationPeriodsComponent },
					{ path: 'taskAccumulationTargets', component: TaskAccumulationTargetsComponent },
					{ path: 'taskConflictExceptions', component: TaskConflictExceptionsComponent },
					{ path: 'reports', component: ReportsComponent },
					{ path: 'allocationRequestAccumulationTypes', component: AllocationRequestAccumulationTypesComponent },
					{ path: 'allocationRequestAccumulationPeriods', component: AllocationRequestAccumulationPeriodsComponent },
					{ path: 'candidateAllocationRequestBudgetComponents', component: CandidateAllocationRequestBudgetComponentsComponent },
					{ path: 'dayScheduleTabs', component: DayScheduleTabsComponent },
					{ path: 'candidateTypeTaskMemberships', component: CandidateTypeTaskMembershipsComponent },
					{ path: 'defaultAssignments', component: DefaultAssignmentsComponent },
					{ path: 'proximityConstraints', component: ProximityConstraintsComponent },
					{ path: 'customers', component: CustomersComponent },
					{ path: 'articles', component: ArticlesComponent },
					{ path: 'selfRosteringMemberships', component: SelfRosteringMembershipsComponent }
				]
			}
	  	]
	},
  	{ path: 'login', component: LoginComponent },
  	{ path: 'forgotPassword', component: ForgotPasswordComponent },
  	{ path: 'register', component: RegisterComponent },
	{ path: 'resetPassword', component: ResetPasswordComponent },
	{ path: 'optoutReminders', component: OptoutRemindersComponent },
	{ path: 'products', component: HomeComponent },
	{ path: 'klanten', component: HomeComponent },
	{ path: 'team', component: HomeComponent },
	{ path: 'contact', component: HomeComponent },
	{ path: 'blog/:seoId', component: HomeComponent },
	{ path: 'blog', component: HomeComponent },
	{ path: '', component: HomeComponent },

  	// otherwise redirect to home
  	{ path: '**', redirectTo: '' }
];

export const appRoutingModule = RouterModule.forRoot(routes, { useHash: true });