import { Component, OnInit } from '@angular/core';
import templateString from './customers.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CustomerFormComponent } from '../customerForm';
import { DataService } from 'site/app/data.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class CustomersComponent implements OnInit {
    
    public customers: any;
    public customerTypes: string[];

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService,
        private dataService: DataService
    ) { }

    refresh() {
        this.http.get("customers").subscribe(x => {
            this.customers = x;

            this.customers.forEach(function(customer) {
                var period = "";
                switch(customer.invoice_frequency_id) {
                    case 1:
                        var dateOfPeriod = new Date();
                        dateOfPeriod.setDate(dateOfPeriod.getDate()-10); 
                        period = ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"][dateOfPeriod.getMonth()] + " " + dateOfPeriod.getFullYear();
                        break;
                }

                customer.emailString = "mailto:" + customer.invoice_email + 
                    "?subject=" + 
                        "Factuur easyplanner " + customer.name + " | " + period +
                    "&body=" + 
                        (customer.invoice_name ? "Beste " + customer.invoice_name + "," : "Geachte heer, mevrouw,") + "%0D%0A" +
                        "%0D%0A" +
                        "Bijgaand " + (customer.invoice_name ? "vind je" : "vindt u") + " de factuur voor levering van de software-applicatie easyplanner aan " + (customer.invoice_department ? "de vakgroep " + customer.invoice_department + " van het " : "") + customer.invoice_institution + " m.b.t. de periode " + period + "." + "%0D%0A" +
                        "%0D%0A" +
                        "Met vriendelijke groet," + "%0D%0A" +
                        "%0D%0A" +
                        "Thijs Langeveld" + "%0D%0A" +
                        "easyplanner" + "%0D%0A" +
                        "www.easyplanner.nl" + "%0D%0A" +
                        "E-mail: info@easyplanner.nl" + "%0D%0A" +
                        "Telefoon: 06 424 624 85" + "%0D%0A";
            });
        });
    }

    create() {
        var bsModalRef = this.bsModalService.show(CustomerFormComponent, {
            initialState: {
                customerId: null,
                newId: Math.max(...this.customers.map(function(x) { return x.id })) + 1
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    edit(customerId) {
        var bsModalRef = this.bsModalService.show(CustomerFormComponent, {
            initialState: {
                customerId: customerId
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    initialize() {
        this.customerTypes = [
            '', 
            '1. Met opleidingsrooster', 
            '2. Regionale planning',
            '3. Regulier',
            '4. Met individueel gedeelte'
        ]

		this.refresh();
	}
	
    ngOnInit() {
        this.initialize();
    }
}
