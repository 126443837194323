// https://medium.com/@amcdnl/global-error-handling-with-angular2-6b992bdfb59c

import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(
        private injector: Injector
    ) { }

    handleError(error) {
        (<any> window).errorCount || ((<any> window).errorCount = 0);
        if ((<any> window).errorCount > 5) {
        return;
        }
        (<any> window).errorCount += 1;

        const router = this.injector.get(Router);
        const http = this.injector.get(HttpClient);

        const reportOject = {
            status: error.status,
            name: error.name,
            message: error.message,
            httpErrorCode: error.httpErrorCode,
            stack: error.stack,
            url: location.href,
            route: router.url,
        };

        http.post("exceptions", reportOject).subscribe();

        throw error;
    }
}
