export class TaskDay {
	public disabled;
	public day_of_week;
	public is_all_day;
	public start_time;
	public end_time;
	
	constructor(obj) {
		this.disabled = obj.disabled;
		this.day_of_week = obj.day_of_week;
		this.is_all_day = obj.is_all_day;
		this.start_time = obj.start_time;
		this.end_time = obj.end_time;
	}
} 
