import { Component, OnInit, Renderer2 } from '@angular/core';
import templateString from './trainingPeriodAccumulationTypes.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TrainingPeriodAccumulationTypeFormComponent } from '../trainingPeriodAccumulationTypeForm';
import { TrainingPeriodAccumulationTypeChangeOrderComponent } from '../trainingPeriodAccumulationTypeChangeOrder';
import { DataService } from 'site/app/data.service';
import { EnvironmentService } from 'site/app/environment.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TrainingPeriodAccumulationTypesComponent implements OnInit {
    
    public trainingPeriodAccumulationTypes: any;
    public selectedSchedulePeriod: any;
    public destroyed$: any;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService,
        private dataService: DataService,
        private renderer: Renderer2,
        private environmentService: EnvironmentService
    ) { 
        this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');

        this.destroyed$ = new Subject<boolean>();
    }

    refresh() {
        this.http.get("training_period_accumulation_types", { params: { schedule_period_id: this.selectedSchedulePeriod.id } }).subscribe(x => {
            this.trainingPeriodAccumulationTypes = x;
        });
    }
    
    changeSchedulePeriod() {
        this.refresh();
    }

    create() {
        var bsModalRef = this.bsModalService.show(TrainingPeriodAccumulationTypeFormComponent, {
            initialState: {
                trainingPeriodAccumulationTypeId: null,
                schedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    edit(trainingPeriodAccumulationTypeId) {
        var bsModalRef = this.bsModalService.show(TrainingPeriodAccumulationTypeFormComponent, {
            initialState: {
                trainingPeriodAccumulationTypeId: trainingPeriodAccumulationTypeId,
                schedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    delete(trainingPeriodAccumulationTypeId) {
        if (window.confirm('Weet je het zeker?')) {
            this.http.delete("training_period_accumulation_types/" + trainingPeriodAccumulationTypeId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.toastr.error(response.error.error);
			});
        }
    };
    
    changeOrder() {
        var bsModalRef = this.bsModalService.show(TrainingPeriodAccumulationTypeChangeOrderComponent, {
            initialState: {
                originalTrainingPeriodAccumulationTypes: this.trainingPeriodAccumulationTypes
            }
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    getColor(trainingPeriodAccumulationType) {
		return this.dataService.colorsRegional[trainingPeriodAccumulationType.color_id];
	}

    initialize() {
		this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();

		this.refresh();
	}
	
    ngOnInit() {
        this.environmentService.selectedSchedulePeriodChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.initialize();
            });

        this.initialize();
    }

    ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
    }
}
