import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './optimizationGroupRunOptions.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { OptimizationGroup } from 'site/app/models/optimizationGroup.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class OptimizationGroupRunOptionsComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public optimizationGroup: any;
	public schedulePeriodId: any;

	public submitted: boolean;	
	public taskAccumulationPeriods: any;
	public isLoading: boolean;
	public schedulePeriods: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
        private toastr: ToastrService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

  	ok(isValid) {
		this.submitted = true;
		
		if (isValid) {
			var schedulePeriodIds = this.schedulePeriods.filter(function(x) { return x.isChecked; }).map(function(x){ return x.id });

			if (schedulePeriodIds.length == 0) {
				this.toastr.error("Selecteer tenminste één roosterperiode");
			} else {
				this.action.emit([
					schedulePeriodIds,
					this.optimizationGroup
				]);
				this.bsModalRef.hide();
			}
		}
  	}

  	cancel() {
		this.bsModalRef.hide();
  	}
	
	trackByFunction(index) {
		return index;
	}

    ngOnInit() {
		this.http.get("schedule_periods/show_all_by_schedule_period",
			{
				params: {
					id: this.schedulePeriodId
				}
			}
		).subscribe(x => {
			this.schedulePeriods = x;
		});

		this.http.get("task_accumulation_periods", { params: { schedule_period_id: this.schedulePeriodId } }).subscribe(taskAccumulationPeriods => {
			this.taskAccumulationPeriods = taskAccumulationPeriods;
	
			this.isLoading = false;
		});
    }
}
