import { Component, OnInit } from '@angular/core';
import templateString from './locations.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { LocationFormComponent } from '../locationForm';
import { LocationChangeOrderComponent } from '../locationChangeOrder';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class LocationsComponent implements OnInit {
    
    public locations: any;
    public selectedSchedulePeriod: any;
    public destroyed$: any;
    public trainingPeriodAccumulationTypes: any;
    public isLoading: boolean;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService
	) { 
        this.destroyed$ = new Subject<boolean>();
    }

    refresh() {
        var self = this;

        this.http.get("locations/index_with_location_capacities").subscribe(locations => {
            this.locations = locations;

            this.http.get("training_period_accumulation_types").subscribe(trainingPeriodAccumulationTypes => {                
                this.trainingPeriodAccumulationTypes = trainingPeriodAccumulationTypes;
            
                this.locations.forEach(function(location) {
                    location.locationCapacitiesFormatted = {};
                
                    self.trainingPeriodAccumulationTypes.forEach(function(trainingPeriodAccumulationType) {
                    
                        var locationCapacities = location.location_capacities.filter(function(x) { return x.training_period_accumulation_type_id == trainingPeriodAccumulationType.id });
                        
                        if (locationCapacities.length == 0) {
                            location.locationCapacitiesFormatted[trainingPeriodAccumulationType.id] = "";
                        } else {
                            var uniqueMinimums = locationCapacities.map(function(x) { return x.min }).filter(function(value, index, self) { return self.indexOf(value) === index; }).sort();
                            var uniqueMaximums = locationCapacities.map(function(x) { return x.max }).filter(function(value, index, self) { return self.indexOf(value) === index; }).sort();							
    
                            location.locationCapacitiesFormatted[trainingPeriodAccumulationType.id] = 
                                uniqueMinimums.join("/") + "-" + uniqueMaximums.join("/");
                        }
                    })
                })
                
                this.isLoading = false;
            });
        });
    }
    
    changeSchedulePeriod() {
        this.refresh();
    }

    create() {
        var bsModalRef = this.bsModalService.show(LocationFormComponent, {
            initialState: {
                locationId: null
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    edit(locationId) {
        var bsModalRef = this.bsModalService.show(LocationFormComponent, {
            initialState: {
                locationId: locationId
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    delete(locationId) {
        // if (window.confirm('Weet je het zeker?')) {
        //     this.http.delete("locations/" + locationId).subscribe(() => {
		// 		this.refresh();
		// 	}, (response) => {
		// 		this.toastr.error(response.error.error);
		// 	});
        // }
    };
    
    changeOrder() {
        var bsModalRef = this.bsModalService.show(LocationChangeOrderComponent, {
            initialState: {
                originalLocations: this.locations
            }
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    initialize() {
        this.isLoading = true;

		this.refresh();
	}
	
    ngOnInit() {
        this.initialize();
    }
}
