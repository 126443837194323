import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
 name: 'nameFilter',
 pure: false
})

export class NameFilterPipe implements PipeTransform {
    transform(items: any[], filterString: string): any {
        if (!items || !filterString) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return items.filter(item => item.name.toLowerCase().indexOf(filterString.toLowerCase()) !== -1);
    }
}