import { Component, OnInit, OnDestroy } from "@angular/core";
import templateString from './monthSchedule.component.html'
import * as moment from 'moment';
import { BsModalService } from "ngx-bootstrap/modal";
import { EnvironmentService } from "site/app/environment.service";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { TemplatePeriodFormComponent } from "../templatePeriodForm";
import { DataService } from "site/app/data.service";
import { Router } from "@angular/router";
import { Candidate } from "site/app/models/candidate.model";
import { SelectShiftChangesForTemplatePeriodsComponent } from "../selectShiftChangesForTemplatePeriods";
import { DomSanitizer } from "@angular/platform-browser";
import { takeUntil } from "rxjs/operators";
import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { ResizeEvent } from 'angular-resizable-element';
import { TemplatePeriod } from "site/app/models/templatePeriod.model";
import { HttpClient } from "@angular/common/http";

@Component({ 
	template: templateString,
	styles: [
		`
		  mwlResizable {
			box-sizing: border-box; // required for the enableGhostResize option to work
		  }		  
		`
	  ],
})
export class MonthScheduleComponent implements OnInit, OnDestroy {
	public destroyed$;
	public selectedSchedulePeriod;
	public user;
	public isVisible;
	public isLoading: boolean;
	public isEdit: any;
	public changedTemplatePeriods: any[];
	public deletedTemplatePeriodIds: any[];
	public data: any[] = [];
	public dataKpiRows = [];
	public fromDate: any;
	public toDate;
	public templatePeriodAccumulationTypes: any;
	public periodicCandidates;
	public numberOfDays;
	public monthColumns;
	public dateHeaderRows;
	public columnWidth;
	public horizontalScrollReceiver1;
	public verticalScrollReceiver1;
	public verticalScrollReceiver2;
	public Arr = Array;
	public mouseover;
	public mouseout;
	public currentHovered = null;
	public tempIndex = 0;
	public isDragging: boolean = false;
	public trainingPeriodAccumulationTypes: any;
	public tasks: any;
	public kpiRowsHidden: any;

	constructor(
		private http: HttpClient,
		private bsModalService: BsModalService,
		private environmentService: EnvironmentService,
		private toastr: ToastrService,
		private dataService: DataService,
		private router: Router,
		private domSanitizer: DomSanitizer
	) { 
		var self = this;
		this.destroyed$ = new Subject<boolean>();
		
		// use 1 event listener for all tooltips and show tooltip based on event target, for better performance
		// see also https://github.com/valor-software/ngx-bootstrap/issues/1536#issuecomment-539576500
		// create event listener according to https://javascript.info/mousemove-mouseover-mouseout-mouseenter-mouseleave
        this.mouseover = function(event: Event){
            // before entering a new element, the mouse always leaves the previous one
			// if currentElem is set, we didn't leave the previous <td>,
			// that's a mouseover inside it, ignore the event
			if (self.currentHovered != null) return;

			let target = self.closestByClass(event.target, 'for-tooltip');

			// we moved not into a <td> - ignore
		  	if (!target) return;

		  	self.currentHovered = target;
			self.tempIndex += 1;

			let boundingRectangle = target.getBoundingClientRect();

			self.environmentService.setHoveredElement({
				text: self.getTooltipText(self.currentHovered),
				bottom: boundingRectangle.bottom,
				left: boundingRectangle.left,
				right: boundingRectangle.right
			});
        }

		document.addEventListener('mouseover', this.mouseover);
		
		this.mouseout = function(event: Event) {
			// if we're outside of any <td> now, then ignore the event
			// that's probably a move inside the table, but out of <td>,
			// e.g. from <tr> to another <tr>
			if (!self.currentHovered) return;

			// we're leaving the element – where to? Maybe to a descendant?
		  	let relatedTarget = (<any> event).relatedTarget;

		  	while (relatedTarget) {
				// go up the parent chain and check – if we're still inside currentElem
				// then that's an internal transition – ignore it
			    if (relatedTarget == self.currentHovered) return;
				
				relatedTarget = relatedTarget.parentNode;
			}

			// we left the <td>. really.
			self.currentHovered = null;
			self.environmentService.setHoveredElement(null);
		}

		document.addEventListener('mouseout', this.mouseout);
	}

	closestByClass(el, tooltipClassName) {
	    // Traverse the DOM up with a while loop
	    while (!el.classList || !el.classList.contains(tooltipClassName)) {
	        // Increment the loop to the parent node
	        el = el.parentNode;
	        if (!el) {
	            return null;
	        }
	    }
	    // At this point, the while loop has stopped and `el` represents the element that has
	    // the class you specified in the second parameter of the function `clazz`

	    // Then return the matched element
	    return el;
	}

	getLength(from, to) {
		return moment(to).diff(moment(from), 'days');
	}

	getOffset(from) {
		return moment(from).diff(this.fromDate, 'days');
	}

	createTemplatePeriod(templatePeriodTask) {
		var bsModalRef = this.bsModalService.show(TemplatePeriodFormComponent, {
            initialState: {
				templatePeriodTask: templatePeriodTask,
                templatePeriod: null,
                schedulePeriod: this.selectedSchedulePeriod
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(isDeleteAndTemplatePeriod => {
			var templatePeriod = isDeleteAndTemplatePeriod[1];
	
			var ganttTaskId = this.addToGanttChart(templatePeriod.periodic_candidate_id, templatePeriod);
			templatePeriod.ganttTaskId = ganttTaskId;
			
			this.addChangedTemplatePeriod(templatePeriod);
		});
	}

	refreshTrainingPeriodAccumulationTypeCounts() {
		var self = this;

		this.http.get("training_period_accumulation_types/counts",
			{ params: { schedule_period_id: this.selectedSchedulePeriod.id } })
		.subscribe(data => {
			var counts = data['counts'];

			Object.keys(counts).forEach(function(trainingPeriodAccumulationTypeId) {
				self.dataKpiRows.filter(function(x) { return x.id == ("trainingPeriodAccumulationType_" + trainingPeriodAccumulationTypeId.toString()) })[0].tasks =
					counts[trainingPeriodAccumulationTypeId].map(function(x) { return self.dataService.createTemplatePeriodAccumulationTypeCount(x) });
			});
		});
	}

	refreshTemplatePeriodAccumulationTypeCounts() {
		var self = this;

		this.http.get("template_period_accumulation_types/counts",
			{ params: { schedule_period_id: this.selectedSchedulePeriod.id } })
		.subscribe(data => {
			var counts = data['counts'];
			Object.keys(counts).forEach(function(templatePeriodAccumulationTypeId) {
				self.dataKpiRows.filter(function(x) { return x.id == ("templatePeriodAccumulationType_" + templatePeriodAccumulationTypeId.toString()) })[0].tasks =
					counts[templatePeriodAccumulationTypeId].map(function(x) { return self.dataService.createTemplatePeriodAccumulationTypeCount(x) });
			});
		});
	}

	setStartEndDate() {
		this.fromDate = this.selectedSchedulePeriod.start_date;
		this.toDate = moment(this.selectedSchedulePeriod.end_date).subtract(1, 'day');

		this.numberOfDays = this.toDate.diff(this.fromDate, 'days') + 1;

		this.columnWidth = (window.innerWidth - 260) / this.numberOfDays;
		
		var yearColumns = [];
		this.monthColumns = [];

		var currentDate = moment(this.fromDate);
		var index = 0;

		var tempYear = currentDate.format("YYYY");
		var tempYearIndex = 0;

		var tempMonth = currentDate.format("MMMM");
		var tempMonthIndex = 0;

		while (currentDate <= this.toDate) {
			var currentYear = currentDate.format("YYYY");
			var currentMonth = currentDate.format("MMMM");

			if (currentYear != tempYear) {
				yearColumns.push({ title: tempYear, offsetInNumberOfCells: tempYearIndex, widthInNumberOfCells: index - tempYearIndex });

				tempYear = currentYear;
				tempYearIndex = index;
			}

			if (currentMonth != tempMonth) {
				this.monthColumns.push({ title:  tempMonth, offsetInNumberOfCells: tempMonthIndex, widthInNumberOfCells: index - tempMonthIndex });

				tempMonth = currentMonth;
				tempMonthIndex = index;
			}

			if (currentDate.isSame(this.toDate)) {
				yearColumns.push({ title: currentYear, offsetInNumberOfCells: tempYearIndex, widthInNumberOfCells: index + 1 - tempYearIndex })
				this.monthColumns.push({ title: currentMonth, offsetInNumberOfCells: tempMonthIndex, widthInNumberOfCells: index + 1 - tempMonthIndex })
			}

			currentDate = moment(currentDate).add(1, 'day');
			index++;
		}

		this.dateHeaderRows = [yearColumns, this.monthColumns];
	}
		
	refresh() {
		var self = this;
		this.dataKpiRows = [];
		this.data = [];
		
		this.setStartEndDate();
	
		this.http.get("template_period_accumulation_types", { params: { schedule_period_id: this.selectedSchedulePeriod.id } })
		.subscribe(templatePeriodAccumulationTypes => {
			this.dataKpiRows.push({
				name: "Tellingen",
				id: "task_display_group_0",
				taskDisplayGroupId: 0,
				isDisplayGroup: true,
				isHidden: false, //task.task_display_group.is_hidden
				isGroupedRow: false
			});	

			this.templatePeriodAccumulationTypes = templatePeriodAccumulationTypes;

	 		this.templatePeriodAccumulationTypes.forEach(function(templatePeriodAccumulationType, index) {
				self.dataKpiRows.push({
					id: "templatePeriodAccumulationType_" + templatePeriodAccumulationType.id,
					name: "# " + templatePeriodAccumulationType.name,
					classes: index == self.templatePeriodAccumulationTypes.length - 1 ? "separatingTask" : "",
					isDisplayGroup: false,
					isGroupedRow: true
				});
			});
			
			this.refreshTemplatePeriodAccumulationTypeCounts();

			this.http.get("periodic_candidates",
				{ 
					params: {
						schedule_period_id: this.selectedSchedulePeriod.id, 
						filter_by_location: 'true'
					}
				}
			).subscribe(periodicCandidates => {
				this.periodicCandidates = periodicCandidates;

		 		this.periodicCandidates.forEach(function(periodicCandidate) {

					periodicCandidate.infixWithLastname = (new Candidate(periodicCandidate.candidate)).infixWithLastname();

					self.data.push({
						id: "training_" + periodicCandidate.id,
						isTrainingPeriodRow: true,
						name: "",
						classes: "trainingRowInTemplateSchedule"
					})
			
					self.data.push({
						id: "template_" + periodicCandidate.id,
						isTrainingPeriodRow: false,
						name: (new Candidate(periodicCandidate.candidate)).infixWithLastname() + " (" + periodicCandidate.candidate_type.name + ")",
						classes: "templateRowInTemplateSchedule",
						isTrainee: periodicCandidate.candidate_type.is_trainee,
						periodicCandidateId: periodicCandidate.id
					})
				});

				// first add training_periods
				this.http.get("training_periods/grouped_by_periodic_candidate",
					{ params: { schedule_period_id: this.selectedSchedulePeriod.id } }
				).subscribe(trainingPeriods => {
					this.periodicCandidates.forEach(function(periodicCandidate) {
						self.data.filter(function(x) { return x.id == ("training_" + periodicCandidate.id) })[0].tasks =
							trainingPeriods[periodicCandidate.id] == null ? [] : trainingPeriods[periodicCandidate.id].map(function(x) {
								x.start_date = moment.max([moment(x.start_date), moment(self.fromDate)]);
								x.end_date = moment.min([moment(x.end_date), moment(self.toDate).add(1, 'day')]);

								return self.dataService.createTrainingPeriodTaskForPeriodicCandidate(x, periodicCandidate) 
						});
					});

					this.refreshTemplatePeriods();
				});
			});
		});
	}

	refreshTemplatePeriods() {
		var self = this;

		this.http.get("template_periods/grouped_by_periodic_candidate", 
			{ params: { schedule_period_id: this.selectedSchedulePeriod.id } }
		).subscribe(templatePeriods => {
			this.periodicCandidates.forEach(function(periodicCandidate) {
				self.data.filter(function(x) { return x.id == ("template_" + periodicCandidate.id) })[0].tasks =
					templatePeriods[periodicCandidate.id] == null ? [] : templatePeriods[periodicCandidate.id].map(function(x) {
						return self.dataService.createTemplatePeriodTaskForPeriodicCandidate(x, periodicCandidate) });
			});
		});
	}
	
	addChangedTemplatePeriod(templatePeriod) {
		if (this.changedTemplatePeriods.filter(function(x) { return x.ganttTaskId == templatePeriod.ganttTaskId }).length == 0) {
			this.changedTemplatePeriods.push(templatePeriod);
		}
	} 
	
	addToGanttChart(periodicCandidateId, templatePeriod) {
		var ganttRow = this.data.filter(function(x) { return x.id == ("template_" + periodicCandidateId); })[0];
		var periodicCandidate = this.periodicCandidates.filter(function(x) { return x.id == periodicCandidateId })[0];

		ganttRow.tasks.push(this.dataService.createTemplatePeriodTaskForPeriodicCandidate(templatePeriod, periodicCandidate));
		
		return ganttRow.tasks[ganttRow.tasks.length-1].id;
	}
	
	deleteFromGanttChart(rowId, templatePeriod) {
		var ganttRow = this.data.filter(function(x) { return x.id == rowId; })[0];

		var index = -1;
		ganttRow.tasks.some(function(el, i) {
			if (el.id == templatePeriod.ganttTaskId) {
				index = i;
				return true;
			}
		});

		ganttRow.tasks.splice(index, 1);

		// delete from changedPeriods
		var changedPeriodsIndex = -1;
		this.changedTemplatePeriods.some(function(tp, i) {
			if (tp.ganttTaskId == templatePeriod.ganttTaskId) {
				changedPeriodsIndex = i;
				return true;
			}
		});
		
		if (changedPeriodsIndex > -1) {
			this.changedTemplatePeriods.splice(changedPeriodsIndex, 1);
		}
	}
	
	save() {
		var self = this;

		// check whether changed template periods do not go outside borders of schedule period
		var schedulePeriod = this.environmentService.getSchedulePeriodSelection();
		var validationErrors = [];
		
		this.changedTemplatePeriods.forEach(function(changedTemplatePeriod) {
			if (moment(changedTemplatePeriod.start_date).isBefore(moment(schedulePeriod.start_date))) {
				validationErrors.push("Startdatum van opleidingsonderdeel [" + (new Candidate(changedTemplatePeriod.periodicCandidate.candidate)).infixWithLastname() + 
					", " + changedTemplatePeriod.task.name + 
					", " + moment(changedTemplatePeriod.start_date).format('D MMM') + " t/m " + moment(changedTemplatePeriod.end_date).subtract(1, 'day').format('D MMM') + 
					"] ligt voor de startdatum van de roosterperiode (" + moment(schedulePeriod.start_date).format('D MMM') + ") ");
			}
			if (moment(changedTemplatePeriod.end_date).isAfter(moment(schedulePeriod.end_date))) {
				validationErrors.push("Einddatum van opleidingsonderdeel [" + (new Candidate(changedTemplatePeriod.periodicCandidate.candidate)).infixWithLastname() + 
					", " + changedTemplatePeriod.task.name + 
					", " + moment(changedTemplatePeriod.start_date).format('D MMM') + " t/m " + moment(changedTemplatePeriod.end_date).subtract(1, 'day').format('D MMM') + 
					"] ligt na de einddatum van de roosterperiode (" + moment(schedulePeriod.end_date).subtract(1, 'day').format('D MMM') + ") ");
			}

			var matchedRows = self.data.filter(function(x) { return x.id == ("template_" + changedTemplatePeriod.periodic_candidate_id); });

			if (matchedRows.length > 0) {
				var templatePeriodsForOverlapCheck = self.data.filter(function(x) { return x.id == ("template_" + changedTemplatePeriod.periodic_candidate_id); }
					)[0].tasks.map(function(x) { return x.templatePeriod; });
			
				templatePeriodsForOverlapCheck.forEach(function(templatePeriodForOverlapCheck) {
					if (changedTemplatePeriod.ganttTaskId != templatePeriodForOverlapCheck.ganttTaskId &&
						// this will make sure we see each overlap validation error only once
						changedTemplatePeriod.ganttTaskId < templatePeriodForOverlapCheck.ganttTaskId && 
						(
							moment(changedTemplatePeriod.start_date).isBefore(moment(templatePeriodForOverlapCheck.end_date)) &&
							moment(changedTemplatePeriod.end_date).isAfter(moment(templatePeriodForOverlapCheck.start_date))
						)
					) {
						validationErrors.push("Overlap tussen opleidingsonderdelen [" + (new Candidate(changedTemplatePeriod.periodicCandidate.candidate)).infixWithLastname() + 
							", " + changedTemplatePeriod.task.name + 
							", " + moment(changedTemplatePeriod.start_date).format('D MMM') + " t/m " + moment(changedTemplatePeriod.end_date).subtract(1, 'day').format('D MMM') + 
						"] en [" + (new Candidate(templatePeriodForOverlapCheck.periodicCandidate.candidate)).infixWithLastname() + 
							", " + templatePeriodForOverlapCheck.task.name + 
							", " + moment(templatePeriodForOverlapCheck.start_date).format('D MMM') + " t/m " + moment(templatePeriodForOverlapCheck.end_date).subtract(1, 'day').format('D MMM') + 
							"]");
					}
				});
			}
		});

		if (validationErrors.length > 0) {
			alert("Kan de wijzigingen niet opslaan om de volgende reden(en):" + validationErrors.map(function(x) { return "\n- " + x }).join() )
		} else {
			var changedTemplatePeriodsToSend = this.changedTemplatePeriods.splice(0);
			changedTemplatePeriodsToSend.forEach(function(x) {
				var startDate = moment(x.start_date);
				var endDate = moment(x.end_date);
				startDate.locale('en');
				endDate.locale('en');
				x.start_date = startDate.format("ddd D MMM YYYY");
				x.end_date = endDate.format("ddd D MMM YYYY");
			});

			this.http.post<any>("shifts/save_template_periods_and_get_shift_changes",
				{ 
					changed_template_periods: changedTemplatePeriodsToSend,
					deleted_template_period_ids: this.deletedTemplatePeriodIds
				}
			).subscribe(shifts => {

				this.changedTemplatePeriods = [];
				this.deletedTemplatePeriodIds = [];

				this.refreshTemplatePeriodAccumulationTypeCounts();
				// if we don't refresh the tasks then we get the bug for the situation
				// when we add a new template period and we save it, and without refresh of page we delete it, the id is not in 
				// deletedTemplatePeriodIds
				this.refreshTemplatePeriods();

				this.toastr.success("Toegevoegde en gewijzigde activiteiten zijn succesvol opgeslagen");
			
				if (shifts.length == 0) {
					this.changedTemplatePeriods = [];
					this.deletedTemplatePeriodIds = [];
				} else {

					var bsModalRef = this.bsModalService.show(SelectShiftChangesForTemplatePeriodsComponent, {
						initialState: {
							shifts: shifts
						},
						class: 'xlModal'
					});
			
					bsModalRef.content.action
						.pipe(takeUntil(this.destroyed$))
						.subscribe(shiftChangesWithConfirmation => {
							this.http.post("shifts/apply_shift_changes_for_template_periods",
								{ confirmed_shifts: shiftChangesWithConfirmation.filter(function(x) { return x.apply_change; }) }
							).subscribe(() => {
								this.toastr.success("De geselecteerde activiteiten zijn succesvol opgeslagen in het rooster");
							});						
						});			
				}
			});
		}
	}
	
	startEdit() {
		this.isEdit = true;
	}
	
	cancelEdit() {
		this.isEdit = false;
		
		// TODO: does this work?
		this.initialize();
	}

	fillAccordingToTrainingPeriods(periodicCandidateId) {
		var self = this;
		
		var ganttTasks = self.data.filter(function(x) { return x.id == ("training_" + periodicCandidateId) })[0].tasks;

		if (ganttTasks.length == 0) {
			self.toastr.warning("Geen opleidingsonderdelen gevonden voor deze " + this.environmentService.translateForIndustry('candidate'));
		}

		ganttTasks.forEach(function(ganttTask) {
			var trainingPeriodAccumulationType = self.trainingPeriodAccumulationTypes.filter(function(y) { return y.id == ganttTask.trainingPeriod.training_period_accumulation_type_id; })[0];

			if (trainingPeriodAccumulationType.default_template_period_tasks_for_schedule_period.length > 0) {
				var templatePeriod = new TemplatePeriod();
				templatePeriod.periodic_candidate_id = periodicCandidateId;
				templatePeriod.start_date = ganttTask.trainingPeriod.start_date;
				templatePeriod.end_date = ganttTask.trainingPeriod.end_date;
				templatePeriod.task_id = trainingPeriodAccumulationType.default_template_period_tasks_for_schedule_period[0].task_id;
				templatePeriod.task = self.tasks.filter(function(x) { return x.id == templatePeriod.task_id; })[0];

				// check for other existing template periods in the same period
				var ganttRow = self.data.filter(function(x) { return x.id == ("template_" + periodicCandidateId); })[0];

				if (ganttRow.tasks.filter(function(x) { return moment(x.templatePeriod.start_date) < moment(templatePeriod.end_date) && moment(x.templatePeriod.end_date) > moment(templatePeriod.start_date); }).length == 0) {
					var ganttTaskId = self.addToGanttChart(periodicCandidateId, templatePeriod);
					templatePeriod.ganttTaskId = ganttTaskId;
					
					self.addChangedTemplatePeriod(templatePeriod);

					self.toastr.success("Stage '" + trainingPeriodAccumulationType.name + "' (" + moment(templatePeriod.start_date).format("DD-MM-YYYY") + " t/m " + (moment(templatePeriod.end_date).subtract(1, 'day')).format("DD-MM-YYYY") + ") toegevoegd");
				} else {
					self.toastr.warning("Stage '" + trainingPeriodAccumulationType.name + "' (" + moment(templatePeriod.start_date).format("DD-MM-YYYY") + " t/m " + (moment(templatePeriod.end_date).subtract(1, 'day')).format("DD-MM-YYYY") + ") niet toegevoegd omdat er al een stage in deze periode bestaat");
				}
			} else {
				self.toastr.error("Geen standaard-activiteiten gevonden voor stagetype '" + trainingPeriodAccumulationType.name + "'");
			}
		});		
	}

	clickGanttTask(templatePeriodTask, row) {
		if (this.isDragging) {
			this.isDragging = false;
		} else {
			if (!this.isDragging && this.isEdit) {

				var bsModalRef = this.bsModalService.show(TemplatePeriodFormComponent, {
					initialState: {
						templatePeriodTask: templatePeriodTask,
						templatePeriod: templatePeriodTask.templatePeriod,
						schedulePeriod: this.selectedSchedulePeriod
					},
					class: 'modal-lg'
				});
		
				bsModalRef.content.action.subscribe(isDeleteAndTemplatePeriod => {	
					var isDelete = isDeleteAndTemplatePeriod[0];
					var templatePeriod = isDeleteAndTemplatePeriod[1];
		
					templatePeriod.ganttTaskId = templatePeriodTask.id;
					this.deleteFromGanttChart(row.id, templatePeriod);
		
					if (isDelete) {
						if (templatePeriod.id != null) {
							this.deletedTemplatePeriodIds.push(templatePeriod.id);
						}
					} else {
						var ganttTaskId = this.addToGanttChart(templatePeriod.periodic_candidate_id, templatePeriod);
						templatePeriodTask.templatePeriod.ganttTaskId = ganttTaskId;
						this.addChangedTemplatePeriod(templatePeriodTask.templatePeriod);
					}
				});
			}
		}
	}

	getTooltipText(element) {
		var task = JSON.parse(element.getAttribute("taskasjson"));

		return this.domSanitizer.bypassSecurityTrustHtml("<div>" + 
			"<span style='font-weight: normal'>" + task.tooltipTitle + "</span><br />" +
				"<small style='font-weight: normal'>" +
					task.tooltipSubtitle + "<br />" +
					task.dateLabel + "<br />" +
					task.candidateNames +
				"</small>" + 
			"</div>");
	}

	toggleTaskDisplayGroup(ganttRow) {
		ganttRow.isHidden = !ganttRow.isHidden
		this.kpiRowsHidden = ganttRow.isHidden
	}

	initialize() {
		var self = this;
		this.user = this.environmentService.getUser();
		if (this.user == undefined) {
			this.router.navigate(["/login"]);
		}
		
		this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();

		this.isVisible = this.user != null && (this.user.isAdmin || (this.selectedSchedulePeriod.is_published && (this.user.candidateId == null || this.user.schedulePeriodIds.indexOf(this.selectedSchedulePeriod.id) >= 0)));
		
		this.kpiRowsHidden = false;

		if (this.isVisible) {
			
			this.isLoading = false;
			this.isEdit = this.user.isAdmin;
			this.changedTemplatePeriods = [];
			this.deletedTemplatePeriodIds = [];
	
			this.http.get("training_period_accumulation_types", { params: { schedule_period_id: this.selectedSchedulePeriod.id } }).subscribe(x => {
				this.trainingPeriodAccumulationTypes = x;

				this.trainingPeriodAccumulationTypes.forEach(function(trainingPeriodAccumulationType, index) {
					self.dataKpiRows.push({
						id: "trainingPeriodAccumulationType_" + trainingPeriodAccumulationType.id,
						name: "# " + trainingPeriodAccumulationType.name,
						classes: index == self.trainingPeriodAccumulationTypes.length - 1 ? "separatingTask" : "",
						isGroupedRow: true
					});
				});

				this.refreshTrainingPeriodAccumulationTypeCounts();
			});

			this.http.get("tasks",
				{ 
					params: {
						schedule_period_id: this.selectedSchedulePeriod.id, 
						is_included_in_template_schedule: 'true'
					}
				}
			).subscribe(tasks => {
				this.tasks = tasks;
			});

			this.refresh();
		}
	}

	syncScroll(event) {
		this.horizontalScrollReceiver1.scrollLeft = event.scrollLeft;
		this.verticalScrollReceiver1.scrollTop = event.scrollTop;
		this.verticalScrollReceiver2.scrollTop = event.scrollTop;
	}

	hasUnsavedData() {
        return this.isVisible && 
            ((this.changedTemplatePeriods != null && this.changedTemplatePeriods.length > 0) || 
                (this.deletedTemplatePeriodIds != null && this.deletedTemplatePeriodIds.length > 0));
	}
	
	createGanttTask(event: MouseEvent, row) {
		var startDate = moment(this.fromDate).add(this.getDayOffsetByPixelOffset(event.offsetX), 'days');
		var endDate = moment(startDate).add(1, 'month');
		if (endDate.isAfter(moment(this.toDate).add(1, 'day'))) {
			endDate = moment(this.toDate).add(1, 'day');
		}

		this.createTemplatePeriod({ row: row, from: startDate.format('YYYY-MM-DD'), to: endDate.format('YYYY-MM-DD') })
	}

	onResizeEnd(event: ResizeEvent, templatePeriodTask, row): void {
		if (event.edges) {
			this.changeStartEndDateByDelta(event.edges.left, event.edges.right, templatePeriodTask, row);
		}
	}

	dragStarted() {
		this.isDragging = true
	}

	dragEnded(event: CdkDragEnd, templatePeriodTask, row) {
		this.changeStartEndDateByDelta(event.distance.x, event.distance.x, templatePeriodTask, row);
	}

	changeStartEndDateByDelta(startDateDelta, endDateDelta, templatePeriodTask, row) {
		var templatePeriod = templatePeriodTask.templatePeriod;

		if (startDateDelta && startDateDelta != 0) {
			templatePeriod.start_date = moment(templatePeriod.start_date).add(this.getDayOffsetByPixelOffset(<number> startDateDelta), 'days').format('YYYY-MM-DD');
		}
		
		if (endDateDelta && endDateDelta != 0) {
			templatePeriod.end_date = moment(templatePeriod.end_date).add(this.getDayOffsetByPixelOffset(<number> endDateDelta), 'days').format('YYYY-MM-DD');
		}

		setTimeout(() => {
			templatePeriod.ganttTaskId = templatePeriodTask.id;
			this.deleteFromGanttChart(row.id, templatePeriod);

			var ganttTaskId = this.addToGanttChart(templatePeriod.periodic_candidate_id, templatePeriod);
			templatePeriodTask.templatePeriod.ganttTaskId = ganttTaskId;
			this.addChangedTemplatePeriod(templatePeriodTask.templatePeriod);
		}, 100);
	}

	getDayOffsetByPixelOffset(pixelOffset) {
		return Math.round(pixelOffset / this.columnWidth);
	}
	
	ngOnInit() {
		if (this.environmentService.getSchedulePeriodSelection() != null) {
			this.initialize();
		}

		this.environmentService.selectedSchedulePeriodChanged$
			.pipe(takeUntil(this.destroyed$))
			.subscribe(schedulePeriodId => {
				this.initialize();
			});
	}
	
	ngAfterViewInit() {
		this.horizontalScrollReceiver1 = document.querySelectorAll('.myHorizontalScrollReceiver')[0];
		var verticalScrollReceivers = document.querySelectorAll('.myVerticalScrollReceiver');
		this.verticalScrollReceiver1 = verticalScrollReceivers[0];
		this.verticalScrollReceiver2 = verticalScrollReceivers[1];
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();

		document.removeEventListener('mouseover', this.mouseover);
		document.removeEventListener('mouseout', this.mouseout);
    }
}
