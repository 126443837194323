import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './selfRosteringMembershipForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

@Component({ template: templateString })
export class SelfRosteringMembershipFormComponent implements OnInit {
	@Output() action = new EventEmitter();

	public selfRosteringStartDate: any;

	public candidateTypes: any;
	public tasks: any;
	public startDate: any;
	public datePickerConfig: any;

	public isLoading: boolean;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
		private environmentService: EnvironmentService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}
	
	ok() {
 		this.isLoading = true;

		 var selfRosteringStartDate = moment(this.selfRosteringStartDate);
		 selfRosteringStartDate.locale('en');
		 var parsedSelfRosteringStartDate = selfRosteringStartDate.format("ddd D MMM YYYY");

		this.http.post("tasks/update_self_rostering_memberships",
			{ tasks: this.tasks, self_rostering_start_date: parsedSelfRosteringStartDate }
		).subscribe(() => {
			this.isLoading = false;
			this.action.emit(true);
			this.bsModalRef.hide();
		});
	}

  	cancel() {
		this.bsModalRef.hide();
	}

    ngOnInit() {
		this.datePickerConfig = {
			firstDayOfWeek: "mo"
		};
	}
}
