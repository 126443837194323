import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import templateString from './defaultAssignments.component.html'
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ChangeDefaultAssignmentModalComponent } from './../changeDefaultAssignmentModal/changeDefaultAssignmentModal.component';
import { ToastrService } from 'ngx-toastr';
import { EnvironmentService } from 'site/app/environment.service';
import { DataService } from 'site/app/data.service';
import { forkJoin, Subject } from 'rxjs';
import { Candidate } from 'site/app/models/candidate.model';
import { ApplyDefaultAssignmentDetailsComponent } from '../applyDefaultAssignmentDetails/applyDefaultAssignmentDetails.component';
import { HttpClient } from '@angular/common/http';

@Component({ 
	template: templateString,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultAssignmentsComponent implements OnInit, AfterViewInit, OnDestroy {
	public selectedSchedulePeriod;
	public isInitializing;
	public isLoading;
	public isMultiSelect;
	public selectedGanttTasksForMultiSelect = [];
	public columnWidth = 28;
	public fromDate;
	public toDate;
	public numberOfDays;
	public dates;
	public data = [];
	public tasks;
	public numberOfTaskDisplayGroups;
	public timespans;
	public dateHeaderRows;
	public user;
	public horizontalScrollReceiver1;
	public verticalScrollReceiver1;
	public verticalScrollReceiver2;
	public currentHovered = null;
	public tempIndex = 0;
	public destroyed$;
	public mouseover;
	public mouseout;
	public selectedTaskDisplayGroupId;
	public taskDisplayGroups: any;

	constructor(
		private http: HttpClient,
		private dataService: DataService,
		private environmentService: EnvironmentService,
		private bsModalService: BsModalService,
		private changeDetectorRef: ChangeDetectorRef,
		private toastr: ToastrService
	) { 
		var self = this;
		this.destroyed$ = new Subject<boolean>();
		
		// use 1 event listener for all tooltips and show tooltip based on event target, for better performance
		// see also https://github.com/valor-software/ngx-bootstrap/issues/1536#issuecomment-539576500
		// create event listener according to https://javascript.info/mousemove-mouseover-mouseout-mouseenter-mouseleave
        this.mouseover = function(event: Event){
            // before entering a new element, the mouse always leaves the previous one
			// if currentElem is set, we didn't leave the previous <td>,
			// that's a mouseover inside it, ignore the event
			if (self.currentHovered != null) return;

			let target = self.closestByClass(event.target, 'for-tooltip');

			// we moved not into a <td> - ignore
		  	if (!target) return;

		  	self.currentHovered = target;
			self.tempIndex += 1;

			let boundingRectangle = target.getBoundingClientRect();

			self.environmentService.setHoveredElement({
				text: self.getTooltipText(self.currentHovered),
				bottom: boundingRectangle.bottom,
				left: boundingRectangle.left,
				right: boundingRectangle.right
			});
        }

		document.addEventListener('mouseover', this.mouseover);
		
		this.mouseout = function(event: Event) {
			// if we're outside of any <td> now, then ignore the event
			// that's probably a move inside the table, but out of <td>,
			// e.g. from <tr> to another <tr>
			if (!self.currentHovered) return;

			// we're leaving the element – where to? Maybe to a descendant?
		  	let relatedTarget = (<any> event).relatedTarget;

		  	while (relatedTarget) {
				// go up the parent chain and check – if we're still inside currentElem
				// then that's an internal transition – ignore it
			    if (relatedTarget == self.currentHovered) return;
				
				relatedTarget = relatedTarget.parentNode;
			}

			// we left the <td>. really.
			self.currentHovered = null;
			self.environmentService.setHoveredElement(null);
		}

		document.addEventListener('mouseout', this.mouseout);
	}
	
	detectChanges() {
		if (!this.destroyed$.isStopped) {
			this.changeDetectorRef.detectChanges();
		}
	}

	closestByClass(el, tooltipClassName) {
	    // Traverse the DOM up with a while loop
	    while (!el.classList || !el.classList.contains(tooltipClassName)) {
	        // Increment the loop to the parent node
	        el = el.parentNode;
	        if (!el) {
	            return null;
	        }
	    }
	    // At this point, the while loop has stopped and `el` represents the element that has
	    // the class you specified in the second parameter of the function `clazz`

	    // Then return the matched element
	    return el;
	}

	setStartEndDate(defaultAssignmentPeriods) {
		var weekdays = ["zo", "ma", "di", "wo", "do", "vr", "za"];

		if (defaultAssignmentPeriods.length == 0) {
			this.numberOfDays = 21;
		} else {
			this.numberOfDays = defaultAssignmentPeriods.length * 7 + 7;
		}

		this.fromDate = moment([2000, 0, 3]);
		this.toDate = moment([2000, 0, 3]).add(this.numberOfDays - 1, 'days');
		
		this.dates = new Array(this.numberOfDays);

		var weekColumns = [];
		var dayColumns = [];

		var currentDate = moment(this.fromDate);
		var index = 0;

		var tempWeekNumber = currentDate.isoWeek();
		var tempWeekIndex = 0;

		var tempMonthAndYear = currentDate.format("MMMM YYYY");

		while (currentDate <= this.toDate) {
			var currentMonthAndYear = currentDate.format("MMMM YYYY");
			var currentWeekNumber = currentDate.isoWeek();

			if (currentMonthAndYear != tempMonthAndYear) {
				tempMonthAndYear = currentMonthAndYear;
			}

			if (currentWeekNumber != tempWeekNumber) {
				weekColumns.push({ title: tempWeekNumber, offsetInNumberOfCells: tempWeekIndex, widthInNumberOfCells: index - tempWeekIndex });

				tempWeekNumber = currentWeekNumber;
				tempWeekIndex = index;
			}

			if (currentDate.isSame(this.toDate)) {
				weekColumns.push({ title: currentWeekNumber, offsetInNumberOfCells: tempWeekIndex, widthInNumberOfCells: index + 1 - tempWeekIndex })
			}

			dayColumns.push({ title: weekdays[currentDate.day()], offsetInNumberOfCells: index, widthInNumberOfCells: 1, isWeekendDay: currentDate.isoWeekday() === 6 || currentDate.isoWeekday() === 7 });

			currentDate = moment(currentDate).add(1, 'day');
			index++;
		}

		weekColumns[weekColumns.length-1].title = "";

		this.dateHeaderRows = [weekColumns, dayColumns];
	}

	refreshTasks() {
		var self = this;
		
		return this.http.get('default_assignments',
			{ 
				params: {
					schedule_period_id: this.selectedSchedulePeriod.id,
				}
			}
		)
		.pipe(takeUntil(this.destroyed$))
		.subscribe( data => {
			var shifts = data;

			this.tasks.forEach(function(task) {
				var tasksWithId = self.data.filter(function(x) { return x.id == task.id });

				if (tasksWithId.length > 0) {
					tasksWithId[0].tasks =
						(shifts[task.id] == null ? [] : shifts[task.id].map(function(x) { return self.dataService.createScheduleTaskFromShift(x, task, self.user.customer.show_week_view ? 2 : 3, false, self.fromDate, self.toDate) }));
				}
			});

			if (this.isInitializing) {
				var offsetGantt;
				var beginningOfPreviousWeek = moment().startOf('week').subtract(1, 'week');

				if (beginningOfPreviousWeek >= moment(this.fromDate) && beginningOfPreviousWeek <= moment(this.toDate)) {
					var offsetBeginningOfPreviousWeek = moment(beginningOfPreviousWeek).diff(this.fromDate, 'days');
					offsetGantt = offsetBeginningOfPreviousWeek * this.columnWidth;
				} else {
					offsetGantt = 0;
				}

				var scrollableDiv = document.querySelector('#gantt-scrollable-div');

				// don't scroll when we're running e2e tests
				if (scrollableDiv != null && !(this.user.lastname == "Langeveld" && this.user.customer != null && this.user.customer.name == "MC Neurologie")) {
					setTimeout(function() {
						scrollableDiv.scrollLeft = offsetGantt;
					}, 10);	
				}

				this.isInitializing = false;
			}

			this.isLoading = false;
			
			this.detectChanges();
		});
	}

	getTooltipText(element) {
		var task = JSON.parse(element.getAttribute("taskasjson"));

		// compute dateLabel not in this content but in a controller/service because moment doesn't work well in templates
		var tooltipText = "";

		// TODO: case statement with enums
		if (task.ganttTaskType == "unassignedCandidateCount") {
			if (task.unassignedCandidateCount > 0) {
				tooltipText = "<small>" +
					task.dateLabel + ": " + task.unassignedCandidateCount + " niet toegewezen " + this.environmentService.translateForIndustry('candidate') + (task.unassignedCandidateCount == 1 ? "" : "en") + "</small><br />" +
					task.candidateNames + "<br />" +
				"</small>";
			}
		} else if (task.ganttTaskType == "unassignedShiftCount") {
			if (task.unassignedShiftCount > 0) {
				tooltipText = "<small>" +
					task.dateLabel + ": " + task.unassignedShiftCount + " niet toegewezen " + (task.unassignedShiftCount == 1 ? "taak" : "taken") + "</small><br />" +
					task.taskNames + "<br />" +
				"</small>";
			}
		} else if (task.ganttTaskType == "shiftAccumulationTypeCount") {
			tooltipText = "<small>" +
				task.dateLabel + ": " + task.name + " " + this.environmentService.translateForIndustry('candidate') + (task.name == "1" ? "" : "en") + " voor " + task.tooltipSubtitle + task.tooltipTitle + "</small><br />" +
				task.candidateNames + "<br />" +
			"</small>";
		} else if (task.isSpecialEvent) {
			tooltipText = "<small>" +
				task.dateLabel + ": " + (task.isPublicHoliday ? "Feestdag" : "Evenement") + "</small><br />" +
				task.name + "<br />" +
			"</small>";
		} else {
			tooltipText = "<small>" + task.dateLabel + ": " + task.task.name +
				(task.shift.task_periodic_candidate_id == null && task.shift.task_variant_name != null ? " (" + task.shift.task_variant_name + ")" : "") +
				(task.shift.location == null ? "" : " (" + task.shift.location.alias + ")") + "</small><br />" +
				"<span class='" +
					(
						(task.shift.task_periodic_candidate_id == null ? task.shift.periodic_candidate_id == null : task.shift.task_variant_name == null) ?
						"tooltip-italic" :
						""
					) +
					"'>" + task.tooltipTitle + "</span><br />";

			task.shift.comment_parts.forEach(function(x) {
				if (x[0] == null) {
					tooltipText += "<small><span class='tooltip-warning'>" + x[1] + "</span><br /></small>";
				} else {
					tooltipText += "<small><span class='tooltip-warning'><span class='tooltip-underline'>" + moment(x[0]).format("D/M") + "</span>: " + x[1] + "</span><br /></small>";
				}
			});

			if (task.violations != null) {
				if (task.violations[1] != null) {
					tooltipText += "<small><span class='tooltip-error'>" + task.violations[1] + "</span><br /></small>";
				}
				if (task.violations[2] != null) {
					tooltipText += "<small><span class='tooltip-warning'>" + task.violations[2] + "</span><br /></small>";
				}
				if (task.violations[3] != null) {
					tooltipText += "<small><span class='tooltip-error'>" + task.violations[3] + "</span><br /></small>";
				}
			}
		}

		return tooltipText;
	}

	buildGanttChart() {
		var self = this;

		this.data = [];

		this.detectChanges();

		// temporary object otherwise not all timespans (weekends and public holidays) are rendered...
		var timespansTemp = [];
		this.timespans = [];

		var currentDate = moment(this.fromDate);

		var ganttTimespanClass = this.user.customer.id == 19 ? "gantt-timespan-dark" : "gantt-timespan-light";

		if (currentDate.day() === 0) {
			timespansTemp.push({
			  from: moment(currentDate),
			  to: moment(currentDate).add(1, 'day'),
			  classes: ganttTimespanClass
			});
		}

		currentDate = currentDate.day(6);

		while (currentDate < this.toDate) {
			timespansTemp.push({
			  from: currentDate,
			  to: moment(currentDate).add(2, 'days'),
			  classes: ganttTimespanClass
			});

		 	currentDate = moment(currentDate).add(1, 'week');
		}

		this.timespans = timespansTemp;
		
		this.http.get('tasks/for_schedule',
			{
				params: {
					schedule_period_id: this.selectedSchedulePeriod.id,
					task_display_group_id: this.selectedTaskDisplayGroupId
				}
			}
		).subscribe(data => {
			this.tasks = data;

			var currentTaskDisplayGroupName = null;
			var currentTaskDisplayGroupIsHidden = false;
			var currentPeriodicCandidateId = null;
			var currentTaskBaseName = null;

			this.numberOfTaskDisplayGroups = 0;

			for(var i=0; i < this.tasks.length; i++) {
				this.tasks[i].baseName = this.tasks[i].name;

				if (this.tasks[i].baseName.substring(this.tasks[i].baseName.length - 8) == " ochtend") {
					this.tasks[i].hasMorningOrAfternoonPostfix = true;
					this.tasks[i].baseName = this.tasks[i].baseName.substring(0, this.tasks[i].baseName.length - 8);
					this.tasks[i].namePostfix = "Ochtend";
				} else if (this.tasks[i].baseName.substring(this.tasks[i].baseName.length - 7) == " middag") {
					this.tasks[i].hasMorningOrAfternoonPostfix = true;
					this.tasks[i].baseName = this.tasks[i].baseName.substring(0, this.tasks[i].baseName.length - 7);
					this.tasks[i].namePostfix = "Middag";
				} else if (this.tasks[i].baseName.substring(0, 13) == "Leidschenveen") {
					this.tasks[i].hasMorningOrAfternoonPostfix = true;
					this.tasks[i].baseName = this.tasks[i].name.substring(14, this.tasks[i].name.length - 9);
					this.tasks[i].namePostfix = this.tasks[i].name.substring(this.tasks[i].name.length - 9);
				} else if (this.tasks[i].baseName.substring(0, 11) == "Voorschoten") {
					this.tasks[i].hasMorningOrAfternoonPostfix = true;
					this.tasks[i].baseName = this.tasks[i].name.substring(12, this.tasks[i].name.length - 9);
					this.tasks[i].namePostfix = this.tasks[i].name.substring(this.tasks[i].name.length - 9);
				} else if (this.tasks[i].baseName.substring(0, 10) == "Zoetermeer") {
					this.tasks[i].hasMorningOrAfternoonPostfix = true;
					this.tasks[i].baseName = this.tasks[i].name.substring(11, this.tasks[i].name.length - 8);
					this.tasks[i].namePostfix = this.tasks[i].name.substring(this.tasks[i].name.length - 8);
				} else if (this.tasks[i].baseName.substring(0, 10) == "Driesprong") {
					this.tasks[i].hasMorningOrAfternoonPostfix = true;
					this.tasks[i].baseName = this.tasks[i].name.substring(11, this.tasks[i].name.length - 8);
					this.tasks[i].namePostfix = this.tasks[i].name.substring(this.tasks[i].name.length - 8);
				} else if (this.tasks[i].baseName.substring(0, 5) == "SPOED") {
					this.tasks[i].hasMorningOrAfternoonPostfix = true;
					this.tasks[i].baseName = this.tasks[i].name.substring(6, this.tasks[i].name.length - 8);
					this.tasks[i].namePostfix = this.tasks[i].name.substring(this.tasks[i].name.length - 8);
				}
			}

			for(var i=0; i < this.tasks.length; i++) {
				this.tasks[i].isPartOfGroup = 
					this.tasks[i].hasMorningOrAfternoonPostfix && 
						(
							(i > 0 && this.tasks[i-1].hasMorningOrAfternoonPostfix && this.tasks[i-1].baseName == this.tasks[i].baseName)
							||
							(i < (this.tasks.length-1) && this.tasks[i+1].hasMorningOrAfternoonPostfix && this.tasks[i+1].baseName == this.tasks[i].baseName)
						);
			}

			for(var i=0; i < this.tasks.length; i++) {
				var task = this.tasks[i];
			
				var isDifferentDisplayGroup = task.task_display_group != null && task.task_display_group.name != currentTaskDisplayGroupName;

				if (isDifferentDisplayGroup) {
					self.data.push({
						name: task.task_display_group.name,
						id: "task_display_group_" + task.task_display_group_id,
						taskDisplayGroupId: task.task_display_group_id,
						isDisplayGroup: true,
						isHidden: task.task_display_group.is_hidden
					});
					self.numberOfTaskDisplayGroups += 1;

					currentTaskDisplayGroupName = task.task_display_group.name;
					currentTaskDisplayGroupIsHidden = task.task_display_group.is_hidden;
				}

				var isFirstOfPeriodCandidateTasks = task.periodic_candidate != null && currentPeriodicCandidateId != task.periodic_candidate_id;
				var isLastOfPeriodCandidateTasks = task.periodic_candidate != null && ((i+1) == this.tasks.length || this.tasks[i+1].periodic_candidate_id != task.periodic_candidate_id);
				var isFirstOfTasksWithSameBaseName = currentTaskBaseName != task.baseName;
				var isLastOfTasksWithSameBaseName = task.isPartOfGroup && ((i+1) == this.tasks.length || this.tasks[i+1].baseName != task.baseName);
				
				self.data.push({
					name: task.isPartOfGroup ? task.namePostfix : task.name,
					id: task.id,
					isDisplayGroup: false, // needed to make sure watcher is removed from ng-if (see https://stackoverflow.com/questions/30904190/one-time-binding-with-ng-if-in-angular)
					belongsToTaskDisplayGroupId: task.task_display_group_id,
					isGroupedRow: (task.periodic_candidate != null || task.isPartOfGroup),
					baseName: task.isPartOfGroup ? (isFirstOfTasksWithSameBaseName ? task.baseName : "") : (isFirstOfPeriodCandidateTasks ? (new Candidate(task.periodic_candidate.candidate)).lastnameWithInfix() : ""),
					isLastOfGroupedTasks: isLastOfPeriodCandidateTasks || isLastOfTasksWithSameBaseName,
					classes: null
				});

				currentPeriodicCandidateId = task.periodic_candidate_id;
				currentTaskBaseName = task.baseName;
			}

			this.data.push({
				id: "dummySoScrollbarWontOverlapLastRow",
				name: "",
				classes: ""
			});

			this.refreshTasks();
		});
	}

	initialize() {
		this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();

		this.user = this.environmentService.getUser();

		if (this.user.customer.show_week_view) {
			this.columnWidth = 28 * 6;
		} else {
			this.columnWidth = 28;
		}

		this.isInitializing = true;
		this.isLoading = true;
		this.isMultiSelect = false;
		
		this.detectChanges();

		forkJoin([
			this.http.get("task_display_groups", { params: { schedule_period_id: this.selectedSchedulePeriod.id } }),
			this.http.get("default_assignment_periods", { params: { schedule_period_id: this.selectedSchedulePeriod.id } })
		]).subscribe(data => {
			this.taskDisplayGroups = data[0];
			var defaultAssignmentPeriods = data[1];

            this.selectedTaskDisplayGroupId = this.taskDisplayGroups.length > 0 ? this.taskDisplayGroups[0].id : null;

			this.setStartEndDate(defaultAssignmentPeriods);

            this.buildGanttChart();
        });		
	}
	
	getLength(from, to) {
		return moment(to).diff(moment(from), 'days');
	}
	
	getOffset(from) {
		return moment(from).diff(this.fromDate, 'days');
	}
	
	getLengthRelative(from, to, entireFrom, entireTo) {
		return this.getLength(from, to) / this.getLength(entireFrom, entireTo);
	}
	
	getOffsetRelative(from, entireFrom, entireTo) {
		return from.diff(entireFrom, 'days') / entireTo.diff(entireFrom, 'days');
	}
	
	syncScroll(event) {
		this.horizontalScrollReceiver1.scrollLeft = event.scrollLeft;
		this.verticalScrollReceiver1.scrollTop = event.scrollTop;
		this.verticalScrollReceiver2.scrollTop = event.scrollTop;
	}
	
	addToGanttChart(groupedShifts) {
		var self = this;
		
		Object.keys(groupedShifts).forEach(function(key) {

			var task = self.tasks.filter(function(x) { return x.id == key })[0];

			var ganttRow = self.data.filter(function(x) { return x.id == key })[0];

			groupedShifts[key].forEach(function(shift) {
				ganttRow.tasks.push(self.dataService.createScheduleTaskFromShift(shift, task, self.user.customer.show_week_view ? 2 : 3, false, self.fromDate, self.toDate));
			});
		});
	}
	
	deleteFromGanttChart(groupedShifts) {
		var self = this;
		
		Object.keys(groupedShifts).forEach(function(key) {

			var task = self.tasks.filter(function(x) { return x.id == key })[0];

			var ganttRow = self.data.filter(function(x) { return x.id == key })[0];

			groupedShifts[key].forEach(function(shift) {

				var index = -1;
				ganttRow.tasks.some(function(el, i) {
				    if (el.shift.id == shift.id) {
				        index = i;
				        return true;
				    }
				});

				if (index >= 0) {
					ganttRow.tasks.splice(index, 1);
				}
			});
		});
	}

	deleteClonedShiftsFromGanttChart(taskIds) {
		var self = this;
		taskIds.forEach(function(taskId) {
			var ganttRow = self.data.filter(function(x) { return x.id == taskId })[0];

			for (var index = ganttRow.tasks.length-1; index >= 0; index--) {
				if (ganttRow.tasks[index].shift.id == null) {
					ganttRow.tasks.splice(index, 1);
				}
			}
		});
	}
	
	clickGanttTask(task) {
		if (this.user.isAdmin && !task.shift.is_cloned_shift) {
			if (this.isMultiSelect) {
				if (task.isSelectedForMultiSelect) {
					task.isSelectedForMultiSelect = false;

					var index = -1;
					for (var i = 0; i < this.selectedGanttTasksForMultiSelect.length; i++) {
						if (this.selectedGanttTasksForMultiSelect[i] == task) {
							index = i;
							break;
						}
					}

					if (index > -1) {
					  this.selectedGanttTasksForMultiSelect.splice(index, 1);
					}
				} else {
					task.isSelectedForMultiSelect = true;
					this.selectedGanttTasksForMultiSelect.push(task);
				}
			} else {
				this.openAssignModal(task);
			}
		}
	}

	openAssignModalMultiSelect() {
		this.openAssignModal(this.selectedGanttTasksForMultiSelect.length == 1 ? this.selectedGanttTasksForMultiSelect[0] : null);
	}

	openAssignModal(task) {
	    var bsModalRef = this.bsModalService.show(ChangeDefaultAssignmentModalComponent, {
			initialState: {
				task: task,
				selectedGanttTasksForMultiSelect: this.selectedGanttTasksForMultiSelect
			}, 
			class: 'xlModal'
		});

		bsModalRef.content.action.subscribe((shift) => {
			this.isLoading = true;

			this.http.post("default_assignments/update_bulk",
				{
					is_bulk_update: this.isMultiSelect && this.selectedGanttTasksForMultiSelect.length != 1,
					shift: shift,
					recurring_period_and_assignment_period_ids: 
						this.selectedGanttTasksForMultiSelect.length > 0 ?
							this.selectedGanttTasksForMultiSelect.map(function(x) { 
								return [x.shift.recurring_period_id, x.shift.default_assignment_period_id];
							}) :
							[[shift.recurring_period_id, shift.default_assignment_period_id]]
				}
			).subscribe(data => {

				this.refreshSchedulePartly(data);
			}, (response) => {
				this.isLoading = false;
				this.toastr.error(response.error.error);
				console.error(response);
			});
    	});
	}

	refreshSchedulePartly(data) {
		this.refreshTasks();
		
		this.isMultiSelect = false;
		this.selectedGanttTasksForMultiSelect = [];
	}

	startMultiSelect() {
		this.isMultiSelect = true;
	}

	cancelMultiSelect() {
		this.isLoading = true;
		this.isMultiSelect = false;
		this.selectedGanttTasksForMultiSelect = [];
		// TODO uncheck all selected
		this.buildGanttChart();
	}

	applyToDaySchedule() {
		// var self = this;

		// if (this.selectedSchedulePeriod.is_published) {
		// 	this.toastr.error("Het toepassen van het basisrooster is alleen mogelijk op het dagrooster van niet-gepubliceerde roosterperioden.");
		// } else
		
		var bsModalRef = this.bsModalService.show(ApplyDefaultAssignmentDetailsComponent, {
			initialState: {
				selectedSchedulePeriod: this.selectedSchedulePeriod,
				selectedTaskDisplayGroupId: this.selectedTaskDisplayGroupId
			},
			class: 'modal-lg'
		});

		bsModalRef.content.action.subscribe(result => {
			this.detectChanges();
		});
	}
	
	ngOnInit() {
		this.environmentService.selectedSchedulePeriodChanged$
			.pipe(takeUntil(this.destroyed$))
			.subscribe(schedulePeriodId => {
				this.initialize();
			});
		
		// because isSchedulePeriodSet in "<router-outlet *ngIf="isSchedulePeriodSet"></router-outlet>" in loggedInLayoutComponent
		// it's a given that schedule period has been set, therefore we can start initialize()
		// (if that would not have been done we would need to check first if schedule period has been set, but then it could be set
		// a fraction later which would cause initialize() to run twice)
		this.initialize();
	}
	
	ngAfterViewInit() {
		this.horizontalScrollReceiver1 = document.querySelectorAll('.myHorizontalScrollReceiver')[0];
		var verticalScrollReceivers = document.querySelectorAll('.myVerticalScrollReceiver');
		this.verticalScrollReceiver1 = verticalScrollReceivers[0];
		this.verticalScrollReceiver2 = verticalScrollReceivers[1];
		this.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
		
		document.removeEventListener('mouseover', this.mouseover);
		document.removeEventListener('mouseout', this.mouseout);
	}
}
