import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import templateString from './taskVariantsChangeOrder.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TaskVariantsChangeOrderComponent implements OnInit {
    @Output() action = new EventEmitter();
    
    public originalTaskVariants: any;
    
    public taskVariants: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2
    ) { 
        this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
    }

    ok() {
        this.http.post("task_variant_groups/update_order_task_variants", { task_variant_ids: this.taskVariants.map(function(x) { return x.id; }) }).subscribe(() => {
            this.action.emit(true);
            this.bsModalRef.hide();
		});
    }

    cancel() {
        this.bsModalRef.hide();
    };

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.taskVariants, event.previousIndex, event.currentIndex);
    }

    ngOnInit() {
        this.taskVariants = JSON.parse(JSON.stringify(this.originalTaskVariants));
    }
}
