import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { ForgotPasswordComponent } from './forgotPassword';
import { appRoutingModule } from './../app.routing';
import { FormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './resetPassword';
import { PrivacyStatementComponent } from './privacyStatement';
import { OptoutRemindersComponent } from './optoutReminders';

@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PrivacyStatementComponent,
    OptoutRemindersComponent
  ],
  imports: [
		BrowserModule,
		appRoutingModule,
    FormsModule
	],
	entryComponents: [
		PrivacyStatementComponent
	]
})
export class AuthenticationModule {}
